import React, {useContext} from "react";
import {SrfDataProvider} from "./dataProvider/srfDataProvider";
import {FilesDataProvider} from "./resources/files/FilesDataProvider";
import {TemplatesDataProvider} from "./resources/templates/TemplatesDataProvider";
import {CertificatesDataProvider} from "./resources/certificates/CertificatesDataProvider";
import {OrdersDataProvider} from "./resources/orders/OrdersDataProvider";
import {ProcessingLogicsDataProvider} from "./resources/processing-logics/ProcessingLogicsDataProvider";
import {ProcessingsDataProvider} from "./resources/processings/ProcessingsDataProvider";
import {ProductsDataProvider} from "./resources/products/ProductsDataProvider";
import {ClientApplication} from "@shopify/app-bridge";
import {authenticatedFetch} from "@shopify/app-bridge-utils";
import {Redirect} from "@shopify/app-bridge/actions";
import {AppBridgeContext} from "@shopify/app-bridge-react/context";
import Admin from "./Admin";
import {Fetch} from "./dataProvider/ResourceDataProvider";
import {AuthProvider, jwtFetch} from "./authProvider";
import {userLoggedInFetch} from "./utils/user-logged-in-fetch";
import {PluginsDataProvider} from "./resources/plugins/PluginsDataProvider";
import {StoresDataProvider} from "./resources/stores/StoresDataProvider";
import {TagsDataProvider} from "./resources/tags/TagsDataProvider";
import {NotificationsDataProvider} from "./resources/notifications/NotificationsDataProvider";
import {FiltersDataProvider} from "./resources/filters/FiltersDataProvider";

const AdminWrapper = () => {
    const appBridge = useContext(AppBridgeContext);
    let fetch: Fetch;
    let authProvider;
    if (appBridge) {
        fetch = userLoggedInFetch(appBridge);
    } else {
        fetch = jwtFetch();
        authProvider = new AuthProvider();
    }

    const providers: any[] = [
        new FilesDataProvider(fetch),
        new TemplatesDataProvider(fetch),
        new CertificatesDataProvider(fetch),
        new OrdersDataProvider(fetch),
        new ProcessingLogicsDataProvider(fetch),
        new ProcessingsDataProvider(fetch),
        new ProductsDataProvider(fetch),
        new PluginsDataProvider(fetch),
        new StoresDataProvider(fetch),
        new TagsDataProvider(fetch),
        new NotificationsDataProvider(fetch),
        new FiltersDataProvider(fetch),
    ];

    const dataProvider = new SrfDataProvider();
    dataProvider.registerProviders(providers);

    return (
        <Admin
            dataProvider={dataProvider}
            authProvider={authProvider}
        />
    );
};

export default AdminWrapper;
