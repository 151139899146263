import React from "react";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {makeStyles} from "@mui/styles";
import cx from "classnames";
import {FSNodeType, RecursivePathResult} from "./types";
import {DeleteAction} from "./Actions/Delete";
import {CreateFileAction} from "./Actions/CreateFile";
import {CreateFolderAction} from "./Actions/CreateFolder";
import {RenameAction} from "./Actions/Rename";
import {UploadFileAction} from "./Actions/UploadFile";

interface Props {
    result: RecursivePathResult;
    showActions?: boolean;
    className?: string;
    onOpen: () => void;
    onClose: () => void;
}

export const ActionsMenu = (props: Props) => {
    const {showActions, className, result, onOpen, onClose} = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const classes = useStyles();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        onOpen();
    };

    const handleClose = () => {
        setAnchorEl(null);
        onClose();
    };

    if (typeof showActions !== "undefined" && !showActions) {
        return null;
    }

    return (
        <>
            <span
                className={cx(className, classes.button)}
                onClick={e => handleClick(e)}
            >
                <MoreVertIcon />
            </span>

            <Menu
                onClick={event => {
                    event.stopPropagation();
                }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={0}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {result.type === FSNodeType.File && (
                    <RenameAction
                        result={result}
                        onCancel={handleClose}
                    />
                )}
                {result.type === FSNodeType.File && (
                    <DeleteAction
                        result={result}
                        onCancel={handleClose}
                    />
                )}
                {result.type === FSNodeType.Directory && (
                    <CreateFileAction
                        result={result}
                        onCancel={handleClose}
                    />
                )}
                {result.type === FSNodeType.Directory && (
                    <CreateFolderAction
                        result={result}
                        onCancel={handleClose}
                    />
                )}
                {result.type === FSNodeType.Directory && (
                    <UploadFileAction
                        result={result}
                        onCancel={handleClose}
                    />
                )}
            </Menu>
        </>
    );
};

const useStyles = makeStyles(
    () => ({
        button: {
            width: 40,
            display: "flex",
            justifyContent: "space-around",
        },
    }),
    {name: "RaList"},
);
