import {
    ApolloCache,
    DefaultContext,
    DefaultOptions,
    HttpLink,
    InMemoryCache,
    MutationOptions,
    OperationVariables,
    QueryOptions,
} from "@apollo/client";
import {
    CreateParams,
    CreateResult,
    DeleteManyParams,
    DeleteManyResult,
    DeleteParams,
    DeleteResult,
    GetListParams,
    GetListResult,
    GetManyParams,
    GetManyReferenceParams,
    GetManyReferenceResult,
    GetManyResult,
    GetOneParams,
    GetOneResult,
    RaRecord as Record,
    UpdateManyParams,
    UpdateManyResult,
    UpdateParams,
    UpdateResult,
} from "react-admin";
import {Config} from "../config";
import {CustomFetchParams} from "./srfDataProvider";
import typeDefs from "../../graphql/schema.graphql";
import {ApolloClient} from "./ApolloClient";
import {Account, getCurrentAccounts, jwtFetch} from "../authProvider";
import {SwitchUserError} from "../utils/error/SwitchUserError";

export type Fetch = (uri: RequestInfo, options?: RequestInit | undefined) => Promise<Response>;

const apolloOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
    },
    query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    },
};

export interface AuthenticatedUser {
    client: ApolloClient;
    account: Account;
}

export abstract class ResourceDataProvider<Entity extends Record = Record> {
    public abstract resource: string;
    protected client: ApolloClient;
    protected authenticatedUsers: AuthenticatedUser[] = [];

    constructor(protected fetch: Fetch, protected baseUrl: string = `${Config.apiBaseUrl}/gql`) {
        this.client = this.getClient(fetch);
        this.authenticatedUsers = this.getAuthenticatedClients();
    }

    private getClient(fetch: Fetch) {
        return new ApolloClient({
            link: new HttpLink({
                credentials: "same-origin",
                uri: this.baseUrl,
                fetch,
            }),
            cache: new InMemoryCache({
                addTypename: false,
            }),
            defaultOptions: apolloOptions,
            typeDefs,
        });
    }

    private getAuthenticatedClients(): AuthenticatedUser[] {
        const accounts = getCurrentAccounts();
        const otherAccounts = accounts.loggedInto.filter(account => account.shop !== accounts.current?.shop);
        return otherAccounts.map((account) => ({
            client: this.getClient(jwtFetch(account.accessToken)),
            account,
        }));
    }

    abstract create(params: CreateParams): Promise<CreateResult<Entity>>;

    abstract delete(params: DeleteParams): Promise<DeleteResult<Entity>>;

    abstract deleteMany(params: DeleteManyParams): Promise<DeleteManyResult>;

    abstract getList(params: GetListParams): Promise<GetListResult<Entity>>;

    abstract getMany(params: GetManyParams): Promise<GetManyResult<Entity>>;

    abstract getManyReference(params: GetManyReferenceParams): Promise<GetManyReferenceResult<Entity>>;

    abstract getOne(params: GetOneParams): Promise<GetOneResult<Entity>>;

    abstract update(params: UpdateParams): Promise<UpdateResult<Entity>>;

    abstract updateMany(params: UpdateManyParams): Promise<UpdateManyResult>;

    async queryAllStores<T = any, TVariables = OperationVariables>(options: QueryOptions<TVariables, T>) {
        const result = await this.client.query(options);

        if (!result.data || result.errors) {
            const promises = this.authenticatedUsers.map(user => {
                return new Promise<AuthenticatedUser | undefined>(async resolve => {
                    const result = await user.client.query(options);
                    if (!result.data || result.errors) {
                        resolve(undefined);
                    } else {
                        resolve(user);
                    }
                });
            });

            const results = await Promise.all(promises);

            const user = results.find(result => result !== undefined);
            if (user) {
                throw new SwitchUserError("Switch user", user.account);
            }
        }

        return result;
    }

    async customFetch<QueryResultType, ResultType>(params: CustomFetchParams<QueryResultType, ResultType>) {
        const {query, resolver} = params;
        const result = await this.client.query({query});
        return resolver(result);
    }

    async customMutate<TData = any, TVariables = OperationVariables, TContext = DefaultContext, TCache extends ApolloCache<any> = ApolloCache<any>>(
        params: MutationOptions<TData, TVariables, TContext>,
    ) {
        return await this.client.mutate(params);
    }
}
