import {Config} from "../../../config";
import {Fetch} from "../../../dataProvider/ResourceDataProvider";
import {CreateTemplateData} from "../types";

export const uploadThumbnail = async (fetch: Fetch, data: CreateTemplateData) => {
    const file: File | undefined = data.thumbnail ? data.thumbnail.rawFile : undefined;
    if (!file) {
        return data;
    }

    var formData = new FormData();
    formData.append("file", file);
    formData.append("rootPath", "/templates/thumbnail");

    const upload = await fetch(`${Config.apiBaseUrl}/files/upload`, {
        method: "POST",
        body: formData,
    });
    const result = await upload.json();

    return {
        ...data,
        thumbnailId: result.id,
    };
};
