import React, {useEffect, useState} from "react";
import {AutocompleteArrayInput, TextInputProps, useDataProvider, useGetList} from "react-admin";
import {TagEntity} from "../types";

interface IProps extends TextInputProps {
    entityName: string;
}

export const TagSelector = ({entityName}: IProps) => {
    const {data, isLoading} = useGetList<TagEntity>("tags", {
        filter: {entityName},
    });

    const [tags, setTags] = useState<TagEntity[]>([]);
    const dataProvider = useDataProvider();

    useEffect(() => setTags(data || []), [data]);

    const handleCreate = async (tagName?: string) => {
        if (!tagName) {
            return;
        }

        const newTag = await dataProvider.create("tags", {data: {tagName, entityName}});
        setTags([...tags, newTag.data as TagEntity]);

        return newTag.data;
    };

    if (isLoading) {
        return null;
    }

    const options = tags.map((tag: TagEntity) => ({
        id: tag.id,
        name: tag.tagName,
    }));

    return (
        <>
            <AutocompleteArrayInput
                source="tags"
                createLabel="Create new tag"
                onCreate={handleCreate}
                choices={options}
            />
        </>
    );
};
