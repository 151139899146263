import * as React from "react";
import {Edit, SimpleForm, TextInput, useDataProvider} from "react-admin";
import {useQuery} from "react-query";
import {ApolloQueryResult, gql} from "@apollo/client";
import {Store} from "../../../graphql/graphql";
import {KeyValueInput} from "../../components/KeyValueField";
import {useUserStore} from "../../hooks/useUserStore";

export const StoreConfiguration = () => {
    const {data: result, isLoading} = useUserStore();

    if (!result) {
        return null;
    }
    const store = result.data;

    return (
        <Edit
            resource="stores"
            id={store.id}
            redirect={false}
        >
            <SimpleForm>
                <TextInput
                    name="Shop"
                    source="shop"
                    fullWidth
                />

                <KeyValueInput
                    name="Configuration"
                    label="Configuration"
                    source="configuration"
                />
            </SimpleForm>
        </Edit>
    );
};
