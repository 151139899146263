import {gql} from "@apollo/client";
import {
    CreateParams,
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateManyParams,
    UpdateParams,
} from "react-admin";
import {ResourceDataProvider} from "../../dataProvider/ResourceDataProvider";
import {File, Product, UpdateFileInput, UpdateProductInput} from "../../../graphql/graphql";
import {cleanKeyValue} from "../../utils/clean-key-value";

export class FilesDataProvider extends ResourceDataProvider {
    public resource = "files";

    async create(params: CreateParams) {
        const query = gql`
        mutation {
            createFile(input: {path: "${params.data.path}", content: ""}) {
                id,
                path
            }
        }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.createFile,
        };
    }

    async delete(params: DeleteParams) {
        const query = gql`
        mutation {
            removeFile(id: ${params.id}) {
                id
            }
        }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data,
        };
    }

    // TODO: implement
    async deleteMany(params: DeleteManyParams) {
        throw new Error("Not implemented");
        return {};
    }

    async getList(params: GetListParams) {
        const query = gql`
            {
                files {
                    id
                    path
                    link
                    fileType
                }
            }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.files,
            total: result.data.files.length,
        };
    }

    // TODO: implement
    async getMany(params: GetManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }

    // TODO: implement
    async getManyReference(params: GetManyReferenceParams) {
        throw new Error("Not implemented");
        return {data: [], total: 0};
    }

    async getOne(params: GetOneParams) {
        const query = gql`
        {
            file(id: ${params.id}) {
                id,
                link,
                path,
                content,
                fileType,
            }
        }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.file,
        };
    }

    async update(params: UpdateParams) {
        console.log(params);

        const query = gql`
            mutation update($input: UpdateFileInput!) {
                updateFile(input: $input) {
                    id
                    path
                    content
                }
            }
        `;

        const data = params.data as File;
        const input: UpdateFileInput = {
            id: params.id as number,
            content: data.content,
        };

        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.updateFile,
        };
    }

    // TODO: implement
    async updateMany(params: UpdateManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }
}
