import {Filter} from "../../../../graphql/graphql";
import React from "react";
import {createStyles, makeStyles} from "@mui/styles";

interface Props {
    includeFilter?: Filter | null;
    excludeFilter?: Filter | null;
}

export const FilterInfo: React.FC<Props> = (props) => {
    const classes = useStyles();

    const {includeFilter, excludeFilter} = props;

    return (
        <div>
            {includeFilter && (
                <div className={classes.include}>
                    include: <span className={classes.name}>{includeFilter.filterName}</span> | {includeFilter.criteria}
                </div>
            )}
            {excludeFilter && (
                <div className={classes.exclude}>
                    exclude: <span className={classes.name}>{excludeFilter.filterName}</span> | {excludeFilter.criteria}
                </div>
            )}
        </div>
    )
};

const useStyles = makeStyles(theme =>
    createStyles({
        include: {
            fontStyle: "italic",
            color: "#696",
        },
        exclude: {
            fontStyle: "italic",
            color: "#a55",
        },
        name: {
            fontWeight: 600,
        },
    }),
);
