import React from "react";
import {InjectedFieldProps, PublicFieldProps, useShowContext} from "react-admin";
import {Order} from "../../../../graphql/graphql";
import {OrderFieldWrapper} from "./OrderFieldWrapper";

export interface OrderProcessingFieldProps extends PublicFieldProps, InjectedFieldProps {
}

export const OrderProcessingsField: React.FC<OrderProcessingFieldProps> = (props) => {
    const {source} = props;
    const {isFetching, record} = useShowContext();

    if (isFetching || !record || !source) {
        return null;
    }

    const orderProcessings: {order: Order}[] = record[source];

    return <OrderFieldWrapper orderProcessings={orderProcessings} />;
};
