import {gql} from "@apollo/client";
import {
    CreateParams,
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateManyParams,
    UpdateParams,
} from "react-admin";
import {CreateTagInput} from "../../../graphql/graphql";
import {ResourceDataProvider} from "../../dataProvider/ResourceDataProvider";

export class TagsDataProvider extends ResourceDataProvider {
    public resource = "tags";

    async create(params: CreateParams) {
        const query = gql`
            mutation CreateTag($input: CreateTagInput!) {
                createTag(createTagInput: $input) {
                    id
                    tagName
                }
            }
        `;

        const data = params.data;
        const input: CreateTagInput = {
            entityName: data.entityName,
            tagName: data.tagName,
        };

        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.createTag,
        };
    }

    async delete(params: DeleteParams) {
        const query = gql`
            mutation RemoveTag($id: Int!) {
                removeTag(id: $id) {
                    id
                }
            }
        `;

        const result = await this.client.query({
            query,
            variables: {id: params.id},
        });

        return {
            data: result.data,
        };
    }

    // TODO: implement
    async deleteMany(params: DeleteManyParams) {
        throw new Error("Not implemented");
        return {};
    }

    async getList(params: GetListParams) {
        const entityName = params.filter.entityName;
        const query = gql`
            {
                tags(entityName: "${entityName}") {
                    id
                    tagName
                }
            }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.tags,
            total: result.data.tags.length,
        };
    }

    async getMany(params: GetManyParams) {
        const query = gql`
            {
                tags {
                    id
                    tagName
                }
            }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.tags,
            total: result.data.tags.length,
        };
    }

    // TODO: implement
    async getManyReference(params: GetManyReferenceParams) {
        throw new Error("Not implemented");
        return {data: [], total: 0};
    }

    async getOne(params: GetOneParams) {
        const query = gql`
            {
                tag(id: ${params.id}) {
                    id,
                    tagName
                }
            }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.tags,
        };
    }

    async update(params: UpdateParams) {
        throw new Error("Method not implemented.");
        return {data: {id: 0}};
    }

    // TODO: implement
    async updateMany(params: UpdateManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }
}
