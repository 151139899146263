import React, {useEffect, useState} from "react";
import {SimpleForm} from "react-admin";
import {makeStyles} from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import Button from "@mui/material/Button";
import ExpandIcon from "@mui/icons-material/Expand";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import {OrderField} from "./OrderField";
import {Order} from "../../../../graphql/graphql";

interface OrderFieldWrapperProps {
    orderProcessings: {order: Order}[];
}

export const OrderFieldWrapper: React.FC<OrderFieldWrapperProps> = props => {
    const {orderProcessings} = props;

    const classes = useStyles();

    const [checkedState, setCheckedState] = useState<boolean[]>([]);

    useEffect(() => {
        if (orderProcessings.length > 0) {
            setCheckedState([true, ...new Array(orderProcessings.length - 1).fill(false)]);
        }
    }, [orderProcessings]);

    const handleOnChange = (position: number) => {
        const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
        setCheckedState(updatedCheckedState);
    };

    const proceed = (index: number) => {
        const newCheckedState = [...checkedState];
        newCheckedState[index] = false;

        if (checkedState.length > index) {
            newCheckedState[index + 1] = true;
        }

        setCheckedState(newCheckedState);
    };

    const Toolbar = () => {
        return (
            <div className={classes.toolbar}>
                <Button
                    className={classes.expand}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setCheckedState([...checkedState.fill(true)]);
                    }}
                    startIcon={<ExpandIcon />}
                >
                    Expand All
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setCheckedState([...checkedState.fill(false)]);
                    }}
                    startIcon={<UnfoldLessIcon />}
                >
                    Collapse All
                </Button>
            </div>
        );
    };

    return (
        <div className={classes.main}>
            {orderProcessings.length > 1 && <Toolbar />}

            {orderProcessings.map(({order}, index) => (
                <SimpleForm
                    record={order}
                    key={order.id}
                    toolbar={false}
                    className={classes.order}
                >
                    <OrderField
                        expanded={checkedState[index]}
                        proceed={() => proceed(index)}
                        onChange={() => handleOnChange(index)}
                    />
                </SimpleForm>
            ))}
        </div>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        main: {
            width: "100%",
        },
        order: {
            padding: 0,
        },
        toolbar: {
            marginBottom: theme.spacing(2),
        },
        expand: {
            marginRight: theme.spacing(1),
        },
    }),
);
