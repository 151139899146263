import React, {useState} from "react";
import {
    Edit,
    TopToolbar,
    SimpleForm,
    TextInput,
    SelectInput,
    ReferenceInput,
    useRecordContext,
    useNotify,
} from "react-admin";
import copy from "copy-to-clipboard";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Grid from "@mui/material/Grid";
import {TemplatePreviewField} from "../../components/TemplatePreview/TemplatePreviewField";
import {KeyValueInput} from "../../components/KeyValueField";
import {OrdersField} from "./fields/OrdersField";
import {CopyableInput} from "../../components/CopyableField";
import {PluginActions} from "../../components/PluginActions";
import {openInNewTab} from "../../utils/open-in-new-tab";
import RelatedItemsInput from "../../components/RelatedItems/input";
import {useListProductVariants} from "./hooks/useListProductVariants";
import {SelectInputGroup} from "../../components/SelectInputGroup/SelectInputGroup";
import {Certificate} from "../../../graphql/graphql";
import {TagSelector} from "../tags/tagSelector";
import {keyValueToObject} from "../../utils/key-value-to-object";
import {SwitchUserError} from "../../utils/error/SwitchUserError";
import {SwitchUserPopup} from "../../components/SwitchUserPopup";
import {Account} from "../../authProvider";

const Actions = (props: any) => {
    const record = useRecordContext<Certificate>(props);
    const notify = useNotify();

    if (!record) {
        return null;
    }

    const variables = keyValueToObject(record.variables);

    return (
        <TopToolbar>
            {variables.finalized === "0" && (
                <Button
                    variant="contained"
                    color="primary"
                    sx={{marginRight: "0.5em"}}
                    onClick={() => {
                        copy(`${record.baseUrl}products/${record.productVariant.product.handle}/?id=${record.hashId}`);
                        notify("Copied finalize url to the clipboard");
                    }}
                >
                    <FileCopyIcon />
                    Finalize link
                </Button>
            )}

            <PluginActions {...props} />

            <Button
                variant="contained"
                color="primary"
                sx={{marginRight: "0.5em"}}
                onClick={() => openInNewTab(record.regenerateUrl)}
            >
                <RestorePageIcon />
                Regenerate
            </Button>

            <Button
                variant="contained"
                color="primary"
                onClick={() => window.location.assign(record.downloadUrl)}
            >
                <PictureAsPdfIcon />
                Download
            </Button>
        </TopToolbar>
    );
};

export const CertificatesEdit = (props: any) => {
    const {data: variants} = useListProductVariants();
    const classes = useStyles();

    const [switchUserAccount, setSwitchUserAccount] = useState<Account>();

    const findProductByVariantId = (variantId: number) => {
        if (!variants) {
            return;
        }

        for (const product of variants.data) {
            for (const variant of product.variants) {
                if (variant.variantId === variantId) {
                    return product;
                }
            }
        }
    };

    return (
        <Edit
            {...props}
            actions={<Actions />}
            mutationMode="pessimistic"
            redirect={false}
            transform={(data: Certificate) => {
                data.relatedItems.map(relatedItem => {
                    const product = findProductByVariantId(relatedItem.variantId);
                    if (product) {
                        relatedItem.handle = product.handle;
                    }
                });
                return data;
            }}
            aside={
                <SwitchUserPopup
                    open={typeof switchUserAccount !== "undefined"}
                    onClose={() => setSwitchUserAccount(undefined)}
                    account={switchUserAccount}
                />
            }
            queryOptions={{
                onError(error) {
                    if (error instanceof SwitchUserError) {
                        setSwitchUserAccount(error.user);
                    }
                },
            }}
        >
            <SimpleForm>
                <Grid container spacing={1} style={{width: "100%"}} className={classes.parentGrid}>
                    <Grid container item xs={12} md={6} lg={8} flexDirection="column">
                        <TextInput
                            disabled
                            label="ID"
                            source="id"
                        />

                        <CopyableInput
                            className={classes.hashId}
                            disabled
                            label="Hash ID"
                            source="hashId"
                        />

                        <OrdersField source="orderCertificates" />

                        <ReferenceInput
                            label="Template"
                            source="template.id"
                            reference="templates"
                        >
                            <SelectInput optionText="name" />
                        </ReferenceInput>

                        <TextInput
                            label="Locale"
                            source="locale"
                        />

                        <SelectInputGroup
                            choices={variants?.data}
                            label="Product Variant"
                            groupText="title"
                            optionKey="variants"
                            optionText="title"
                            source="productVariantId"
                            sx={{
                                minWidth: 430,
                            }}
                        />

                        <TagSelector
                            source="tags"
                            entityName="certificate"
                        />

                        <RelatedItemsInput
                            label="Related Items"
                            source="relatedItems"
                        />

                        <KeyValueInput
                            label="Variables"
                            source="variables"
                        />

                        <KeyValueInput
                            label="Private Variables"
                            source="privateVariables"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} className={classes.rightGrid}>
                        <TemplatePreviewField
                            templateSource="template.file"
                            variablesSource="variables"
                            privateVariablesSource="privateVariables"
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};

const useStyles = makeStyles(theme => ({
    parentGrid: {
        [theme.breakpoints.up("md")]: {
            overflowY: "scroll",
            // maxHeight: "calc(100vh - 280px)",
            alignItems: "baseline",
        },
    },
    rightGrid: {
        [theme.breakpoints.up("md")]: {
            position: "sticky",
            top: 0,
        },
    },
    hashId: {
        minWidth: 430,
    },
}));
