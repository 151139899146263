import {
    DataProvider,
    RaRecord as Record,
    CreateParams,
    CreateResult,
    DeleteManyParams,
    DeleteManyResult,
    DeleteParams,
    DeleteResult,
    GetListParams,
    GetListResult,
    GetManyParams,
    GetManyReferenceParams,
    GetManyReferenceResult,
    GetManyResult,
    GetOneParams,
    GetOneResult,
    UpdateManyParams,
    UpdateManyResult,
    UpdateParams,
    UpdateResult,
} from "react-admin";
import {ResourceDataProvider} from "./ResourceDataProvider";
import {DocumentNode} from "graphql/language/ast";
import {ApolloCache, ApolloQueryResult, DefaultContext, MutationOptions, OperationVariables} from "@apollo/client";

export interface CustomFetchParams<QueryResultType, ResultType> {
    query: DocumentNode;
    resolver: (result: ApolloQueryResult<QueryResultType>) => ResultType | Promise<ResultType>;
}

export class SrfDataProvider implements DataProvider {
    protected providers: {[resource: string]: ResourceDataProvider} = {};

    registerProvider(provider: ResourceDataProvider) {
        this.providers[provider.resource] = provider;
    }

    registerProviders(providers: ResourceDataProvider[]) {
        providers.map(provider => this.registerProvider(provider));
    }

    getProvider<Entity extends Record = Record>(resource: string) {
        if (!this.providers.hasOwnProperty(resource)) {
            throw new Error(`Provider for ${resource} not implemented!`);
        }
        return this.providers[resource] as ResourceDataProvider<Entity>;
    }

    async create<Entity extends Record = Record>(
        resource: string,
        params: CreateParams,
    ): Promise<CreateResult<Entity>> {
        return this.getProvider<Entity>(resource).create(params);
    }

    async delete<Entity extends Record = Record>(
        resource: string,
        params: DeleteParams,
    ): Promise<DeleteResult<Entity>> {
        return this.getProvider<Entity>(resource).delete(params);
    }

    async deleteMany(resource: string, params: DeleteManyParams): Promise<DeleteManyResult> {
        return this.getProvider(resource).deleteMany(params);
    }

    async getList<Entity extends Record = Record>(
        resource: string,
        params: GetListParams,
    ): Promise<GetListResult<Entity>> {
        return this.getProvider<Entity>(resource).getList(params);
    }

    async getMany<Entity extends Record = Record>(
        resource: string,
        params: GetManyParams,
    ): Promise<GetManyResult<Entity>> {
        return this.getProvider<Entity>(resource).getMany(params);
    }

    async getManyReference<Entity extends Record = Record>(
        resource: string,
        params: GetManyReferenceParams,
    ): Promise<GetManyReferenceResult<Entity>> {
        return this.getProvider<Entity>(resource).getManyReference(params);
    }

    async getOne<Entity extends Record = Record>(
        resource: string,
        params: GetOneParams,
    ): Promise<GetOneResult<Entity>> {
        return this.getProvider<Entity>(resource).getOne(params);
    }

    async update<Entity extends Record = Record>(
        resource: string,
        params: UpdateParams,
    ): Promise<UpdateResult<Entity>> {
        return this.getProvider<Entity>(resource).update(params);
    }

    async updateMany(resource: string, params: UpdateManyParams): Promise<UpdateManyResult> {
        return this.getProvider(resource).updateMany(params);
    }

    async customFetch<QueryResultType, ResultType>(
        resource: string,
        params: CustomFetchParams<QueryResultType, ResultType>,
    ) {
        return this.getProvider(resource).customFetch(params);
    }

    async customMutate<TData = any, TVariables = OperationVariables, TContext = DefaultContext, TCache extends ApolloCache<any> = ApolloCache<any>>(
        resource: string,
        params: MutationOptions<TData, TVariables, TContext>,
    ) {
        return this.getProvider(resource).customMutate(params);
    }
}
