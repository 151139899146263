export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Certificate = {
  __typename?: 'Certificate';
  baseUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  downloadUrl: Scalars['String'];
  /** The tags that owned by entity */
  entityTags: Array<EntityTag>;
  /** The certificate's generations */
  generations: Array<CertificateGeneration>;
  /** Unique hashed id for identifying certificate */
  hashId: Scalars['String'];
  /** id */
  id: Scalars['Int'];
  /** Locale for the certificate */
  locale: Scalars['String'];
  /** Order Certificate map that this certificate has */
  orderCertificates: Array<OrderCertificate>;
  /** The user that owns this entity */
  owner: User;
  pluginActions: Array<PluginActionField>;
  /** Private variable map (key-value) for the certificate */
  privateVariables: Array<KeyValueField>;
  /** The product variant for the certificate */
  productVariant: ProductVariant;
  /** The id of the product variant */
  productVariantId?: Maybe<Scalars['Int']>;
  regenerateUrl: Scalars['String'];
  /** Related items array */
  relatedItems: Array<RelatedItemField>;
  /** Related store */
  store: Store;
  /** The id of the related store */
  storeId?: Maybe<Scalars['Int']>;
  /** The relevant template for the certificate */
  template: Template;
  /** The id of the template */
  templateId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** Variable map (key-value) for the certificate */
  variables: Array<KeyValueField>;
};

export type CertificateGeneration = {
  __typename?: 'CertificateGeneration';
  /** The relevant certificate */
  certificate: Certificate;
  finishedAt: Scalars['DateTime'];
  /** id */
  id: Scalars['Int'];
  /** Options map (key-value) for the generation */
  options: Array<KeyValueField>;
  startedAt: Scalars['DateTime'];
};

export type CertificateNodesField = {
  __typename?: 'CertificateNodesField';
  length: Scalars['Int'];
  nodes: Array<Certificate>;
};

export type CreateCertificateInput = {
  /** Unique hashed id for identifying certificate */
  hashId?: InputMaybe<Scalars['String']>;
  /** Locale of the certificate */
  locale: Scalars['String'];
  /** Private variable map (key-value) for the certificate */
  privateVariables: Array<KeyValueInput>;
  /** The id of the variant */
  productVariantId?: InputMaybe<Scalars['Int']>;
  /** Related items array */
  relatedItems: Array<RelatedItemInput>;
  /** The id of the store */
  storeId?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<Scalars['Int']>>;
  /** The id of the template */
  templateId: Scalars['Int'];
  /** Variable map (key-value) for the certificate */
  variables: Array<KeyValueInput>;
};

export type CreateFileInput = {
  /** Contents of the file */
  content?: InputMaybe<Scalars['String']>;
  /** Path to the file */
  path: Scalars['String'];
};

export type CreateFilterInput = {
  criteria: Scalars['String'];
  entityName: Scalars['String'];
  filterName: Scalars['String'];
};

export type CreateManualProcessingBatchInput = {
  /** Which orders to include in the batch */
  orderIds: Array<Scalars['Int']>;
  /** The id of the processing logic */
  processingLogicId: Scalars['Int'];
};

export type CreateOrderProcessingInput = {
  /** Order's id */
  orderId: Scalars['Int'];
  /** The id of the processing logic */
  processingLogicId: Scalars['Int'];
};

export type CreatePluginInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  namespace: Scalars['String'];
};

export type CreatePluginStorageInput = {
  isPublic: Scalars['Boolean'];
  key: Scalars['String'];
  pluginId: Scalars['Int'];
  schema: Scalars['String'];
  value: Scalars['String'];
};

export type CreateProcessingBatchInput = {
  /** Limit of how many orders to include in the batch */
  limit?: InputMaybe<Scalars['Int']>;
  /** The id of the processing logic */
  processingLogicId: Scalars['Int'];
};

export type CreateProcessingLogicInput = {
  excludeFilterId?: InputMaybe<Scalars['Float']>;
  exclusive?: InputMaybe<Scalars['Boolean']>;
  function?: InputMaybe<Scalars['String']>;
  includeFilterId?: InputMaybe<Scalars['Float']>;
  /** Short description/name of the logic */
  name: Scalars['String'];
  type: Type;
};

export type CreateTagInput = {
  entityName: Scalars['String'];
  tagName: Scalars['String'];
};

export type CreateTemplateInput = {
  fileId?: InputMaybe<Scalars['Float']>;
  /** Name of the file */
  name: Scalars['String'];
  ownerId?: InputMaybe<Scalars['Float']>;
  tags?: InputMaybe<Array<Scalars['Int']>>;
  thumbnailId?: InputMaybe<Scalars['Float']>;
  /** Variable map (key-value) for the template */
  variables?: InputMaybe<Array<KeyValueInput>>;
};

export type CreateUserInput = {
  /** Is user active? */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** Password */
  password: Scalars['String'];
  /** Username */
  username: Scalars['String'];
};

export type EntityTag = {
  __typename?: 'EntityTag';
  /** The entity ID */
  entityId: Scalars['Int'];
  /** id */
  id: Scalars['Int'];
  /** The tag for this entity data */
  tag: Tag;
  /** The tag ID */
  tagId: Scalars['Int'];
};

export type File = {
  __typename?: 'File';
  /** Contents of the file */
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  fileType: FileType;
  /** id */
  id: Scalars['Int'];
  /** Link to the file */
  link?: Maybe<Scalars['String']>;
  /** Path to the file */
  path?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export enum FileType {
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Raw = 'RAW'
}

export type Filter = {
  __typename?: 'Filter';
  /** The filter criteria */
  criteria?: Maybe<Scalars['String']>;
  /** The entity name */
  entityName: Scalars['String'];
  /** The filter name */
  filterName: Scalars['String'];
  /** id */
  id: Scalars['Int'];
  /** The user that owns this entity */
  owner: User;
};

export type FulfillOrderInput = {
  id: Scalars['Int'];
  /** Tracking number for the order */
  notifyCustomer?: InputMaybe<Scalars['Boolean']>;
  /** Tracking number for the order */
  trackingNumber?: InputMaybe<Scalars['String']>;
};

export type IsOrderEligibleInput = {
  orderId: Scalars['Int'];
  processingLogicId: Scalars['Int'];
};

export type KeyValueField = {
  __typename?: 'KeyValueField';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type KeyValueInput = {
  key: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type LineItemField = {
  __typename?: 'LineItemField';
  admin_graphql_api_id: Scalars['String'];
  fulfillable_quantity: Scalars['Int'];
  fulfillment_service: Scalars['String'];
  fulfillment_status: Scalars['String'];
  gift_card: Scalars['Boolean'];
  grams: Scalars['Int'];
  id: Scalars['Float'];
  name: Scalars['String'];
  price: Scalars['String'];
  price_set: ShopPresentmentMoneyField;
  product_exists: Scalars['Boolean'];
  product_id?: Maybe<Scalars['Float']>;
  properties: Array<LineItemPropertyField>;
  quantity: Scalars['Int'];
  requires_shipping: Scalars['Boolean'];
  sku?: Maybe<Scalars['String']>;
  taxable: Scalars['Boolean'];
  title: Scalars['String'];
  total_discount: Scalars['String'];
  total_discount_set: ShopPresentmentMoneyField;
  variant_id?: Maybe<Scalars['Float']>;
  variant_title?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
};

export type LineItemInput = {
  admin_graphql_api_id: Scalars['String'];
  fulfillable_quantity: Scalars['Int'];
  fulfillment_service: Scalars['String'];
  fulfillment_status: Scalars['String'];
  gift_card: Scalars['Boolean'];
  grams: Scalars['Int'];
  id: Scalars['Float'];
  name: Scalars['String'];
  price: Scalars['String'];
  price_set: ShopPresentmentMoneyInput;
  product_exists: Scalars['Boolean'];
  product_id?: InputMaybe<Scalars['Float']>;
  properties: Array<LineItemPropertyInput>;
  quantity: Scalars['Int'];
  requires_shipping: Scalars['Boolean'];
  sku?: InputMaybe<Scalars['String']>;
  taxable: Scalars['Boolean'];
  title: Scalars['String'];
  total_discount: Scalars['String'];
  total_discount_set: ShopPresentmentMoneyInput;
  variant_id?: InputMaybe<Scalars['Float']>;
  variant_title?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type LineItemPropertyField = {
  __typename?: 'LineItemPropertyField';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type LineItemPropertyInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type MoneyV2Field = {
  __typename?: 'MoneyV2Field';
  amount: Scalars['String'];
  currency_code: Scalars['String'];
};

export type MoneyV2Input = {
  amount: Scalars['String'];
  currency_code: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createCertificate: Certificate;
  createFile: File;
  createFilter: Filter;
  createManualBatch: Processing;
  createNewBatch: Processing;
  createPlugin: Plugin;
  createPluginStorage: PluginStorage;
  createProcessingLogic: ProcessingLogic;
  createTag: Tag;
  createTemplate: Template;
  createUser: User;
  fulfillOrder: Scalars['String'];
  processOrder: Processing;
  removeCertificate: Certificate;
  removeFile: File;
  removeFilter: Filter;
  removePlugin: Plugin;
  removePluginStorage: PluginStorage;
  removeProcessing: ProcessingLogic;
  removeTag: Tag;
  removeTemplate: Template;
  removeUser: User;
  renameFile: File;
  updateCertificate: Certificate;
  updateFile: File;
  updateFilter: Filter;
  updateNotification: Notification;
  updateOrder: Order;
  updatePlugin: Plugin;
  updatePluginStorage: PluginStorage;
  updateProcessingLogic: ProcessingLogic;
  updateProduct: Product;
  updateStore: Store;
  updateTemplate: Template;
  updateUser: User;
};


export type MutationCreateCertificateArgs = {
  input: CreateCertificateInput;
};


export type MutationCreateFileArgs = {
  input: CreateFileInput;
};


export type MutationCreateFilterArgs = {
  createFilterInput: CreateFilterInput;
};


export type MutationCreateManualBatchArgs = {
  input: CreateManualProcessingBatchInput;
};


export type MutationCreateNewBatchArgs = {
  input: CreateProcessingBatchInput;
};


export type MutationCreatePluginArgs = {
  createPluginInput: CreatePluginInput;
};


export type MutationCreatePluginStorageArgs = {
  createPluginStorageInput: CreatePluginStorageInput;
};


export type MutationCreateProcessingLogicArgs = {
  input: CreateProcessingLogicInput;
};


export type MutationCreateTagArgs = {
  createTagInput: CreateTagInput;
};


export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationFulfillOrderArgs = {
  input: FulfillOrderInput;
};


export type MutationProcessOrderArgs = {
  input: CreateOrderProcessingInput;
};


export type MutationRemoveCertificateArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveFileArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveFilterArgs = {
  id: Scalars['Int'];
};


export type MutationRemovePluginArgs = {
  id: Scalars['Int'];
};


export type MutationRemovePluginStorageArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveProcessingArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveTagArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveTemplateArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['Int'];
};


export type MutationRenameFileArgs = {
  input: RenameFileInput;
};


export type MutationUpdateCertificateArgs = {
  input: UpdateCertificateInput;
};


export type MutationUpdateFileArgs = {
  input: UpdateFileInput;
};


export type MutationUpdateFilterArgs = {
  input: UpdateFilterInput;
};


export type MutationUpdateNotificationArgs = {
  input: UpdateNotificationInput;
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};


export type MutationUpdatePluginArgs = {
  updatePluginInput: UpdatePluginInput;
};


export type MutationUpdatePluginStorageArgs = {
  updatePluginStorageInput: UpdatePluginStorageInput;
};


export type MutationUpdateProcessingLogicArgs = {
  input: UpdateProcessingLogicInput;
};


export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};


export type MutationUpdateStoreArgs = {
  input: UpdateStoreInput;
};


export type MutationUpdateTemplateArgs = {
  input: UpdateTemplateInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type Notification = {
  __typename?: 'Notification';
  /** How many times the same message was added for the entity */
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** Additional notification data */
  data?: Maybe<Scalars['String']>;
  dismissed: Scalars['Boolean'];
  /** The entity ID */
  entityId: Scalars['Int'];
  /** The entity name */
  entityName: Scalars['String'];
  /** id */
  id: Scalars['Int'];
  /** The notification level */
  level: NotificationLevel;
  /** The notification message */
  message: Scalars['String'];
  /** The user that owns this entity */
  owner: User;
  updatedAt: Scalars['DateTime'];
};

export enum NotificationLevel {
  Critical = 'CRITICAL',
  Error = 'ERROR',
  Notification = 'NOTIFICATION',
  Warning = 'WARNING'
}

export type NotificationNodesField = {
  __typename?: 'NotificationNodesField';
  length: Scalars['Int'];
  nodes: Array<Notification>;
};

export type Order = {
  __typename?: 'Order';
  /** Field from webhook: admin_graphql_api_id, for use in graphql */
  adminGraphqlApiId: Scalars['String'];
  barcodeCsvUrl: Scalars['String'];
  billingAddress?: Maybe<ShippingAddressField>;
  createdAt: Scalars['DateTime'];
  /** Email address */
  email: Scalars['String'];
  fedexCsvUrl: Scalars['String'];
  /** Financial status */
  financialStatus?: Maybe<Scalars['String']>;
  fulfilled: Scalars['Boolean'];
  fulfillmentStatus: Scalars['String'];
  /** id */
  id: Scalars['Int'];
  lineItems: Array<LineItemField>;
  /** Order note */
  note?: Maybe<Scalars['String']>;
  /** Order Certificate map that this order has */
  orderCertificates: Array<OrderCertificate>;
  /** Shopify order name in #xxxx or custom format */
  orderName: Scalars['String'];
  /** The processings that this order was used in */
  orderProcessing?: Maybe<Array<OrderProcessing>>;
  /** Phone number */
  phone: Scalars['String'];
  pluginActions: Array<PluginActionField>;
  refunds?: Maybe<Array<RefundField>>;
  shippingAddress?: Maybe<ShippingAddressField>;
  shippingCompany: Scalars['String'];
  shippingLines?: Maybe<Array<ShippingLineField>>;
  /** ID provided by Shopify */
  shopifyOrderId: Scalars['Float'];
  /** Store entity */
  store: Store;
  /** Tracking number */
  trackingNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** Variable map (key-value) for the order */
  variables: Array<KeyValueField>;
};

export type OrderCertificate = {
  __typename?: 'OrderCertificate';
  /** The certificate that it is included into the order */
  certificate: Certificate;
  /** The id of the associated certificate */
  certificateId: Scalars['Int'];
  /** id */
  id: Scalars['Int'];
  /** The order that has this certificate */
  order: Order;
  /** The id of the associated order */
  orderId: Scalars['Int'];
};

export type OrderEligibleField = {
  __typename?: 'OrderEligibleField';
  reason: Scalars['String'];
  resolveTime: Scalars['String'];
};

export type OrderNodesField = {
  __typename?: 'OrderNodesField';
  length: Scalars['Int'];
  nodes: Array<Order>;
};

export type OrderProcessing = {
  __typename?: 'OrderProcessing';
  /** id */
  id: Scalars['Int'];
  /** The order that is included */
  order: Order;
  /** The processing that it is included into */
  processing: Processing;
};

export type Plugin = {
  __typename?: 'Plugin';
  /** Plugin data */
  data: Array<PluginDataField>;
  /** Is the plugin enabled? */
  enabled: Scalars['Boolean'];
  /** id */
  id: Scalars['Int'];
  /** Plugin's namespace, unique per owner */
  namespace: Scalars['String'];
  /** The user that owns this entity */
  owner: User;
};

export type PluginActionField = {
  __typename?: 'PluginActionField';
  /** Inline css that will be applied to the action button. */
  buttonStyle?: Maybe<Scalars['String']>;
  /** Plugin action controller endpoint */
  endpoint: Scalars['String'];
  /** Plugin schema for modal */
  inputSchema: Scalars['String'];
  /** Plugin action name */
  name: Scalars['String'];
  /** Plugin namespace */
  namespace: Scalars['String'];
  /** If true, no modal will be opened */
  noConfirmation?: Maybe<Scalars['Boolean']>;
  /** Plugin action type */
  type: Scalars['String'];
};

export type PluginDataField = {
  __typename?: 'PluginDataField';
  /** Plugin data id */
  id: Scalars['Int'];
  /** If true, no modal will be opened */
  isPublic?: Maybe<Scalars['Boolean']>;
  /** Plugin data key */
  key: Scalars['String'];
  /** Plugin data schema */
  schema: Scalars['String'];
  /** Plugin data value */
  value: Scalars['String'];
};

export type PluginFunctionField = {
  __typename?: 'PluginFunctionField';
  /** Plugin function name */
  description?: Maybe<Scalars['String']>;
  /** Plugin function name */
  slug: Scalars['String'];
};

export type PluginStorage = {
  __typename?: 'PluginStorage';
  createdAt: Scalars['DateTime'];
  /** id */
  id: Scalars['Int'];
  /** Is variable available for public API */
  isPublic: Scalars['Boolean'];
  /** Key */
  key: Scalars['String'];
  /** Related plugin entity */
  plugin: Plugin;
  /** The id of the related plugin */
  pluginId: Scalars['Int'];
  /** Schema for the value */
  schema: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** Value for key */
  value: Scalars['String'];
};

export type Processing = {
  __typename?: 'Processing';
  barcodeCsvUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  downloadUrl: Scalars['String'];
  fedexCsvUrl: Scalars['String'];
  /** id */
  id: Scalars['Int'];
  labelsUrl: Scalars['String'];
  /** Order Processings that this processing has */
  orderProcessings: Array<OrderProcessing>;
  /** The processing logic that was used to create this batch */
  processingLogic: ProcessingLogic;
  regenerateUrl: Scalars['String'];
  /** Type of the processing (single|batch) */
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProcessingLogic = {
  __typename?: 'ProcessingLogic';
  createdAt: Scalars['DateTime'];
  /** What criteria it needs to exclude from included (useful for multiple relations) */
  excludeFilter?: Maybe<Filter>;
  /** Is the processing logic exclusive? */
  exclusive: Scalars['Boolean'];
  /** If the processing logic's type is automatic, which plugin function to run */
  function?: Maybe<Scalars['String']>;
  /** id */
  id: Scalars['Int'];
  /** What criteria it needs to match */
  includeFilter?: Maybe<Filter>;
  /** Common name of the logic */
  name: Scalars['String'];
  /** The user that owns this entity */
  owner: User;
  pluginFunctions: Array<PluginFunctionField>;
  /** Type of the processing logic */
  type: Type;
  updatedAt: Scalars['DateTime'];
};

export type ProcessingLogicNodesField = {
  __typename?: 'ProcessingLogicNodesField';
  length: Scalars['Int'];
  nodes: Array<ProcessingLogic>;
};

export type ProcessingLogicOrderIdsField = {
  __typename?: 'ProcessingLogicOrderIdsField';
  count: Scalars['Int'];
  orderIds: Array<Scalars['Int']>;
  processingLogicId: Scalars['Int'];
};

export type ProcessingNodesField = {
  __typename?: 'ProcessingNodesField';
  length: Scalars['Int'];
  nodes: Array<Processing>;
};

export type Product = {
  __typename?: 'Product';
  /** Field from webhook: admin_graphql_api_id, for use in graphql */
  adminGraphqlApiId: Scalars['String'];
  configuration: Array<KeyValueField>;
  createdAt: Scalars['DateTime'];
  /** Product handle */
  handle: Scalars['String'];
  /** id */
  id: Scalars['Int'];
  /** ID provided by Shopify */
  productId: Scalars['Float'];
  /** Store entity */
  store: Store;
  /** Product title */
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** Product variants associated with this product */
  variants: Array<ProductVariant>;
};

export type ProductNodesField = {
  __typename?: 'ProductNodesField';
  length: Scalars['Int'];
  nodes: Array<Product>;
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  /** Field from webhook: admin_graphql_api_id, for use in graphql */
  adminGraphqlApiId: Scalars['String'];
  configuration: Array<KeyValueField>;
  createdAt: Scalars['DateTime'];
  /** id */
  id: Scalars['Int'];
  /** Product */
  product: Product;
  /** Product sku */
  sku?: Maybe<Scalars['String']>;
  /** Product title */
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** ID provided by Shopify */
  variantId: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  certificate: Certificate;
  certificates: CertificateNodesField;
  deepResolveTemplate: Scalars['String'];
  file: File;
  files: Array<File>;
  filter: Filter;
  filters: Array<Filter>;
  findOne: PluginStorage;
  generateOnePdf: Certificate;
  isOrderEligible: OrderEligibleField;
  notifications: NotificationNodesField;
  order: Order;
  orders: OrderNodesField;
  plugin: Plugin;
  pluginStorages: Array<PluginStorage>;
  plugins: Array<Plugin>;
  processing: Processing;
  processingLogic: ProcessingLogic;
  processingLogicOrders: Array<Order>;
  processingLogics: ProcessingLogicNodesField;
  processingLogicsOrderIds: Array<ProcessingLogicOrderIdsField>;
  processings: ProcessingNodesField;
  product: Product;
  products: ProductNodesField;
  store: Store;
  stores: StoreNodesField;
  tag: Tag;
  tags: Array<Tag>;
  template: Template;
  templates: Array<Template>;
  user: User;
  userStore: Store;
  users: Array<User>;
};


export type QueryCertificateArgs = {
  id: Scalars['Int'];
};


export type QueryCertificatesArgs = {
  filter?: InputMaybe<Scalars['JSON']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};


export type QueryDeepResolveTemplateArgs = {
  path: Scalars['String'];
};


export type QueryFileArgs = {
  id: Scalars['Int'];
};


export type QueryFilesArgs = {
  ids?: InputMaybe<Array<Scalars['Int']>>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryFilterArgs = {
  id: Scalars['Int'];
};


export type QueryFindOneArgs = {
  id: Scalars['Int'];
};


export type QueryGenerateOnePdfArgs = {
  id: Scalars['Int'];
};


export type QueryIsOrderEligibleArgs = {
  input: IsOrderEligibleInput;
};


export type QueryNotificationsArgs = {
  filter?: InputMaybe<Scalars['JSON']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};


export type QueryOrderArgs = {
  id: Scalars['Int'];
};


export type QueryOrdersArgs = {
  filter?: InputMaybe<Scalars['JSON']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};


export type QueryPluginArgs = {
  id: Scalars['Int'];
};


export type QueryProcessingArgs = {
  id: Scalars['Int'];
};


export type QueryProcessingLogicArgs = {
  id: Scalars['Int'];
};


export type QueryProcessingLogicOrdersArgs = {
  id: Scalars['Int'];
};


export type QueryProcessingLogicsArgs = {
  filter?: InputMaybe<Scalars['JSON']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};


export type QueryProcessingsArgs = {
  filter?: InputMaybe<Scalars['JSON']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};


export type QueryProductArgs = {
  id: Scalars['Int'];
};


export type QueryProductsArgs = {
  filter?: InputMaybe<Scalars['JSON']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};


export type QueryStoreArgs = {
  id: Scalars['Int'];
};


export type QueryStoresArgs = {
  filter?: InputMaybe<Scalars['JSON']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};


export type QueryTagArgs = {
  id: Scalars['Int'];
};


export type QueryTagsArgs = {
  entityName?: InputMaybe<Scalars['String']>;
};


export type QueryTemplateArgs = {
  id: Scalars['Int'];
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUsersArgs = {
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type RefundField = {
  __typename?: 'RefundField';
  id: Scalars['Float'];
  refund_line_items: Array<RefundLineItemField>;
};

export type RefundInput = {
  id: Scalars['Float'];
  refund_line_items: Array<RefundLineItemInput>;
};

export type RefundLineItemField = {
  __typename?: 'RefundLineItemField';
  id: Scalars['Float'];
  line_item: LineItemField;
};

export type RefundLineItemInput = {
  id: Scalars['Float'];
  line_item: LineItemInput;
};

export type RelatedItemField = {
  __typename?: 'RelatedItemField';
  handle: Scalars['String'];
  qty: Scalars['Int'];
  uniqueLineId: Scalars['String'];
  variantId: Scalars['Float'];
};

export type RelatedItemInput = {
  handle: Scalars['String'];
  qty: Scalars['Int'];
  uniqueLineId: Scalars['String'];
  variantId: Scalars['Float'];
};

export type RenameFileInput = {
  id: Scalars['Int'];
  /** Path to the file */
  path: Scalars['String'];
};

export type ShippingAddressField = {
  __typename?: 'ShippingAddressField';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  country_code: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  province: Scalars['String'];
  province_code?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ShippingAddressInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  company?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  country_code: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  province: Scalars['String'];
  province_code?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type ShippingLineField = {
  __typename?: 'ShippingLineField';
  carrier_identifier?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  delivery_category?: Maybe<Scalars['String']>;
  discounted_price: Scalars['String'];
  discounted_price_set: ShopPresentmentMoneyField;
  id: Scalars['Float'];
  phone?: Maybe<Scalars['String']>;
  price: Scalars['String'];
  price_set: ShopPresentmentMoneyField;
  requested_fulfillment_service_id?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  title: Scalars['String'];
};

export type ShippingLineInput = {
  carrier_identifier?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  delivery_category?: InputMaybe<Scalars['String']>;
  discounted_price: Scalars['String'];
  discounted_price_set: ShopPresentmentMoneyInput;
  id: Scalars['Float'];
  phone?: InputMaybe<Scalars['String']>;
  price: Scalars['String'];
  price_set: ShopPresentmentMoneyInput;
  requested_fulfillment_service_id?: InputMaybe<Scalars['String']>;
  source: Scalars['String'];
  title: Scalars['String'];
};

export type ShopPresentmentMoneyField = {
  __typename?: 'ShopPresentmentMoneyField';
  presentment_money: MoneyV2Field;
  shop_money: MoneyV2Field;
};

export type ShopPresentmentMoneyInput = {
  presentment_money: MoneyV2Input;
  shop_money: MoneyV2Input;
};

export type ShopifySession = {
  __typename?: 'ShopifySession';
  /** Store's access token */
  accessToken: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** Session's identifier */
  id: Scalars['String'];
  /** Is session's mode online? */
  isOnline: Scalars['Boolean'];
  /** Session's access scope */
  scope: Scalars['String'];
  /** id */
  sessionId: Scalars['Int'];
  /** Session's state */
  state: Scalars['String'];
  /** Store entity */
  store: Store;
  updatedAt: Scalars['DateTime'];
};

export type Store = {
  __typename?: 'Store';
  /** Configuration map (key-value) for the store */
  configuration?: Maybe<Array<KeyValueField>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** id */
  id: Scalars['Float'];
  /** The user that owns this store */
  owner: User;
  /** Array of sessions */
  sessions: Array<ShopifySession>;
  /** Domain of the store */
  shop: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type StoreNodesField = {
  __typename?: 'StoreNodesField';
  length: Scalars['Int'];
  nodes: Array<Store>;
};

export type Tag = {
  __typename?: 'Tag';
  /** The entity name */
  entityName: Scalars['String'];
  /** id */
  id: Scalars['Int'];
  /** The user that owns this entity */
  owner: User;
  /** The tag name */
  tagName: Scalars['String'];
};

export type Template = {
  __typename?: 'Template';
  createdAt: Scalars['DateTime'];
  /** The tags that owned by entity */
  entityTags: Array<EntityTag>;
  /** The relevant file for the template */
  file: File;
  /** id */
  id: Scalars['Int'];
  /** Name of the template */
  name?: Maybe<Scalars['String']>;
  /** The user that owns this entity */
  owner: User;
  /** Thumbnail for the template */
  thumbnail?: Maybe<File>;
  thumbnailUrl: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** Variable map (key-value) for the template */
  variables?: Maybe<Array<KeyValueField>>;
};

export enum Type {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
  Processing = 'PROCESSING'
}

export type UpdateCertificateInput = {
  /** Unique hashed id for identifying certificate */
  hashId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Locale of the certificate */
  locale?: InputMaybe<Scalars['String']>;
  /** Private variable map (key-value) for the certificate */
  privateVariables?: InputMaybe<Array<KeyValueInput>>;
  /** The id of the variant */
  productVariantId?: InputMaybe<Scalars['Int']>;
  /** Related items array */
  relatedItems?: InputMaybe<Array<RelatedItemInput>>;
  /** The id of the store */
  storeId?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<Scalars['Int']>>;
  /** The id of the template */
  templateId?: InputMaybe<Scalars['Int']>;
  /** Variable map (key-value) for the certificate */
  variables?: InputMaybe<Array<KeyValueInput>>;
};

export type UpdateFileInput = {
  /** Contents of the file */
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Path to the file */
  path?: InputMaybe<Scalars['String']>;
};

export type UpdateFilterInput = {
  criteria?: InputMaybe<Scalars['String']>;
  entityName?: InputMaybe<Scalars['String']>;
  filterName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type UpdateNotificationInput = {
  /** Additional serialized notification data */
  data?: InputMaybe<Scalars['String']>;
  dismissed?: InputMaybe<Scalars['Boolean']>;
  /** The entity ID */
  entityId?: InputMaybe<Scalars['Int']>;
  /** The entity name */
  entityName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** The notification level */
  level?: InputMaybe<NotificationLevel>;
  /** The notification message */
  message?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderInput = {
  /** Field from webhook: admin_graphql_api_id, for use in graphql */
  adminGraphqlApiId?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<ShippingAddressInput>;
  /** Email address */
  email?: InputMaybe<Scalars['String']>;
  /** Financial status */
  financialStatus?: InputMaybe<Scalars['String']>;
  fulfilled?: InputMaybe<Scalars['Boolean']>;
  fulfillmentStatus?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lineItems?: InputMaybe<Array<LineItemInput>>;
  /** Order note */
  note?: InputMaybe<Scalars['String']>;
  /** Shopify order name in #xxxx or custom format */
  orderName?: InputMaybe<Scalars['String']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['String']>;
  refunds?: InputMaybe<Array<RefundInput>>;
  shippingAddress?: InputMaybe<ShippingAddressInput>;
  shippingLines?: InputMaybe<Array<ShippingLineInput>>;
  /** ID provided by Shopify */
  shopifyOrderId?: InputMaybe<Scalars['Float']>;
  /** The id of the store */
  storeId?: InputMaybe<Scalars['Int']>;
  /** Tracking number */
  trackingNumber?: InputMaybe<Scalars['String']>;
  /** Variable map (key-value) for the certificate */
  variables?: InputMaybe<Array<KeyValueInput>>;
};

export type UpdatePluginInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  namespace?: InputMaybe<Scalars['String']>;
};

export type UpdatePluginStorageInput = {
  id: Scalars['Int'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  pluginId?: InputMaybe<Scalars['Int']>;
  schema?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateProcessingLogicInput = {
  excludeFilterId?: InputMaybe<Scalars['Float']>;
  exclusive?: InputMaybe<Scalars['Boolean']>;
  function?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  includeFilterId?: InputMaybe<Scalars['Float']>;
  /** Short description/name of the logic */
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Type>;
};

export type UpdateProductInput = {
  /** Variable map (key-value) for the product */
  configuration: Array<KeyValueInput>;
  id: Scalars['Int'];
  /** Array with variants and their configuration */
  variants: Array<UpdateProductVariantInput>;
};

export type UpdateProductVariantInput = {
  /** Variable map (key-value) for the product variant */
  configuration: Array<KeyValueInput>;
  id: Scalars['Int'];
};

export type UpdateStoreInput = {
  /** Store configuration */
  configuration?: InputMaybe<Array<KeyValueInput>>;
  id: Scalars['Int'];
  /** Name of store */
  shop: Scalars['String'];
};

export type UpdateTemplateInput = {
  fileId?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  /** Name of the file */
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Float']>;
  tags?: InputMaybe<Array<Scalars['Int']>>;
  thumbnailId?: InputMaybe<Scalars['Float']>;
  /** Variable map (key-value) for the template */
  variables?: InputMaybe<Array<KeyValueInput>>;
};

export type UpdateUserInput = {
  id: Scalars['Int'];
  /** Is user active? */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** Password */
  password?: InputMaybe<Scalars['String']>;
  /** Username */
  username?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  /** id */
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  password: Scalars['String'];
  /** Related stores */
  stores: Array<Store>;
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};
