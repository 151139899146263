import {deepmerge} from "@mui/utils";
import {
    defaultTheme,
    ListClasses,
    MenuItemLinkClasses,
    SimpleFormIteratorClasses,
} from "react-admin";
import {createTheme, responsiveFontSizes} from "@mui/material/styles";

const fontSize = 14;
const htmlFontSize = 16;
const coef = fontSize / 14;
const pxToRem = (size: number) => `${(size / htmlFontSize) * coef}rem`;

const palette = {
    primary: {
        main: "#586994",
    },
    secondary: {
        main: "#7D869C",
    },
    error: {
        main: "#EE6C4D",
    },
    warning: {
        main: "#EAC435",
    },
    info: {
        main: "#E0FBFC",
    },
    success: {
        main: "#89CE94",
    },
    text: {
        primary: "rgba(0, 0, 0, 0.87)",
    },
};

export const SRFThemeOptions = deepmerge(defaultTheme, {
    palette,
    typography: {
        h1: {
            fontSize: pxToRem(40),
        },
        h2: {
            fontSize: pxToRem(32),
        },
        h3: {
            fontSize: pxToRem(24),
        },
        h4: {
            fontSize: pxToRem(20),
        },
        h5: {
            fontSize: pxToRem(18),
        },
        h6: {
            fontSize: pxToRem(16),
        },

        //    h1: buildVariant(fontWeightLight, 96, 1.167, -1.5),
        //     h2: buildVariant(fontWeightLight, 60, 1.2, -0.5),
        //     h3: buildVariant(fontWeightRegular, 48, 1.167, 0),
        //     h4: buildVariant(fontWeightRegular, 34, 1.235, 0.25),
        //     h5: buildVariant(fontWeightRegular, 24, 1.334, 0),
        //     h6: buildVariant(fontWeightMedium, 20, 1.6, 0.15),
        //     subtitle1: buildVariant(fontWeightRegular, 16, 1.75, 0.15),
        //     subtitle2: buildVariant(fontWeightMedium, 14, 1.57, 0.1),
    },
    components: {
        RaList: {
            styleOverrides: {
                root: {
                    [`& .${ListClasses.main}`]: {
                        overflow: "scroll",
                    },
                },
            },
        },
        RaFilterFormInput: {
            styleOverrides: {
                root: {
                    flexWrap: "wrap",
                }
            }
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    color: "#fff",
                    [`&.${MenuItemLinkClasses.active}`]: {
                        color: "#fff",
                        backgroundColor: palette.primary.main,
                        borderBottom: "2px solid #eee",
                    },
                },
            },
        },
        RaSimpleFormIterator: {
            styleOverrides: {
                root: {
                    [`& .${SimpleFormIteratorClasses.line}`]: {
                        paddingTop: "0.5em",
                        paddingBottom: "0.5em",
                    },

                    [`& .${SimpleFormIteratorClasses.indexContainer}`]: {
                        paddingTop: "0.5em",
                        paddingBottom: "0.5em",
                    },
                },
            },
        },
    },
});

let theme = createTheme(SRFThemeOptions);
theme = responsiveFontSizes(theme, {
    // factor: 10,
});

export {theme};
