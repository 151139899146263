import React from "react";
import Card from "@mui/material/Card";
import {Title} from "react-admin";
import CardContent from "@mui/material/CardContent";

export const Welcome: React.FC = () => (
    <Card>
        <Title title="Welcome to SRF!" />
        <CardContent>
            Welcome to SRF!
        </CardContent>
    </Card>
);
