import React, {useMemo, useState} from "react";
import {SimpleForm, TextInput, SelectInput, ReferenceInput, Create, ToolbarClasses, SaveButton} from "react-admin";
import {deepmerge} from "@mui/utils";
import {makeStyles} from "@mui/styles";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import {TemplatePreviewField} from "../../components/TemplatePreview/TemplatePreviewField";
import {KeyValueInput} from "../../components/KeyValueField";
import RelatedItemsInput from "../../components/RelatedItems/input";
import {useListProductVariants} from "./hooks/useListProductVariants";
import {SelectInputGroup} from "../../components/SelectInputGroup/SelectInputGroup";
import {KeyValueField} from "../../../graphql/graphql";

export const CertificatesCreate = (props: any) => {
    const {data: variants} = useListProductVariants();
    const [isDirty, setIsDirty] = useState(false);
    const classes = useStyles();

    const createProps = useMemo(() => {
        const searchProps = Object.fromEntries(new URLSearchParams(location.search));

        let variables: KeyValueField[] | undefined = undefined;
        if (typeof searchProps.variables !== "undefined") {
            try {
                variables = JSON.parse(searchProps.variables);
            } catch (e) {
            }
        }
        let privateVariables: KeyValueField[] | undefined = undefined;
        if (typeof searchProps.privateVariables !== "undefined") {
            try {
                privateVariables = JSON.parse(searchProps.privateVariables);
            } catch (e) {
            }
        }
        let relatedItems: KeyValueField[] | undefined = undefined;
        if (typeof searchProps.relatedItems !== "undefined") {
            try {
                relatedItems = JSON.parse(searchProps.relatedItems);
            } catch (e) {
            }
        }

        const record: any = {
            variables,
            privateVariables,
            relatedItems,
            productVariantId: Number(searchProps.productVariantId) || undefined,
            locale: searchProps.locale,
        };
        if (Number(searchProps["template.id"])) {
            record.template = {
                id: Number(searchProps["template.id"]),
            };
        }

        Object.keys(record).forEach(key => (record[key] === undefined) && delete record[key]);
        setIsDirty(Object.keys(record).length > 0);

        return deepmerge(props, {record});
    }, [props]);

    return (
        <Create {...createProps}>
            <SimpleForm toolbar={
                <Toolbar
                    children={
                        <div className={ToolbarClasses.defaultToolbar}>
                            <SaveButton alwaysEnable={isDirty} />
                        </div>
                    }
                />
            }>
                <Grid container spacing={1} style={{width: "100%"}} className={classes.parentGrid}>
                    <Grid container item xs={12} md={6} lg={8} flexDirection="column">
                        <ReferenceInput
                            label="Template"
                            source="template.id"
                            reference="templates"
                        >
                            <SelectInput optionText="name" />
                        </ReferenceInput>

                        <TextInput
                            label="Locale"
                            source="locale"
                        />

                        <SelectInputGroup
                            choices={variants?.data}
                            label="Product Variant"
                            groupText="title"
                            optionKey="variants"
                            optionText="title"
                            source="productVariantId"
                            sx={{
                                minWidth: 430,
                            }}
                        />

                        <RelatedItemsInput
                            label="Related Items"
                            source="relatedItems"
                        />

                        <KeyValueInput
                            label="Variables"
                            source="variables"
                        />

                        <KeyValueInput
                            label="Private Variables"
                            source="privateVariables"
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4} className={classes.rightGrid}>
                        <TemplatePreviewField
                            templateSource="template.file"
                            templateIdSource="template.id"
                            loadTemplate={true}
                            variablesSource="variables"
                            privateVariablesSource="privateVariables"
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

const useStyles = makeStyles(theme => ({
    parentGrid: {
        [theme.breakpoints.up("md")]: {
            overflowY: "scroll",
            // maxHeight: "calc(100vh - 280px)",
            alignItems: "baseline",
        },
    },
    rightGrid: {
        [theme.breakpoints.up("md")]: {
            position: "sticky",
            top: 0,
        },
    },
}));
