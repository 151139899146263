import {gql} from "@apollo/client";
import {
    CreateParams,
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateManyParams,
    UpdateParams,
} from "react-admin";
import {ResourceDataProvider} from "../../dataProvider/ResourceDataProvider";
import {Certificate, Product, UpdateCertificateInput, UpdateProductInput} from "../../../graphql/graphql";
import {cleanKeyValue} from "../../utils/clean-key-value";

export class ProductsDataProvider extends ResourceDataProvider {
    public resource = "products";

    // TODO: implement
    async create(params: CreateParams) {
        throw new Error("Not implemented");
        return {data: {id: 0}};
    }

    // TODO: implement
    async delete(params: DeleteParams) {
        throw new Error("Not implemented");
        return {data: {id: 0}};
    }

    // TODO: implement
    async deleteMany(params: DeleteManyParams) {
        throw new Error("Not implemented");
        return {};
    }

    async getList(params: GetListParams) {
        let filter = params.filter ?? {};
        const query = gql`
            query getQuery($filter: JSON!) {
                products(
                    page: ${params.pagination.page},
                    perPage: ${params.pagination.perPage},
                    sortField: "${params.sort.field}",
                    sortOrder: "${params.sort.order}",
                    filter: $filter,
                ) {
                    nodes {
                        id
                        title
                        handle
                        createdAt
                        updatedAt
                    }
                    length
            }
}
        `;

        const result = await this.client.query({query, variables: {filter}});

        return {
            data: result.data.products.nodes,
            total: result.data.products.length,
        };
    }

    // TODO: implement
    async getMany(params: GetManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }

    // TODO: implement
    async getManyReference(params: GetManyReferenceParams) {
        throw new Error("Not implemented");
        return {data: [], total: 0};
    }

    async getOne(params: GetOneParams) {
        const query = gql`
            query ($id: Int!) {
                product(id: $id) {
                    id
                    title
                    handle
                    configuration {
                        key
                        value
                    }
                    variants {
                        id
                        title
                        variantId
                        configuration {
                            key
                            value
                        }
                    }
                    createdAt
                    updatedAt
                }
            }
        `;

        const id = parseInt(params.id as string);
        const result = await this.client.query({query, variables: {id}});

        return {
            data: result.data.product,
        };
    }

    // TODO: implement
    async update(params: UpdateParams) {
        const query = gql`
            mutation update($input: UpdateProductInput!) {
                updateProduct(input: $input) {
                    id
                }
            }
        `;

        const data = params.data as Product;
        const input: UpdateProductInput = {
            id: data.id,
            configuration: cleanKeyValue(data.configuration),
            variants: (data.variants || []).map(({id, configuration}) => ({
                id,
                configuration: cleanKeyValue(configuration),
            })),
        };

        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.updateProduct,
        };
    }

    // TODO: implement
    async updateMany(params: UpdateManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }
}
