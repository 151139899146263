import React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl, {FormControlProps} from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import {useController} from "react-hook-form";

interface SelectInputGroupProps extends FormControlProps {
    source: string;
    variant?: "standard" | "outlined" | "filled";
    choices?: any[];
    label?: string;
    groupText?: string;
    optionKey?: string;
    optionText?: string;
    optionValue?: string;
}

export const SelectInputGroup = (props: SelectInputGroupProps) => {
    const {
        source,
        choices,
        groupText = "name",
        optionKey = "options",
        optionText = "name",
        optionValue = "id",
        label,
        variant,
        ...rest
    } = props;

    const {
        field: {value, onChange},
        fieldState: {isTouched, invalid, error},
        formState: {isSubmitted},
    } = useController({name: source});

    const getRenderValue = (value: any) => {
        if (!value || !choices) {
            return "";
        }

        for (const choiceGroup of choices) {
            for (const choice of choiceGroup[optionKey]) {
                if (choice[optionValue] === value) {
                    return `${choiceGroup[groupText]} - ${choice[optionText]}`;
                }
            }
        }

        return "";
    };

    return (
        <FormControl
            variant={variant || "filled"}
            {...rest}
        >
            <InputLabel>{label}</InputLabel>
            <Select
                value={value || ""}
                onChange={onChange}
                error={(isTouched || isSubmitted) && invalid}
                renderValue={getRenderValue}
            >
                <MenuItem value=""></MenuItem>
                {choices &&
                    choices.map((choiceGroup: any, key: number) => [
                        <ListSubheader key={key}>{choiceGroup[groupText]}</ListSubheader>,
                        choiceGroup[optionKey] &&
                        choiceGroup[optionKey].map((choice: any) => (
                            <MenuItem
                                value={choice[optionValue]}
                                key={choice[optionValue]}
                            >
                                {choice[optionText]}
                            </MenuItem>
                        )),
                    ])}
            </Select>

            <FormHelperText>
                {(isTouched || isSubmitted) && invalid ? error : ""}
            </FormHelperText>
        </FormControl>
    );
};
