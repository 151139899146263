import decodeJwt from "jwt-decode";
import * as RA from "react-admin";
import {UserIdentity} from "react-admin";
import merge from "lodash/merge";
import storage from "local-storage-fallback";
import {Fetch} from "../dataProvider/ResourceDataProvider";
import {Config} from "../config";

interface Credentials {
    username: string;
    password: string;
}

const accessTokenKey = "tokens";

export interface Account {
    sub: number;
    username: string;
    shop: string;
    accessToken: string;
}

export interface Accounts {
    current: Account | null;
    loggedInto: Account[];
}

export const defaultAccounts: Accounts = {
    current: null,
    loggedInto: [],
};

export class AuthProvider implements RA.AuthProvider {
    checkAuth(params: any): Promise<void> {
        try {
            getCurrentAccessToken();
            return Promise.resolve();
        } catch (e) {
            return Promise.reject();
        }
    }

    checkError(error: any): Promise<void> {
        if (error?.status === 401) {
            return Promise.reject(undefined);
        }
        return Promise.resolve(undefined);
    }

    async getIdentity(): Promise<UserIdentity> {
        return {
            id: 0,
        };
    }

    async getAccounts(): Promise<Accounts> {
        return getCurrentAccounts();
    }

    getPermissions(params: any): Promise<any> {
        const role = "admin";
        return role ? Promise.resolve(role) : Promise.reject();
    }

    async login({username, password}: Credentials): Promise<any> {
        const request = new Request(`${Config.apiBaseUrl}/auth/login`, {
            method: "POST",
            body: JSON.stringify({username, password}),
            headers: new Headers({"Content-Type": "application/json"}),
        });

        const response = await fetch(request);

        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }

        const body = await response.json();

        const {accessToken} = body;
        const decodedToken = decodeJwt(accessToken) as Omit<Account, "accessToken">;
        const account = {
            ...decodedToken,
            accessToken,
        };
        addLoggedAccount(account);
        activateAccount(account);

        return account;
    }

    logout(params: any): Promise<void | false | string> {
        storage.removeItem(accessTokenKey);
        return Promise.resolve();
    }
}

export const getCurrentAccounts = (): Accounts => {
    const data = storage.getItem(accessTokenKey);
    if (!data) {
        return defaultAccounts;
    }

    try {
        return JSON.parse(data);
    } catch (e) {
        return defaultAccounts;
    }
};

export const setCurrentAccounts = (accounts: Accounts) => {
    storage.setItem(accessTokenKey, JSON.stringify(accounts));
};

const addLoggedAccount = (account: Account) => {
    const accounts = getCurrentAccounts();
    const exists = accounts.loggedInto.find(({username}) => username === account.username);
    if (!exists) {
        accounts.loggedInto.push(account);
    }
    setCurrentAccounts(accounts);
};

export const activateAccount = (account: Account) => {
    const accounts = getCurrentAccounts();
    accounts.current = account;
    setCurrentAccounts(accounts);
}

export const getCurrentAccessToken = () => {
    const current = getCurrentAccounts().current;
    if (!current) {
        throw new Error("Couldn't find the current account.");
    }
    return current.accessToken;
};

export const jwtFetch = (token?: string): Fetch => {
    return async (uri: RequestInfo, options: RequestInit = {}) => {
        if (!token) {
            token = getCurrentAccessToken();
        }

        options = merge(options, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await fetch(uri, options);
    };
};
