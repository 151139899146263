import React from "react";
import {keyValueToObject} from "../../utils/key-value-to-object";
import {useUserStore} from "../../hooks/useUserStore";

export const ProfileName = () => {
    const {data: userStore} = useUserStore();

    if (!userStore) {
        return null;
    }
    const configuration = keyValueToObject(userStore.data.configuration || []);
    const profileName = configuration.profileName;
    if (!profileName) {
        return null;
    }
    return <div>Profile: {profileName}</div>;
};
