import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import copy from "copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";

interface Props {
    value: string;
    onCopy?: () => void;
}

export const CopyAdornment: React.FC<Props> = ({value, onCopy}) => {
    return (
        <InputAdornment
            position="end"
            sx={{marginLeft: 0}}
            disableTypography={true}
        >
            <IconButton
                aria-label="toggle password visibility"
                onClick={e => {
                    copy(value);
                    onCopy?.();
                    e.stopPropagation();
                }}
                size="small"
            >
                <FileCopyIcon />
            </IconButton>
        </InputAdornment>
    );
};
