import React from "react";
import {Datagrid, EditButton, FunctionField, List, SelectInput, TextField} from "react-admin";
import LinearProgress from "@mui/material/LinearProgress";
import createStyles from "@mui/styles/createStyles";
import {makeStyles} from "@mui/styles";
import {useLogicsOrdersCountQuery} from "./hooks/useLogicsOrdersCountQuery";
import {Type} from "../../../graphql/graphql";

const useStyles = makeStyles(theme =>
    createStyles({
        loader: {
            // width: "4em",
        },
    }),
);

const processingLogicsFilters = [
    <SelectInput
        source="type"
        emptyText="All"
        choices={[
            {id: Type.Automatic, name: "Automatic"},
            {id: Type.Manual, name: "Manual"},
            {id: Type.Processing, name: "Processing"},
        ]}
        alwaysOn
    />,
];

export const ProcessingLogicsList = (props: any) => {
    const classes = useStyles();
    const logicsOrdersQuery = useLogicsOrdersCountQuery();
    const logicsOrderIds = logicsOrdersQuery.data ? logicsOrdersQuery.data.data : [];

    return (
        <List
            {...props}
            filters={processingLogicsFilters}
            filterDefaultValues={{type: Type.Processing}}
        >
            <Datagrid rowClick="show">
                <TextField
                    source="name"
                    label="Name"
                />
                <TextField source="type" />
                <FunctionField
                    label="Order count"
                    render={(record: any) => {
                        if (logicsOrdersQuery.isLoading) {
                            return <LinearProgress className={classes.loader} />;
                        }
                        const logic = logicsOrderIds.find(item => item.processingLogicId === record.id);
                        const orderIds = logic?.count || 0;
                        return `${orderIds}`;
                    }}
                />
                ;
                <EditButton />
                {/*<Button*/}
                {/*    onClick={() => {*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <span>*/}
                {/*        <CreateNewFolderIcon/>*/}
                {/*        <Typography>*/}
                {/*            Create batch*/}
                {/*        </Typography>*/}
                {/*    </span>*/}
                {/*</Button>*/}
            </Datagrid>
        </List>
    );
};
