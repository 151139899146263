import {gql} from "@apollo/client";
import {
    CreateParams,
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateManyParams,
    UpdateParams,
} from "react-admin";
import {ResourceDataProvider} from "../../dataProvider/ResourceDataProvider";
import {PluginDataField} from "../../../graphql/graphql";

export class PluginsDataProvider extends ResourceDataProvider {
    public resource = "plugins";

    async create(params: CreateParams) {
        if (params.data.storeId) {
            return {data: null};
        }

        const query = gql`
            mutation CreatePluginStorage($input: CreatePluginStorageInput!) {
                createPluginStorage(createPluginStorageInput: $input) {
                    id
                }
            }
        `;

        // TODO: PluginDataField doesn't have pluginId
        const data: PluginDataField & {pluginId: number} = params.data;
        const input = {
            isPublic: data.isPublic,
            schema: data.schema,
            value: data.value,
            key: data.key,
            pluginId: data.pluginId,
        };

        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.createPluginStorage,
        };
    }

    // TODO: implement
    async delete(params: DeleteParams) {
        if (params.previousData?.namespace) {
            return {} as any;
        }
        const query = gql`
            mutation RemovePluginStorage($id: Int!) {
                removePluginStorage(id: $id) {
                    id
                }
            }
        `;

        const result = await this.client.query({
            query,
            variables: {id: params.id},
        });

        return {
            data: result.data,
        };
    }

    // TODO: implement
    async deleteMany(params: DeleteManyParams) {
        throw new Error("Not implemented");
        return {};
    }

    async getList(params: GetListParams) {
        const query = gql`
            {
                plugins {
                    id
                    namespace
                    enabled
                }
            }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.plugins,
            total: result.data.plugins.length,
        };
    }

    // TODO: implement
    async getMany(params: GetManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }

    // TODO: implement
    async getManyReference(params: GetManyReferenceParams) {
        throw new Error("Not implemented");
        return {data: [], total: 0};
    }

    async getOne(params: GetOneParams) {
        const query = gql`
            query ($id: Int!) {
                plugin(id: $id) {
                    id
                    namespace
                    enabled
                    data {
                        id
                        key
                        value
                        schema
                        isPublic
                    }
                }
            }
        `;

        const id = parseInt(params.id as string);
        const result = await this.client.query({query, variables: {id}});

        return {
            data: result.data.plugin,
        };
    }

    // TODO: implement
    async update(params: UpdateParams) {
        if (params.data.storeId) {
            return {data: null};
        }

        const query = gql`
            mutation UpdatePluginStorage($input: UpdatePluginStorageInput!) {
                updatePluginStorage(updatePluginStorageInput: $input) {
                    id
                }
            }
        `;

        const data = params.data as PluginDataField;
        const input = {
            id: data.id,
            isPublic: data.isPublic,
            schema: data.schema,
            value: data.value,
            key: data.key,
        };

        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.updatePluginStorage,
        };
    }

    // TODO: implement
    async updateMany(params: UpdateManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }
}
