import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import cx from "classnames";
import {ActionsMenu} from "./ActionsMenu";
import {isDirectory, RecursivePathResult} from "./types";
import FolderIcon from "@mui/icons-material/Folder";
import DescriptionIcon from "@mui/icons-material/Description";

interface Props {
    result: RecursivePathResult;
    onClick: () => void;
    showActions: boolean;
}

export const ItemLabel = (props: Props) => {
    const {result, onClick, showActions} = props;
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    if (result.name === ".keep") {
        return null;
    }

    return (
        <div className={cx(classes.item, {[classes.open]: open})}>
            <span
                className={classes.label}
                onClick={onClick}
            >
                {isDirectory(result) ? (
                    <FolderIcon
                        color="secondary"
                        fontSize="small"
                    />
                ) : (
                    <DescriptionIcon
                        color="secondary"
                        fontSize="small"
                    />
                )}
                <span className={classes.name}>{result.name}</span>
            </span>
            {showActions && (
                <ActionsMenu
                    className={classes.button}
                    result={result}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                />
            )}
        </div>
    );
};

const useStyles = makeStyles(
    theme => ({
        item: {
            display: "flex",
            "&:hover, $open": {
                "& $button": {
                    opacity: 1,
                },
            },
        },
        label: {
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
        },
        name: {
            marginLeft: theme.spacing(0.5),
        },
        actions: {},
        button: {
            opacity: 0,
            transition: "opacity 0.1s ease",
        },
        open: {
            "& $button": {
                opacity: 1,
            },
        },
    }),
    {name: "RaList"},
);
