import React, {useState} from "react";
import {useCreate, useListContext} from "react-admin";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {ActionComponent, Action} from "./Action";
import {FileListEntity, RecursivePathResult} from "../types";
import TextField from "@mui/material/TextField";

interface Props {
    result: RecursivePathResult;
}

export const CreateFolderAction: ActionComponent<Props> = React.forwardRef((props, ref) => {
    const {result, onCancel} = props;
    const [open, setOpen] = useState(false);
    const {refetch} = useListContext<FileListEntity>();

    const [folderName, setFolderName] = useState("");

    const path = result.absolute + "/" + folderName.replace(/^[\\/]+|[\\/]+$/g, "") + "/.keep";

    const [createOne] = useCreate();

    return (
        <>
            <Action onClick={() => setOpen(true)}>
                <span>Create Folder</span>
            </Action>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle id="alert-dialog-title">{"Create new folder"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Please enter the folder name</DialogContentText>

                    <TextField
                        autoFocus
                        onChange={event => setFolderName(event.target.value)}
                        value={folderName}
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpen(false);
                            onCancel?.();
                        }}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            await createOne("files", {data: {path}}, {onSuccess: () => refetch()});
                            setOpen(false);
                            onCancel?.();
                        }}
                        color="primary"
                        autoFocus
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});
