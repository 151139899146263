import React, {createRef, useEffect} from "react";
import {TextInput, useDataProvider, useNotify, useRecordContext} from "react-admin";
import {useWatch} from "react-hook-form";
import {useMutation} from "react-query";
import Button from "@mui/material/Button";
import {createStyles, makeStyles} from "@mui/styles";
import {gql} from "@apollo/client";
import {FulfillOrderInput, Order} from "../../../../graphql/graphql";
import {OverlayLoader} from "../../../components/OverlayLoader";
import Typography from "@mui/material/Typography";

interface Props {
    expanded: boolean;
    proceed: () => void;
}

export const TrackingNumber: React.FC<Props> = ({expanded, proceed}) => {
    const dataProvider = useDataProvider();
    const order = useRecordContext<Order>();
    const trackingNumber = useWatch({name: "trackingNumber"});
    const notify = useNotify();

    const {mutate, isLoading} = useMutation<string, unknown, {input: FulfillOrderInput}>(
        ["fulfillOrder", {id: order.id}],
        (variables) =>
        dataProvider.customMutate("orders", {
            mutation: gql`
                mutation fulfill($input: FulfillOrderInput!) {
                    fulfillOrder(input: $input)
                }
            `,
            variables,
        }),
    );
    const trackingNumberRef = createRef<HTMLInputElement>();

    const classes = useStyles();

    useEffect(() => {
        const currentRef = trackingNumberRef.current;
        if (expanded && currentRef) {
            currentRef.focus();
        }
    }, [expanded]);

    const updateTrackingNumber = async () => {
        const input: FulfillOrderInput = {id: order.id};

        if (trackingNumber.length) {
            input.trackingNumber = trackingNumber;
        }

        mutate({
            input,
        }, {
            onError(error) {
                notify((error as Error).message, {type: "error"});
            },
            onSuccess() {
                proceed();
            },
        });
    };

    return (
        <>
            {isLoading && <OverlayLoader />}

            <div className={classes.shippingCompany}>
                Shipping company: {order.shippingCompany}
            </div>

            <TextInput
                id=""
                helperText={false}
                source="trackingNumber"
                label="Tracking number"
                inputProps={{
                    ref: trackingNumberRef,
                    name: "",
                }}
                format={value => value ?? ""}
                onKeyPress={(event) => {
                    if (event.key == "Enter") {
                        updateTrackingNumber();
                    }
                }}
            />

            {order.fulfilled && (
                <Typography variant="caption" color="error" display="block">
                    Order is already fulfilled!
                </Typography>
            )}

            <Button
                size="small"
                variant="contained"
                color="secondary"
                className={classes.action}
                fullWidth
                onClick={() => {
                    updateTrackingNumber();
                }}
            >
                Save & mark as shipped
            </Button>
        </>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        action: {
            marginRight: theme.spacing(1),
        },
        shippingCompany: {
            fontSize: 12,
            color: "#999",
        },
    }),
);
