import React from "react";
import {
    Edit,
    TextInput,
    SimpleForm,
    NumberInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    FormDataConsumer,
    RadioButtonGroupInput,
} from "react-admin";
import {Type} from "../../../graphql/graphql";

export const ProcessingLogicsEdit = (props: any) => {
    return (
        <Edit
            {...props}
            mutationMode="pessimistic"
        >
            <SimpleForm>
                <TextInput
                    disabled
                    label="ID"
                    source="id"
                />

                <TextInput
                    label="Name"
                    source="name"
                />
                <ReferenceInput
                    source="includeFilter.id"
                    reference="filters"
                >
                    <SelectInput
                        label="Include criteria"
                        optionText="filterName"
                        format={value => value === null ? "" : value}
                    />
                </ReferenceInput>

                <ReferenceInput
                    source="excludeFilter.id"
                    reference="filters"
                >
                    <SelectInput
                        label="Exclude criteria"
                        optionText="filterName"
                        format={value => value === null ? "" : value}
                    />
                </ReferenceInput>

                <RadioButtonGroupInput source="type" choices={[
                    {id: Type.Processing, name: "Processing"},
                    {id: Type.Automatic, name: "Automatic"},
                    {id: Type.Manual, name: "Manual"},
                ]} />

                <FormDataConsumer>
                    {({formData, ...rest}) => formData.automatic && (
                        <SelectInput
                            source="function"
                            optionText="slug"
                            optionValue="slug"
                            format={value => value === null ? "" : value}
                            choices={
                                formData.pluginFunctions ?? []
                            }
                            {...rest}
                        />
                    )}
                </FormDataConsumer>

                <BooleanInput source="exclusive" />
            </SimpleForm>
        </Edit>
    );
};
