import {Certificate} from "../../../../graphql/graphql";

export const getUniqueIds = (certificates: Certificate[]) => {
    const uniqueIds: string[] = [];

    for (const certificate of certificates) {
        const property = certificate.variables.find(({key}) => key === "uniqueLineId");
        if (property && property.value) {
            uniqueIds.push(property.value);
        }

        for (const item of certificate.relatedItems) {
            uniqueIds.push(item.uniqueLineId);
        }
    }

    return uniqueIds;
};
