import React from "react";
import {makeStyles} from "@mui/styles";
import cx from "classnames";
import nl2br from "react-nl2br";
import {KeyValueField} from "../../../graphql/graphql";

interface IProps {
    keyValue: KeyValueField;
    dense?: boolean;
}

export const KeyValueItem: React.FC<IProps> = props => {
    const classes = useStyles();
    const {keyValue, dense} = props;

    const breaklined = dense ? keyValue.value : nl2br(keyValue.value);
    let multiline = false;
    if (!dense && breaklined) {
        multiline = breaklined.length > 1;
    }

    return (
        <div
            className={cx({[classes.dense]: dense})}
        >
            <span className={classes.key}>{keyValue.key}: </span>
            <span className={cx(classes.value, {[classes.denseValue]: dense})}>
                {multiline && <div className={classes.multiline}>{breaklined}</div>}
                {!multiline && breaklined}
            </span>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    key: {
        color: "#999",
        fontStyle: "italic",
        marginRight: theme.spacing(0.25),
    },
    multiline: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    value: {},
    dense: {
        whiteSpace: "nowrap",
        maxWidth: 220,
        fontSize: "0.875em",
        textOverflow: "ellipsis",
        display: "block",
        overflow: "hidden",
    },
    denseValue: {},
}));
