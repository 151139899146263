import React from "react";
import {
    ArrayField,
    Datagrid,
    DateField,
    List,
    ListProps,
    Pagination,
    SelectInput,
    TextField,
    useGetList,
    useListContext,
} from "react-admin";
import {ProcessingLogic} from "../../../graphql/graphql";

const LengthField = (props: any) => {
    const {data, isLoading} = useListContext(props);
    return !isLoading ? <>{data.length}</> : null;
};

const processingFilters = [
    <SelectInput
        source="type"
        emptyText="All"
        choices={[
            {id: "single", name: "Single"},
            {id: "batch", name: "Batch"},
        ]}
    />,
];

const ProcessingsPagination: React.FC = (props) => <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />;

export const ProcessingsList: React.FC<ListProps> = (props: any) => {
    // Technically, this should be defined as ReferenceInput>SelectInput, however react-admin required implementing the
    //   getMany in that case and did a redundant request to the backend.
    const {data: processingLogics = []} = useGetList<ProcessingLogic>("processingLogics");

    return (
        <List
            {...props}
            perPage={20}
            pagination={<ProcessingsPagination />}
            filters={[
                ...processingFilters,
                <SelectInput
                    label="Processing Logic"
                    choices={processingLogics.map(logic => ({id: logic.id, name: logic.name}))}
                    source="processingLogic.id"
                    optionText="name"
                />,
            ]}
            filterDefaultValues={{type: "batch"}}
        >
            <Datagrid rowClick="show">
                <TextField
                    source="id"
                    label="ID"
                />
                <TextField
                    source="type"
                    label="Type"
                />
                <TextField
                    source="processingLogic.name"
                    label="Processing Logic"
                />
                <ArrayField
                    source="orderProcessings"
                    label="Order count"
                >
                    <LengthField />
                </ArrayField>
                <DateField
                    source="createdAt"
                    showTime={true}
                />
                <DateField
                    source="updatedAt"
                    showTime={true}
                />
            </Datagrid>
        </List>
    );
};
