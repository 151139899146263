import {gql} from "@apollo/client";
import {
    CreateParams,
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateManyParams,
    UpdateParams,
} from "react-admin";
import {CreateTemplateInput, EntityTag, UpdateTemplateInput} from "../../../graphql/graphql";
import {ResourceDataProvider} from "../../dataProvider/ResourceDataProvider";
import {cleanKeyValue} from "../../utils/clean-key-value";

export class TemplatesDataProvider extends ResourceDataProvider {
    public resource = "templates";

    async create(params: CreateParams) {
        const query = gql`
            mutation CreateTemplate($input: CreateTemplateInput!) {
                createTemplate(input: $input) {
                    id
                }
            }
        `;

        const data = params.data;
        const input: CreateTemplateInput = {
            name: data.name,
            thumbnailId: data.thumbnailId,
            ownerId: data.ownerId,
            fileId: data.file.id,
            tags: data.tags,
            variables: data.variables,
        };
        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.createTemplate,
        };
    }

    async delete(params: DeleteParams) {
        const query = gql`
            mutation RemoveTemplate($id: Int!) {
                removeTemplate(id: $id) {
                    id
                }
            }
        `;

        const result = await this.client.query({
            query,
            variables: {id: params.id},
        });

        return {
            data: result.data,
        };
    }

    // TODO: implement
    async deleteMany(params: DeleteManyParams) {
        throw new Error("Not implemented");
        return {};
    }

    async getList(params: GetListParams) {
        const query = gql`
            {
                templates {
                    id
                    name
                    thumbnail {
                        id
                        link
                        path
                    }
                }
            }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.templates,
            total: result.data.templates.length,
        };
    }

    async getMany(params: GetManyParams) {
        const query = gql`
            {
                templates {
                    id
                    name
                }
            }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.templates,
        };
    }

    // TODO: implement
    async getManyReference(params: GetManyReferenceParams) {
        throw new Error("Not implemented");
        return {data: [], total: 0};
    }

    async getOne(params: GetOneParams) {
        const query = gql`
        {
            template(id: ${params.id}) {
                id
                name
                thumbnail {
                    id
                    path
                    link
                }
                variables {
                    key
                    value
                }
                file {
                    id
                    path
                }
                entityTags {
                    tagId
                }
            }
        }
        `;

        const result = await this.client.query({query});
        const data = result.data.template;
        const entityTags: EntityTag[] = data.entityTags;
        const tags: number[] = entityTags.map(templateTag => {
            return templateTag.tagId;
        });
        data.tags = tags;

        return {
            data,
        };
    }

    async update(params: UpdateParams) {
        const query = gql`
            mutation UpdateTemplate($input: UpdateTemplateInput!) {
                updateTemplate(input: $input) {
                    id
                }
            }
        `;

        const data = params.data;
        const input: UpdateTemplateInput = {
            id: data.id,
            name: data.name,
            thumbnailId: data.thumbnailId,
            ownerId: data.ownerId,
            fileId: data.file.id,
            tags: data.tags,
            variables: cleanKeyValue(data.variables),
        };
        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.updateTemplate,
        };
    }

    // TODO: implement
    async updateMany(params: UpdateManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }
}
