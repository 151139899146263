import {useController} from "react-hook-form";
import {CodeJarEditor} from "./CodeJarEditor";
import React from "react";
import {makeStyles} from "@mui/styles";
import createStyles from "@mui/styles/createStyles";

export const CodeJarField = () => {
    const classes = useStyles();
    const {field} = useController({name: "criteria"});

    return (
        <div className={classes.codeEditor}>
            <CodeJarEditor
                value={field.value}
                setValue={field.onChange}
                filepath={"file.sql"}
            />
        </div>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        codeEditor: {
            marginTop: theme.spacing(1),
            overflowY: "scroll",
            alignSelf: "stretch",
        },
    }),
);
