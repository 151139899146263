export const variablesChoices = [
    "star_name",
    "star_message",
    "handle",
    "finalized",
    "uniqueLineId",
    "registration_date",
];

export const privateVariablesChoices = [
    "registration_number",
    "ra",
    "dec",
    "star_id",
    "ra_string",
    "dec_string",
    "finalize_notified",
    "email",
];
