import React from "react";
import {CopyableField} from "./index";
import {Props} from "./field";

export const InlineCopyableField: React.FC<Props> = props => {
    const {value, children, display = "inline", ...rest} = props;

    return (
        <CopyableField
            value={value}
            useNotify={true}
            display={display}
            {...rest}
        >
            {children || value}
        </CopyableField>
    );
};
