import React, {useEffect, useMemo, useState} from "react";
import {TextInput, TextInputProps, useGetList, useInput, useRecordContext} from "react-admin";
import {Template} from "../types";
import {FileListEntity} from "../../../components/FileBrowser/types";
import {pathsToTree} from "../../../components/FileBrowser/pathsToTree";
import {makeStyles} from "@mui/styles";
import {ChooseFileDialog} from "../../../components/ChooseFileDialog";
import {useFormContext} from "react-hook-form";

export const TemplateSelector = (props: TextInputProps) => {
    const {
        isRequired,
        fieldState: {isTouched, error},
        formState: {},
    } = useInput(props);

    const {setValue} = useFormContext();

    const context = useRecordContext<Template>();
    const {data, isLoading} = useGetList<FileListEntity>("files");
    const [open, setOpen] = useState(false);
    const [currentFile, setCurrentFile] = useState(context?.file);

    useEffect(() => setCurrentFile(context?.file), [context]);

    const tree = useMemo(() => {
        return pathsToTree(data || []);
    }, [data]);

    const classes = useStyles();

    if (isLoading) {
        return null;
    }

    return (
        <>
            <TextInput
                type="hidden"
                source="file.id"
                className={classes.hidden}
            />
            <TextInput
                fullWidth={true}
                inputProps={{
                    className: classes.currentFileInput,
                    readOnly: true,
                }}
                source="file.path"
                label="Current Template"
                onClick={() => setOpen(true)}
                error={!!(isTouched && error)}
                required={isRequired}
                helperText={isTouched && error ? "Error in file path" : ""}
            />

            <ChooseFileDialog
                tree={tree}
                open={open}
                setOpen={setOpen}
                currentFile={currentFile}
                onOpenFile={file => {
                    setOpen(false);
                    setCurrentFile(file);
                    setValue("file.path", file.path);
                    setValue("file.id", file.id);
                }}
            />
        </>
    );
};

const useStyles = makeStyles(() => ({
    currentFileInput: {
        cursor: "pointer",
    },
    hidden: {
        display: "none",
    },
}));
