import React from "react";
import {ArrayInput, Edit, SimpleForm, SimpleFormIterator, TextInput} from "react-admin";
import {KeyValueInput} from "../../components/KeyValueField";
import {CopyableInput} from "../../components/CopyableField";

export const ProductsEdit = (props: any) => {
    return (
        <Edit
            {...props}
            mutationMode="pessimistic"
        >
            <SimpleForm>
                <TextInput
                    disabled
                    label="ID"
                    source="id"
                />

                <TextInput
                    disabled
                    source="title"
                />
                <TextInput
                    disabled
                    source="handle"
                />

                <KeyValueInput
                    label="Product Configuration"
                    source="configuration"
                />

                <ArrayInput source="variants">
                    <SimpleFormIterator
                        TransitionProps={{classNames: "", addEndListener: () => {}}}
                        disableReordering
                        disableRemove
                        disableAdd
                    >
                        <TextInput
                            disabled
                            source="id"
                            label="resources.products.fields.id"
                        />

                        <CopyableInput
                            disabled
                            label="Shopify Variant ID"
                            source="variantId"
                        />

                        <TextInput
                            disabled
                            source="title"
                            label="resources.products.fields.id"
                        />

                        <KeyValueInput
                            label="Variant Configuration"
                            source="configuration"
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
};
