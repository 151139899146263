import React from "react";
import {ListBase} from "react-admin";
import {Editor} from "./editor";

export const FilesComponent = (props: any) => {
    return (
        <ListBase {...props}>
            <Editor {...props} />
        </ListBase>
    );
};
