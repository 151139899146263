import React from "react";
import {FunctionField, TextFieldProps} from "react-admin";
import {makeStyles} from "@mui/styles";
import {KeyValueArray} from "./KeyValueArray";
import {KeyValueField} from "../../../graphql/graphql";

interface IProps {
    dense?: boolean;
}

const KeyValueField: React.FC<IProps & TextFieldProps> = props => {
    const classes = useStyles();
    const {source} = props;
    if (!source) {
        return null;
    }

    const {dense, ...rest} = props;

    return (
        <FunctionField
            label="Order count"
            {...rest}
            render={(record: any) => {
                const variables: KeyValueField[] = record[source];
                return (
                    <KeyValueArray
                        keyValues={variables}
                        dense={dense}
                    />
                );
            }}
        />
    );
};

const useStyles = makeStyles(theme => ({
    root: {},
}));

export default KeyValueField;
