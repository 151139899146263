import React, {ChangeEvent} from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {makeStyles} from "@mui/styles";
import {FileListEntity, FSNodeType, isDirectory, RecursivePathResult} from "./types";
import {BrowserItem} from "./BrowserItem";
import {resultsSorter} from "./resultsSorter";
import {useLocalStorage} from "../../hooks/useLocalStorage";

export type FileCallback = (entity: FileListEntity) => void;

interface ItemOptions {
    showActions?: boolean;
    onOpenFile?: FileCallback;
    currentFile?: FileListEntity;
    fileAvailable?: (file: RecursivePathResult) => boolean;
}

interface Props extends ItemOptions {
    tree: RecursivePathResult[];
    className?: string;
    defaultExpanded?: string[];
}

export const FileBrowser = (props: Props) => {
    const classes = useStyles();
    const {tree, defaultExpanded = []} = props;

    const [expanded, setExpanded] = useLocalStorage<string[]>("expandedDirectories", defaultExpanded || []);

    const handleToggle = (event: ChangeEvent<{}>, nodeIds: string[]) => setExpanded(nodeIds);

    return (
        <div
            className={props.className}
            style={{minWidth: 250, margin: "1em"}}
        >
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeToggle={handleToggle}
                expanded={[
                    ...expanded,
                    ...defaultExpanded,
                ]}
            >
                {buildChildren(tree, props)}
            </TreeView>
        </div>
    );
};

const buildChildren = (recursivePathResults: RecursivePathResult[], itemOptions: ItemOptions) => {
    const {onOpenFile} = itemOptions;

    const showActions = typeof itemOptions.showActions !== "undefined" ? itemOptions.showActions : true;

    return recursivePathResults.sort(resultsSorter).map(result => (
        <BrowserItem
            showActions={showActions}
            result={result}
            key={result.absolute}
            nodeId={result.absolute}
            text={result.name}
            active={itemOptions.currentFile?.path === result.absolute}
            available={result.type === FSNodeType.Directory || (itemOptions.fileAvailable?.(result) ?? true)}
            onClick={() => {
                if (!isDirectory(result)) {
                    const entity = {
                        id: result.attributes.id,
                        path: result.absolute,
                        link: result.attributes.link,
                        fileType: result.attributes.fileType,
                    } as FileListEntity;
                    onOpenFile?.(entity);
                }
            }}
        >
            {isDirectory(result) && buildChildren(result.children, itemOptions)}
        </BrowserItem>
    ));
};

const useStyles = makeStyles(
    () => ({
        root: {
            flexGrow: 1,
            maxWidth: 400,
        },
    }),
    {name: "RaList"},
);
