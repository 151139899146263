import React, {useEffect, useState} from "react";
import {useLogout, useNotify} from "react-admin";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {makeStyles} from "@mui/styles";
import cx from "classnames";
import {activateAccount} from "../../authProvider";
import {useGetAccounts} from "../../hooks/useGetAccounts";
import {useReload} from "../../hooks/useReload";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {useUserStore} from "../../hooks/useUserStore";
import {PopupLoginForm} from "../../components/PopupLoginForm";

export const AccountSwitcher = () => {
    const classes = useStyles();

    const logout = useLogout();

    const [loginFormOpen, setLoginFormOpen] = useState(false);
    const {accounts: {current, loggedInto}} = useGetAccounts();
    const reload = useReload();
    const notify = useNotify();
    const [notification, setNotification] = useLocalStorage("switchNotification", "", false);
    const {data: userStore} = useUserStore();

    const switchableAccounts = loggedInto.filter((account: any) => account.username !== current?.username);

    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = (event: any) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    useEffect(() => {
        setTimeout(() => {
            if (notification) {
                notify(notification, {
                    type: "success",
                });
                setNotification("")
            }
        }, 100);
    }, [notification]);

    return (
        <Box sx={{flexGrow: 0}}>
            <PopupLoginForm
                open={loginFormOpen}
                onClose={() => setLoginFormOpen(false)}
                onSuccess={(account) => {
                    setLoginFormOpen(false);
                    setNotification(`Logged into: ${account.shop}`);
                    reload();
                }}
            />


            <Tooltip title="Open settings">
                <IconButton
                    size="small"
                    onClick={handleOpenUserMenu}
                >
                    <AccountCircle fontSize="large"/>
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem
                    onClick={() => {
                        handleCloseUserMenu();
                        logout();
                    }}
                >
                    <Typography align="center">Logout</Typography>
                </MenuItem>

                <MenuItem className={cx(classes.dividerTop, classes.noHover)}>
                    <div className={classes.title}>
                        Current account:
                    </div>
                </MenuItem>

                <MenuItem className={classes.noHover}>
                    <div className={classes.accountName}>
                        {current?.shop}
                    </div>
                </MenuItem>

                {switchableAccounts.length > 0 && (
                    <MenuItem className={classes.noHover}>
                        <div className={classes.title}>
                            Switch to:
                        </div>
                    </MenuItem>
                )}

                {switchableAccounts.map((account) => (
                    <MenuItem key={account.shop} onClick={() => {
                        activateAccount(account);
                        setNotification(`Switched to: ${account.shop}`);
                        handleCloseUserMenu();
                        reload();
                    }}>
                        <div>
                            <div className={classes.accountName}>
                                {account.shop}
                            </div>
                        </div>
                    </MenuItem>
                ))}

                <MenuItem className={classes.dividerTop} onClick={() => {
                    setLoginFormOpen(true);
                    handleCloseUserMenu();
                }}>
                    <div>
                        + add account
                    </div>
                </MenuItem>
            </Menu>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    dividerTop: {
        borderTop: "1px solid #ddd",
    },
    noHover: {
        cursor: "auto",
        "&:hover": {
            background: "none",
        },
    },
    title: {
        color: "#888",
        fontWeight: 600,
        fontStyle: "italic",
        fontSize: 14,
    },
    accountName: {
        fontSize: 13,
    },
}));
