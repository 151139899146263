import React from "react";
import {List, useShowController} from "react-admin";
import Box from "@mui/material/Box";
import {makeStyles} from "@mui/styles";
import {CustomToolbar} from "./Show/CustomToolbar";
import {OrderPagination} from "./Show/OrderPagination";
import {OrdersGrid} from "../orders/OrdersGrid";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
    },
    list: {},
}));

export const ProcessingLogicsShow = (props: any) => {
    const {isLoading, record} = useShowController(props);
    const classes = useStyles();

    if (isLoading || !record) {
        return null;
    }

    // TODO: find a way to do it via <List/> component or creating custom data list from dataProvider.getList.
    const filter = {
        processingLogicId: record.id,
        orderName: undefined,
        email: undefined,
        fulfilled: undefined,
        includeFilterId: undefined,
    };

    return (
        <Box
            className={classes.root}
            sx={{width: "100%"}}
        >
            <CustomToolbar {...props} />

            <List
                {...props}
                empty={false}
                className={classes.list}
                pagination={<OrderPagination />}
                actions={false}
                name="processingLogicOrders"
                resource="orders"
                filter={filter}
                sort={{field: "id", order: "DESC"}}
                perPage={25}
            >
                <OrdersGrid />
            </List>
        </Box>
    );
};
