import {useDataProvider} from "react-admin";
import {useQuery} from "react-query";
import {ApolloQueryResult, gql} from "@apollo/client";
import {Store} from "../../graphql/graphql";

export const useUserStore = () => {
    const dataProvider = useDataProvider();

    return useQuery<{data: Store}>(["stores", "findUserStore"], () =>
        dataProvider.customFetch("stores", {
            query: gql`
                {
                    userStore {
                        id
                        shop
                        configuration {
                            key
                            value
                        }
                        createdAt
                        updatedAt
                    }
                }
            `,
            resolver: (result: ApolloQueryResult<any>) => ({data: result.data.userStore}),
        }),
    );
};
