import React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {FileBrowser, FileCallback} from "../FileBrowser/FileBrowser";
import {FileListEntity, RecursivePathResult} from "../FileBrowser/types";
import {makeStyles} from "@mui/styles";

interface Props {
    tree: RecursivePathResult[];
    open: boolean;
    setOpen: (open: boolean) => void;
    onOpenFile?: FileCallback;
    currentFile?: FileListEntity;
    fileAvailable?: (file: RecursivePathResult) => boolean;
}

export const ChooseFileDialog = (props: Props) => {
    const {tree, open, setOpen, onOpenFile, currentFile, fileAvailable} = props;

    const classes = useStyles();

    let currentFolder = "";
    const expandedFolders =
        currentFile &&
        currentFile.path
            .split("/")
            .slice(0, -1)
            .map(part => (currentFolder += part + "/") && currentFolder);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle id="alert-dialog-title">{"Select file"}</DialogTitle>
            <DialogContent className={classes.content}>
                <DialogContentText id="alert-dialog-description">Please select the file from below</DialogContentText>

                <FileBrowser
                    showActions={false}
                    onOpenFile={onOpenFile}
                    tree={tree}
                    defaultExpanded={expandedFolders || ["/"]}
                    currentFile={currentFile}
                    fileAvailable={fileAvailable}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setOpen(false);
                    }}
                    color="secondary"
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles(
    () => ({
        content: {
            height: "50vh",
        },
    }),
    {name: "RaList"},
);
