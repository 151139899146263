import React from "react";
import {SimpleFormIterator, SimpleFormIteratorClasses, TextInput, NumberInput} from "react-admin";
import {makeStyles} from "@mui/styles";
import {SelectInputGroup} from "../SelectInputGroup/SelectInputGroup";
import {useListProductVariants} from "../../resources/certificates/hooks/useListProductVariants";

interface IteratorProps {
    source: string;
}

// TODO: The variant should be dropdown-selected. And should update the CertificatesDataProvider so the related items
//   also get saved into the database (update).
const Iterator = (props: IteratorProps) => {
    const {source} = props;
    const iteratorClasses = useIteratorStyles();
    const classes = useStyles();
    const {data: variants} = useListProductVariants();

    return (
        <SimpleFormIterator
            className={iteratorClasses.root}
            disableReordering
            source={source}
        >
            <SelectInputGroup
                choices={variants?.data}
                label="Product Variant"
                groupText="title"
                optionKey="variants"
                optionValue="variantId"
                optionText="title"
                source="variantId"
                sx={theme => ({
                    minWidth: 200,
                    flex: 1,
                    marginRight: theme.spacing(2),
                })}
            />

            <NumberInput
                min="0"
                step="1"
                helperText={false}
                source="qty"
                label={"QTY"}
                className={classes.input}
            />

            <TextInput
                helperText={false}
                source="uniqueLineId"
                label={"Unique Line ID"}
                className={classes.input}
                sx={{
                    flex: 1,
                }}
            />
        </SimpleFormIterator>
    );
};

const useIteratorStyles = makeStyles(theme => ({
    root: {
        [`& .${SimpleFormIteratorClasses.form}`]: {
            flexDirection: "row",
            alignItems: "center",
        },
    },
}));

const useStyles = makeStyles(theme => ({
    input: {
        marginRight: theme.spacing(2),
    },
}));

export default Iterator;
