import React from "react";
import {useEditContext, useRecordContext} from "react-admin";
import createStyles from "@mui/styles/createStyles";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {Order} from "../../../../graphql/graphql";
import {CertificateField} from "../../../components/CertificateField";
import {getOrderShopifyUrl} from "../../../utils/get-order-shopify-url";

export const InfoModal: React.FC = (props) => {
    const record = useRecordContext(props) as Order;
    const classes = useStyles();

    const {isFetching} = useEditContext<Order>();

    if (!record || isFetching) {
        return <></>;
    }

    let address = "Address not available";
    const {shippingAddress} = record;
    if (shippingAddress) {
        const {address1, address2, city, country, zip} = shippingAddress;
        address = `${address1}, ${address2}, ${city}, ${country}, ${zip}`;
    }

    return (
        <div>
            <div className={classes.order}>
                <Typography
                    variant="h3"
                    gutterBottom
                >
                    {record.orderName}
                </Typography>
                <Typography
                    variant="body2"
                    gutterBottom
                >
                    Email: {record.email}
                    <br />
                    Address: {address}
                    <br />
                    Financial status: {record.financialStatus}
                    <br />
                    Fulfillment status: {record.fulfillmentStatus}
                    <br />
                    Shopify ID:&nbsp;
                    <a href={getOrderShopifyUrl(record)} target="_blank">
                        {record.shopifyOrderId}
                        <OpenInNewIcon fontSize={"small"} sx={{verticalAlign: "middle"}} />
                    </a>
                </Typography>
            </div>
            <div>
                <Typography
                    variant="h4"
                    gutterBottom
                >
                    Certificates
                </Typography>
                {record.orderCertificates.map(({certificate}) => {
                    return (
                        <CertificateField
                            key={certificate.id}
                            certificate={certificate}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        order: {
            marginBottom: theme.spacing(1),
        },
    }),
);
