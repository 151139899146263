import {DashboardComponent, MenuItemLink, useGetResourceLabel, useResourceDefinitions, useTranslate} from "react-admin";
import {Menu as RaMenu} from "ra-ui-materialui/dist/cjs/layout/Menu";
import React from "react";
import {makeStyles} from "@mui/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import cx from "classnames";

interface Props {
    dashboard?: DashboardComponent;
}

export const Navigation: React.FC<Props> = (props) => {
    const classes = useStyles();
    const translate = useTranslate();

    const resourcesDefinitions = useResourceDefinitions();
    const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);
    const getResourceLabel = useGetResourceLabel();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const configurationResourceNames = [
        "files",
        "templates",
        "products",
        "plugins",
        "filters",
    ];

    const configurationResources = resources.filter(r => configurationResourceNames.indexOf(r.name) > -1);
    const otherResources = resources.filter(r => r.hasList && configurationResources.indexOf(r) === -1);

    return (
        <RaMenu className={classes.menu}>
            <MenuItemLink
                className={cx(classes.hideOnMd, classes.logo, classes.menuItem)}
                key="SRF"
                to="/"
                state={{_scrollToTop: true}}
                primaryText="SRF"
            />

            <MenuItem className={classes.hideOnDesktop} onClick={() => setDrawerOpen(true)}>
                <MenuIcon />
            </MenuItem>

            <Drawer
                anchor={"left"}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <MenuItemLink
                    className={cx(classes.mobileMenuItem, classes.logo, classes.menuItem)}
                    key="SRF"
                    to="/"
                    state={{_scrollToTop: true}}
                    primaryText="Home"
                />

                {otherResources.map(resource => (
                    <MenuItemLink
                        key={resource.name}
                        to={{
                            pathname: `/${resource.name}`,
                        }}
                        className={cx(classes.mobileMenuItem, classes.menuItem)}
                        state={{_scrollToTop: true}}
                        primaryText={getResourceLabel(resource.name, 2)}
                    />
                ))}
            </Drawer>

            {otherResources.map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={{
                        pathname: `/${resource.name}`,
                    }}
                    className={cx(classes.hideOnMd, classes.menuItem)}
                    state={{_scrollToTop: true}}
                    primaryText={getResourceLabel(resource.name, 2)}
                />
            ))}

            <MenuItem
                onClick={handleClick}
                className={classes.menuItem}
            >
                Configuration
            </MenuItem>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: -2,
                    horizontal: "center",
                }}
                MenuListProps={{
                    className: classes.configurationMenu,
                }}
            >
                <MenuItemLink
                    className={classes.configurationItem}
                    onClick={handleClose}
                    key="Settings"
                    to="/configuration"
                    state={{_scrollToTop: true}}
                    primaryText="Settings"
                />

                {configurationResources.map(resource => (
                    <MenuItemLink
                        key={resource.name}
                        onClick={handleClose}
                        to={{
                            pathname: `/${resource.name}`,
                        }}
                        className={classes.configurationItem}
                        state={{_scrollToTop: true}}
                        primaryText={getResourceLabel(resource.name, 2)}
                    />
                ))}
            </Menu>
        </RaMenu>
    );
};

const useStyles = makeStyles(theme => {
    return ({
        hideOnMd: {
            [theme.breakpoints.down("md")]: {
                display: "none",
            },
        },
        hideOnDesktop: {
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        logo: {
        },
        menu: {
            flexDirection: "row",
            marginTop: 0,
            marginBottom: 0,
            marginLeft: theme.spacing(2),
            flexGrow: 1,
            alignSelf: "stretch",
            [theme.breakpoints.down("md")]: {
                marginLeft: theme.spacing(0.5),
            },
        },
        menuItem: {
            [theme.breakpoints.down("md")]: {
                padding: theme.spacing(1),
                // fontSize: "0.875rem",
            },
        },
        mobileMenuItem: {
            background: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
            "&:hover": {
                backgroundColor: "rgba(60, 60, 60, 0.8)"
            },
        },
        configurationMenu: {
            background: theme.palette.primary.dark,
        },
        configurationItem: {
            borderLeft: "3px solid transparent",

            "&:hover": {
                borderLeftColor: theme.palette.primary.light,
            },
            "&.RaMenuItemLink-active": {
                // borderLeftColor: theme.palette.primary.light,
            },
        },
    });
});
