import React from "react";
import {makeStyles} from "@mui/styles";
import {FileListEntity, FilePathResult} from "../../../../components/FileBrowser/types";
import {FileCallback} from "../../../../components/FileBrowser/FileBrowser";
import CancelIcon from "@mui/icons-material/Cancel";
import cx from "classnames";
import {pathToFilename} from "../../../../components/FileBrowser/pathToFilename";

interface IProps {
    onClose: () => void;
    onOpen: () => void;
    file: FileListEntity;
    isActive: boolean;
}

export const File = (props: IProps) => {
    const classes = useStyles();

    const {file, isActive, onClose, onOpen} = props;
    const name = pathToFilename(file);

    return (
        <div className={cx(classes.main, {[classes.active]: isActive})}>
            <span
                className={classes.label}
                onClick={onOpen}
            >
                {name}
            </span>
            <CancelIcon
                className={classes.close}
                onClick={onClose}
            />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    main: {
        padding: "0.5em 0.5em 0.5em 0.75em",
        cursor: "pointer",
        opacity: 0.6,
        transition: "0.2s ease all",
        borderBottom: `2px solid transparent`,
        "&$active": {
            opacity: 1,
        },
        "&:hover": {
            opacity: 1,
        },
    },
    active: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    label: {
        verticalAlign: "middle",
    },
    close: {
        marginLeft: theme.spacing(1),
        verticalAlign: "middle",
        opacity: 0.7,
        transition: "0.2s ease all",

        "&:hover": {
            opacity: 1,
        },
    },
}));
