import * as React from "react";
import {useEffect, useContext} from "react";
import {useListContext} from "ra-core";
import {FormProvider, useForm} from "react-hook-form";
import lodashUnset from "lodash/unset";
import lodashGet from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import {FilterContext, FilterFormProps, getFilterFormValues, mergeInitialValuesWithDefaultValues} from "react-admin";
import {CustomFilterFormBase} from "./CustomFilterFormBase";

export const CustomFilterForm = (props: FilterFormProps) => {
    const {defaultValues, filters: filtersProps, ...rest} = props;

    const {setFilters, displayedFilters, filterValues} = useListContext(props);
    const filters = useContext(FilterContext) || filtersProps;

    const mergedInitialValuesWithDefaultValues = mergeInitialValuesWithDefaultValues(
        defaultValues || filterValues,
        filters,
    );

    const form = useForm({
        defaultValues: mergedInitialValuesWithDefaultValues,
    });

    // Reapply filterValues when the URL changes or a user removes a filter
    useEffect(() => {
        const newValues = getFilterFormValues(form.getValues(), filterValues);
        form.reset(newValues);
    }, [filterValues, form]);

    useEffect(() => {
        const subscription = form.watch(async (values, info) => {
            // We must check whether the form is valid as watch will not check that for us.
            // We can't rely on form state as it might not be synchronized yet
            const isFormValid = await form.trigger();

            if (isFormValid && info.name) {
                if (lodashGet(values, info.name) === "") {
                    const newValues = cloneDeep(values);
                    lodashUnset(newValues, info.name);
                    setFilters(newValues, displayedFilters);
                } else {
                    setFilters(values, displayedFilters);
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [displayedFilters, form, setFilters]);

    return (
        <FormProvider {...form}>
            <CustomFilterFormBase
                onSubmit={() => {}}
                filters={filters}
                {...rest}
            />
        </FormProvider>
    );
};
