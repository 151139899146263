import React, {useState} from "react";
import {Button, TopToolbar, useCreate, useRedirect, useShowController} from "react-admin";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {FilterInfo} from "./FilterInfo";
import {LimitModal} from "./LimitModal";

const useStyles = makeStyles(theme => ({
    titleBar: {
        alignSelf: "center",
    },
    count: {
        marginRight: 10,
        maxWidth: 60,
    },
    toolbar: {
        justifyContent: "space-between",
        // alignItems: "flex-start",
        alignItems: "center",
        paddingRight: 0,
        [theme.breakpoints.up("xs")]: {
            paddingLeft: 0,
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing(2),
            backgroundColor: theme.palette.background.paper,
        },
    },
    topToolbar: {
        padding: 0,
        alignItems: "center",
    },
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
}));

export const CustomToolbar = (props: any) => {
    const {isLoading, record} = useShowController(props);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const redirect = useRedirect();

    const [limitModalOpen, setLimitModalOpen] = useState(false);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [createEntity] = useCreate();

    const createBatch = async (limit: number) => {
        const response = await createEntity("processings", {
            data: {
                processingLogicId: record.id,
                limit,
            },
        }, {
            onSuccess(response) {
                redirect(`/processings/${response.id}/show`);
            }
        });
        console.log(response);
    }

    const choices = [3, 10, 50, 100, 200];

    if (isLoading || !record) {
        return null;
    }

    const {includeFilter, excludeFilter} = record;

    return (
        <>
            <LimitModal
                open={limitModalOpen}
                onClose={() => setLimitModalOpen(false)}
                onSubmit={(limit: number) => createBatch(limit)}
            />

            <Toolbar className={classes.toolbar}>
                <Box className={classes.titleBar}>
                    <Typography
                        variant="h4"
                        color="textPrimary"
                    >
                        Filter: {record.name}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        component="em"
                        color="textSecondary"
                    >
                        <FilterInfo
                            includeFilter={includeFilter}
                            excludeFilter={excludeFilter}
                        />
                    </Typography>
                </Box>
                <span/>
                <TopToolbar className={classes.topToolbar}>
                    <Button
                        onClick={event => setAnchorEl(event.currentTarget)}
                        label="Create Batch"
                    >
                        <CreateNewFolderIcon/>
                    </Button>

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {choices.map(choice => (
                            <MenuItem
                                key={choice}
                                onClick={async () => {
                                    await createBatch(choice);
                                    // return handleClose;
                                }}
                            >
                                {choice} orders
                            </MenuItem>
                        ))}
                        <MenuItem onClick={() => setLimitModalOpen(true)}>
                            Custom
                        </MenuItem>
                    </Menu>
                </TopToolbar>
            </Toolbar>
        </>
    );
};
