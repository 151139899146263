import React, {useEffect, useState} from "react";
import createStyles from "@mui/styles/createStyles";
import {makeStyles} from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {PluginDataField} from "../../../../graphql/graphql";
import {CodeJarEditor} from "../../../components/CodeJarEditor/CodeJarEditor";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";

interface IProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: PluginDataField) => void;
    data: PluginDataField;
}

export const PluginStorageModal = ({open, onClose, data, onSubmit}: IProps) => {
    const initialSchema = JSON.stringify(
        {
            title: "new schema",
            description: "new schema for plugin storage",
            type: "array",
            items: {
                properties: {name: {type: "string"}, job: {type: "string"}},
                required: ["name", "job"],
            },
        },
        null,
        4,
    );

    const [state, setState] = useState<PluginDataField>({
        id: 0,
        key: "",
        isPublic: false,
        schema: initialSchema,
        value: "[]",
    });
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        let schema = initialSchema;
        if (data && data.id !== 0) {
            schema = JSON.stringify(JSON.parse(data.schema), null, 4);
        }
        if (data) {
            setState({...data, schema});
        }
    }, [data]);

    const handleChange = (field: string, value: string | boolean | number) => {
        setState({...state, [field]: value});
        setIsDirty(true);
    };

    const handleReset = () => {
        let schema = initialSchema;
        if (data && data.id !== 0) {
            schema = JSON.stringify(JSON.parse(data.schema), null, 4);
        }
        setState({...data, schema});
        setIsDirty(false);
    };

    const handleSubmit = () => {
        onSubmit(state);
    };

    const classes = useStyles();

    return (
        <Dialog
            maxWidth={false}
            open={open}
            onClose={() => !isDirty && onClose()}
        >
            <DialogContent>
                <Input
                    id="id"
                    type="hidden"
                    value={state.id}
                />
                <FormControl fullWidth={true}>
                    <InputLabel
                        htmlFor="key"
                        variant="standard"
                    >
                        Key
                    </InputLabel>
                    <Input
                        id="my-input"
                        aria-describedby="my-helper-text"
                        value={state.key}
                        onChange={e => handleChange("key", e.target.value)}
                    />
                </FormControl>
                <FormControl
                    fullWidth={true}
                    margin="normal"
                >
                    <FormControlLabel
                        label="Public"
                        control={
                            <Checkbox
                                checked={state.isPublic || false}
                                onChange={() => handleChange("isPublic", !state.isPublic)}
                            />
                        }
                    />
                </FormControl>
                <FormControl
                    fullWidth={true}
                    margin="normal"
                >
                    <FormLabel htmlFor="key">Schema</FormLabel>
                    <div className={classes.codeEditor}>
                        <CodeJarEditor
                            value={state.schema}
                            setValue={value => handleChange("schema", value)}
                            filepath={"schema.json"}
                        />
                    </div>
                </FormControl>

                <Button
                    disabled={!isDirty}
                    className={classes.resetButton}
                    variant="contained"
                    color="primary"
                    startIcon={<UndoIcon />}
                    onClick={handleReset}
                >
                    Reset
                </Button>

                <Button
                    className={classes.saveButton}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        codeEditor: {
            marginTop: theme.spacing(1),
            maxHeight: 300,
            overflowY: "scroll",
        },
        saveButton: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        resetButton: {
            marginRight: theme.spacing(1),
            backgroundColor: "red",
        },
    }),
);
