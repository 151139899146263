import React, {useMemo} from "react";
import {makeStyles} from "@mui/styles";
import Box from "@mui/material/Box";
import cx from "classnames";
import {RelatedItemField} from "../../../graphql/graphql";
import {useListProductVariants} from "../../resources/certificates/hooks/useListProductVariants";

export type RelatedItemProps = RelatedItemField & {
    strikeThrough?: boolean;
};

interface IProps {
    relatedItem: RelatedItemProps;
    dense?: boolean;
}

const relatedItemsColorMapping: {[handle: string]: string;} = {
    "swarovski-star": "#F9E862",
    "frame": "#59C4AF",
    "additonal-folder": "#aef359",
    "additional-folder": "#aef359", // somewhere else is misspelled
    "additional-copies": "#7FC0F9",
    "document-copies": "#7FC0F9",
    "zodiac-sign-necklace": "#F19675",
};

const defaultBackgroundColor = "#fff";

export const RelatedItem: React.FC<IProps> = props => {
    const classes = useStyles();
    const {relatedItem, dense} = props;

    if (relatedItem.qty < 1) {
        return null;
    }

    const {data: products} = useListProductVariants();

    const [product, variant] = useMemo(() => {
        const productList = products?.data || [];
        for (let product of productList) {
            for (let variant of product.variants) {
                if (variant.variantId === relatedItem.variantId) {
                    return [product, variant];
                }
            }
        }
        return [];
    }, [products, relatedItem])

    let title = `${relatedItem.handle}`;

    if (product && variant) {
        title = `${product.title} - ${variant.title}`
    }

    const backgroundColor = relatedItemsColorMapping[relatedItem.handle] || defaultBackgroundColor;

    return (
        <div
            className={cx({[classes.dense]: dense})}
        >
            <Box
                className={classes.relatedItem}
                sx={{
                    backgroundColor,
                    ...(relatedItem.strikeThrough && {
                        textDecoration: "line-through",
                    }),
                }}
                title={`handle: ${relatedItem.handle}`}
            >
                {relatedItem.qty > 1 && <>{relatedItem.qty}x </>}
                {title}
            </Box>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    multiline: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    value: {},
    dense: {
        whiteSpace: "nowrap",
        maxWidth: 220,
        fontSize: "0.875em",
        textOverflow: "ellipsis",
        display: "block",
        overflow: "hidden",
    },
    relatedItem: {
        display: "inline-block",
        marginTop: theme.spacing(0.5),
        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
        borderRadius: 3,
    },
    denseValue: {},
}));
