import React from "react";
import {BulkDeleteButton} from "react-admin";
import {CreateBatchMenu} from "./bulk-actions/create-batch-menu";

export const ListBulkActions: React.FC = () => {
    return (
        <>
            <BulkDeleteButton />
            <CreateBatchMenu />
        </>
    );
};
