import {
    ApolloCache,
    ApolloClient as DefaultApolloClient,
    ApolloQueryResult, DefaultContext,
    FetchResult,
    MutationOptions,
    NormalizedCacheObject,
    OperationVariables,
    QueryOptions,
} from "@apollo/client";
import {HttpError} from "react-admin";

export class ApolloClient extends DefaultApolloClient<NormalizedCacheObject> {
    async query<T = any, TVariables = OperationVariables>(
        options: QueryOptions<TVariables, T>,
    ): Promise<ApolloQueryResult<T>> {
        const response = await super.query(options);
        const errors = response.errors || [];

        for (const error of errors) {
            const extensions = error.extensions;
            const response = extensions.response as any;
            if (extensions.code === "UNAUTHENTICATED") {
                throw new HttpError(error.message, response?.statusCode || 401, response.data);
            }
        }

        return response;
    }

    async mutate<TData = any, TVariables = OperationVariables, TContext = DefaultContext, TCache extends ApolloCache<any> = ApolloCache<any>>(
        options: MutationOptions<TData, TVariables, TContext>,
    ): Promise<FetchResult<TData>> {
        const response = await super.mutate(options);
        const errors = response.errors || [];

        for (const error of errors) {
            const extensions = error.extensions;
            const response = extensions.response as any;
            if (extensions.code === "UNAUTHENTICATED") {
                throw new HttpError(error.message, response?.statusCode || 401, response.data);
            }
        }

        return response;
    }
}
