import {useEffect, useState} from "react";
import {useAuthProvider} from "react-admin";
import {Accounts, AuthProvider, getCurrentAccounts} from "../authProvider";

interface State {
    isLoading: boolean;
    accounts: Accounts;
    error?: any;
}

export const useGetAccounts = () => {
    const [state, setState] = useState<State>({
        isLoading: true,
        accounts: getCurrentAccounts(),
    });
    const authProvider = useAuthProvider() as AuthProvider;
    useEffect(() => {
        if (authProvider && typeof authProvider.getAccounts === "function") {
            const callAuthProvider = async () => {
                try {
                    const accounts = await authProvider.getAccounts();
                    setState({
                        isLoading: false,
                        accounts,
                    });
                } catch (error) {
                    setState({
                        isLoading: false,
                        accounts: getCurrentAccounts(),
                        error,
                    });
                }
            };
            callAuthProvider();
        } else {
            setState({
                isLoading: false,
                accounts: getCurrentAccounts(),
            });
        }
    }, [authProvider, setState]);
    return state;
};
