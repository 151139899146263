import React from "react";
import {ArrayInput, InputProps} from "react-admin";
import {makeStyles} from "@mui/styles";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Iterator from "./iterator";
import {CustomAccordionSummary} from "../Accordion/CustomAccordionSummary";

const RelatedItemsInput: React.FC<InputProps> = props => {
    const classes = useStyles();
    const arrayInputClasses = useArrayInputStyles();

    return (
        <div className={classes.root}>
            <Accordion>
                <CustomAccordionSummary
                    label={props.label}
                    startIcon={<ShoppingBasketIcon fontSize="small" />}
                />
                <AccordionDetails>
                    <ArrayInput
                        classes={arrayInputClasses}
                        label={false}
                        source={props.source}
                    >
                        <Iterator source={props.source} />
                    </ArrayInput>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1),
        width: "100%",
    },
}));

const useArrayInputStyles = makeStyles(theme => ({
    root: {
        margin: 0,
    },
}));

export default RelatedItemsInput;
