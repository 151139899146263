import React, {useState} from "react";
import {useRecordContext} from "react-admin";
import {makeStyles, createStyles} from "@mui/styles";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import Card from "@mui/material/Card";
import {Order} from "../../../../graphql/graphql";
import {openInNewTab} from "../../../utils/open-in-new-tab";
import {getOrderShopifyUrl} from "../../../utils/get-order-shopify-url";
import {RelatedItemsArray} from "../../../components/RelatedItems/RelatedItemsArray";
import {KeyValueArray} from "../../../components/KeyValueField/KeyValueArray";
import {InlineCopyableField} from "../../../components/CopyableField";
import {getUniqueIds} from "../utils/get-unique-ids";
import {getLineItemsUniqueIds} from "../utils/get-line-items-unique-ids";
import {CertificatesModal} from "./CertificatesModal";
import {RenderAddress} from "./RenderAddress";
import {TrackingNumber} from "./TrackingNumber";
import {CsvButtons} from "./CsvButtons";
import {keyValueToObject} from "../../../utils/key-value-to-object";

interface Props {
    expanded: boolean;
    onChange: (expand: boolean) => void;
    proceed: () => void;
}

const showVariables = ["star_name"];

export const OrderField = ({expanded, onChange, proceed}: Props) => {
    const classes = useStyles();

    const order = useRecordContext<Order>();
    const [certificatesOpen, setCertificatesOpen] = useState(false);

    const shopifyOrderUrl = getOrderShopifyUrl(order);

    const {orderCertificates} = order;

    const certificates = orderCertificates.map(({certificate}) => certificate);

    const uniqueIds = getUniqueIds(certificates);

    const unresolvedItems = order.lineItems.filter(item => {
        const property = item.properties.find(property => property.name === "_uniqueLineId");
        return !property || uniqueIds.indexOf(property.value) === -1;
    });

    const lineItemUniqueIds = getLineItemsUniqueIds(order.lineItems);

    const refunds = order.refunds || [];

    return (
        <Accordion
            expanded={expanded || false}
            onChange={(event, expand) => onChange(expand)}
            className={classes.main}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <InlineCopyableField value={order.orderName} onClick={(e) => {
                    e.stopPropagation();
                    openInNewTab(shopifyOrderUrl);
                }}/>
                &nbsp;|&nbsp;
                <div onClick={(e) => {
                    e.stopPropagation();
                    setCertificatesOpen(true);
                }}>
                    {orderCertificates.length} certificate(s)
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                {order.note && <div className={classes.note}>Note: {order.note}</div>}

                <CertificatesModal
                    certificates={certificates}
                    open={certificatesOpen}
                    onClose={() => setCertificatesOpen(false)}
                />

                <div className={classes.content}>
                    <div className={classes.additionalItems}>
                        {certificates.map(certificate => {
                            const variables = certificate.variables.filter(variable => showVariables.indexOf(variable.key) > -1);
                            const parsedVariables = keyValueToObject(certificate.variables);
                            const skipStrikeThrough = typeof parsedVariables.customization_url !== "undefined";

                            return (
                                <Card
                                    key={certificate.id}
                                    className={classes.additionalItem}
                                >
                                    <div className={classes.certificateInfo}>
                                        {/*Certificate ID: <InlineCopyableField value={certificate.id.toString()} />*/}
                                        <KeyValueArray keyValues={variables}/>
                                    </div>
                                    <div className={classes.relatedItems}>
                                        <RelatedItemsArray
                                            relatedItems={certificate.relatedItems.map((item) => ({
                                                ...item,
                                                strikeThrough: !skipStrikeThrough && lineItemUniqueIds.indexOf(item.uniqueLineId) === -1,
                                            }))}
                                        />
                                    </div>
                                </Card>
                            );
                        })}
                    </div>

                    <div>
                        <RenderAddress order={order}/>
                    </div>

                    <div className={classes.trackingWrapper}>
                        <div className={classes.actions}>
                            <CsvButtons
                                barcodeCsvUrl={order.barcodeCsvUrl}
                                fedexCsvUrl={order.fedexCsvUrl}
                                variant="outlined"
                            />
                        </div>

                        <Card className={classes.tracking} variant="outlined">
                            <TrackingNumber expanded={expanded} proceed={proceed}/>
                        </Card>
                    </div>
                </div>

                {unresolvedItems.length > 0 && (
                    <div>
                        <div>Unresolved order items:</div>

                        <RelatedItemsArray
                            relatedItems={unresolvedItems.map(item => ({
                                handle: item.sku || "",
                                qty: item.quantity,
                                uniqueLineId: "",
                                variantId: item.variant_id || 0,
                            }))}
                        />

                        <hr/>

                        <div>Refunded order items:</div>

                        {refunds.map(({id, refund_line_items}) => (
                            <div key={id}>
                                <RelatedItemsArray
                                    relatedItems={refund_line_items.map(({line_item}) => ({
                                        handle: line_item.sku || "",
                                        qty: line_item.quantity,
                                        uniqueLineId: "",
                                        variantId: line_item.variant_id || 0,
                                    }))}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        main: {
            width: "100%",
        },
        details: {
            display: "flex",
            flexDirection: "column",
        },
        note: {
            color: theme.palette.error.main,
        },
        actions: {
            display: "flex",
            marginBottom: theme.spacing(1),
            justifyContent: "end",
        },
        action: {
            marginRight: theme.spacing(1),
        },
        content: {
            display: "flex",
            justifyContent: "space-between",
        },
        trackingWrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            marginLeft: theme.spacing(2),
        },
        tracking: {
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(1),
        },
        additionalItems: {
            flex: 1,
            maxWidth: 600,
            marginRight: theme.spacing(2),
        },
        additionalItem: {
            display: "flex",
            marginTop: theme.spacing(1),
            padding: theme.spacing(1),
            justifyContent: "space-between",
            background: "#f5f5f5",
        },
        certificateInfo: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
        relatedItems: {},
    }),
);
