import React from "react";
import {Edit, SimpleForm, TextInput} from "react-admin";
import {CodeJarField} from "../../components/CodeJarEditor/field";

export const FiltersEdit = (props: any) => {
    return (
        <Edit
            {...props}
            mutationMode="pessimistic"
        >
            <SimpleForm>
                <TextInput
                    disabled
                    label="ID"
                    source="id"
                />

                <TextInput source="filterName" />

                <span>Criteria:</span>
                <CodeJarField />

                <TextInput source="entityName" />
            </SimpleForm>
        </Edit>
    );
};
