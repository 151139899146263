import React from "react";
import {ArrayInput, FormDataConsumer, SimpleFormIterator, useRecordContext} from "react-admin";
import get from "lodash/get";
import {Order} from "../../../../graphql/graphql";
import {getOrderShopifyUrl} from "../../../utils/get-order-shopify-url";

interface Props {
    label?: string;
    record?: any;
    source: string;
}

export const OrdersField: React.FC<Props> = props => {
    const {source} = props;
    const record = useRecordContext(props);

    const orders = get(record, source);

    if (!orders?.length) {
        return <span>{"The certificate hasn't yet been included in any orders"}</span>;
    }

    // margin="dense"
    return (
        <ArrayInput
            label="Associated Shopify Orders"
            source={source}
        >
            <SimpleFormIterator
                TransitionProps={{
                    classNames: "", addEndListener: () => {
                    },
                }}
                disableReordering
                disableRemove
                disableAdd
            >
                <FormDataConsumer>
                    {formData => {
                        const order = formData.scopedFormData.order as Order;
                        const url = getOrderShopifyUrl(order);
                        return (
                            <div>
                                <div>Country: {order.shippingAddress?.country}</div>

                                <a
                                    target="_blank"
                                    href={url}
                                >
                                    {order.orderName}
                                </a>
                            </div>
                        );
                    }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    );
};
