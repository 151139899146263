import React from "react";
import {Datagrid, DateField, ExportButton, FilterButton, ListBase, Pagination, TextField, TextInput} from "react-admin";
import Card from "@mui/material/Card";
import Toolbar from "@mui/material/Toolbar";
import {CustomFilterForm} from "../../components/CustomFilterForm/CustomFilterForm";
import {InputFilterGroup} from "../../components/CustomFilterForm/InputFilterGroup";

const productFilters = [
    <TextInput source="title" />,
    <TextInput source="handle" />,
    <InputFilterGroup source="configuration">
        <TextInput
            source="configuration.key"
            label="Configuration Key"
        />
        <TextInput
            source="configuration.value"
            label="Configuration Value"
        />
    </InputFilterGroup>,
];

const ListToolbar = () => (
    <Toolbar>
        <CustomFilterForm filters={productFilters} />
        <Toolbar>
            <FilterButton filters={productFilters} />
            <ExportButton />
        </Toolbar>
    </Toolbar>
);

export const ProductsList = (props: any) => {
    return (
        <ListBase
            {...props}
            sort={{field: "id", order: "DESC"}}
        >
            <ListToolbar />

            <Card>
                <Datagrid rowClick="edit">
                    <TextField
                        source="id"
                        label="ID"
                    />
                    <TextField source="title" />
                    <TextField source="handle" />
                    <DateField
                        source="createdAt"
                        showTime={true}
                    />
                    <DateField
                        source="updatedAt"
                        showTime={true}
                    />
                </Datagrid>
                <Pagination />
            </Card>
        </ListBase>
    );
};
