import React from "react";
import {Edit, SimpleForm, TextInput} from "react-admin";
import {PluginStorageList} from "./plugin-storage/PluginStorageList";

export const PluginsEdit = (props: any) => {
    return (
        <Edit
            {...props}
            mutationMode="pessimistic"
        >
            <SimpleForm>
                <TextInput
                    disabled
                    label="ID"
                    source="id"
                />

                <TextInput
                    disabled
                    source="namespace"
                />

                <TextInput
                    disabled
                    source="enabled"
                />

                <PluginStorageList source="data" />
            </SimpleForm>
        </Edit>
    );
};
