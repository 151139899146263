import React from "react";
import {BooleanField, Datagrid, DateField, TextField, useListContext, BulkActionsToolbarClasses} from "react-admin";
import {OverlayLoader, OverlayScope} from "../../components/OverlayLoader";
import {ListBulkActions} from "./ListBulkActions";

export const OrdersGrid = (props: any) => {
    const {isFetching} = useListContext(props);

    return (
        <>
            {isFetching && <OverlayLoader scope={OverlayScope.Window} />}
            <Datagrid
                {...props}
                rowClick="edit"
                sx={{
                    [`& div.${BulkActionsToolbarClasses.toolbar}`]: {
                        position: "relative",
                        transform: "none",
                    },
                }}
                bulkActionButtons={<ListBulkActions />}
            >
                <TextField
                    source="id"
                    label="ID"
                />
                <TextField
                    source="shopifyOrderId"
                    label="Shopify Order ID"
                />
                <TextField source="orderName" />
                <TextField source="email" />
                <BooleanField source="fulfilled" />
                <TextField
                    source="shippingAddress.country_code"
                    label={"Country"}
                    sortable={false}
                />
                <DateField
                    source="createdAt"
                    showTime={true}
                />
                <DateField
                    source="updatedAt"
                    showTime={true}
                />
            </Datagrid>
        </>
    );
};
