import React, {useMemo, useState} from "react";
import {useGetList, useRedirect} from "react-admin";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import {pathsToTree} from "../../../../components/FileBrowser/pathsToTree";
import {FileListEntity} from "../../../../components/FileBrowser/types";
import {ChooseFileDialog} from "../../../../components/ChooseFileDialog";
import {useDeepResolvedTemplate} from "../../../../hooks/useDeepResolvedTemplate";
import {DebuggablePreview} from "../../../../components/TemplatePreview/DebuggablePreview";
import {getFileLanguage} from "../../../../utils/get-file-language";
import {useLocalStorage} from "../../../../hooks/useLocalStorage";
import {useFetch} from "../../../../hooks/useFetch";
import {Config} from "../../../../config";

interface Props {
    file: FileListEntity;
    templateCode: string;
}

export const EditorPreview: React.FC<Props> = (props) => {
    const {file, templateCode} = props;

    const {data, isLoading} = useGetList<FileListEntity>("files");
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentLocale, setCurrentLocale] = useState("en");
    const [currentFile, setCurrentFile] = useLocalStorage<FileListEntity | undefined>(`currentBaseFile_${file.id}`, undefined);
    const {variables, locale} = useDeepResolvedTemplate({file: currentFile});
    const fetch = useFetch();
    const redirect = useRedirect();

    const tree = useMemo(() => {
        return pathsToTree(data || []);
    }, [data]);

    const downloadPdf = async () => {
        setLoading(true);
        const request = await fetch(`${Config.apiBaseUrl}/render/pdf`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                template: templateCode,
                variables: variables,
                locale: locale[currentLocale],
            }),
        });
        const url = await request.text();
        redirect(url);
        setTimeout(() => setLoading(false), 1000);
    };

    if (isLoading) {
        return null;
    }

    return (
        <DebuggablePreview
            template={templateCode}
            variables={variables}
            locale={locale}
            onLocaleChange={locale => setCurrentLocale(locale)}
        >
            <div>
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => setOpen(true)}
                >
                    Choose base file
                </Button>

                <ChooseFileDialog
                    tree={tree}
                    open={open}
                    setOpen={setOpen}
                    currentFile={currentFile}
                    fileAvailable={file => getFileLanguage(file.name) === "json"}
                    onOpenFile={file => {
                        setOpen(false);
                        setCurrentFile(file);
                    }}
                />

                <div>
                    Base file: {currentFile?.path || "none"}{" "}
                </div>
            </div>

            <LoadingButton
                size="small"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                onClick={downloadPdf}
            >
                Download PDF
            </LoadingButton>
        </DebuggablePreview>
    );
};
