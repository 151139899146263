import React from "react";
import {List, NullableBooleanInput, ReferenceInput, SelectInput, TextInput} from "react-admin";
import {OrdersGrid} from "./OrdersGrid";

const orderFilters = [
    <TextInput source="shopifyOrderId" />,
    <TextInput source="orderName" alwaysOn />,
    <TextInput source="email" />,
    <NullableBooleanInput source="fulfilled" />,
    <ReferenceInput
        source="includeFilterId"
        reference="filters"
    >
        <SelectInput
            label="Include criteria"
            optionText="filterName"
        />
    </ReferenceInput>,
];

export const OrdersList = (props: any) => {
    return (
        <>
            <List
                {...props}
                sort={{field: "id", order: "DESC"}}
                perPage={25}
                filters={orderFilters}
            >
                <OrdersGrid />
            </List>
        </>
    );
};
