import {LineItemField} from "../../../../graphql/graphql";

export const getLineItemsUniqueIds = (lineItems: LineItemField[]) => {
    const uniqueIds: string[] = [];

    for (const lineItem of lineItems) {
        const property = lineItem.properties.find(({name}) => name === "_uniqueLineId");
        if (property && property.value) {
            uniqueIds.push(property.value);
        }
    }

    return uniqueIds;
};
