import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {makeStyles} from "@mui/styles";
import cx from "classnames";

export enum OverlayScope {
    Window,
    Local,
}

interface Props {
    scope?: OverlayScope;
}

export const OverlayLoader: React.FC<Props> = (props) => {
    const {scope} = props;
    const classes = useStyles();

    const className = scope === OverlayScope.Window ? classes.loaderWindow : classes.loaderLocal;
    const size = scope === OverlayScope.Window ? 50 : 30;
    const thickness = scope === OverlayScope.Window ? 7 : 6;

    return (
        <div className={classes.loadingWrapper}>
            <CircularProgress
                color="secondary"
                size={size}
                thickness={thickness}
                className={cx(classes.loader, className)}
            />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    loadingWrapper: {
        position: "absolute",
        zIndex: 100,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(180, 180, 180, 0.6)",
    },
    loader: {
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    loaderWindow: {
        top: "30vh",
    },
    loaderLocal: {
        top: "50%",
    },
}));
