import React from "react";
import {makeStyles} from "@mui/styles";
import {FileListEntity} from "../../../../components/FileBrowser/types";
import {FileCallback} from "../../../../components/FileBrowser/FileBrowser";
import {File} from "./File";

interface IProps {
    files: FileListEntity[];
    onClose: FileCallback;
    onOpen: FileCallback;
    activeFile: FileListEntity;
}

export const Toolbar = (props: IProps) => {
    const classes = useStyles();

    return (
        <div className={classes.main}>
            {props.files.map(file => (
                <File
                    key={file.id}
                    isActive={file === props.activeFile}
                    file={file}
                    onOpen={() => props.onOpen(file)}
                    onClose={() => props.onClose(file)}
                />
            ))}
        </div>
    );
};

const useStyles = makeStyles(() => ({
    main: {
        display: "flex",
        flexWrap: "wrap",
    },
}));
