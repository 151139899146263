import {useRecordContext} from "react-admin";
import React from "react";
import createStyles from "@mui/styles/createStyles";
import {makeStyles} from "@mui/styles";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {ThemeProps, withTheme} from "@rjsf/core";
import {Theme} from "@rjsf/material-ui/v5";
import {PluginActionField} from "../../../graphql/graphql";
import {OverlayLoader} from "../OverlayLoader";

interface IProps {
    action: PluginActionField;
    open: boolean;
    setOpen: (open: boolean) => void;
    onSubmit: (data: any) => void;
    isLoading: boolean;
}

const Form = withTheme(Theme as ThemeProps);

export const PluginModal = (props: IProps) => {
    const record = useRecordContext(props);
    const {action, open, setOpen, onSubmit} = props;
    const classes = useStyles();

    const handleDialogClose = () => setOpen(false);

    if (!record) {
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={() => handleDialogClose()}
        >
            <DialogContent className={classes.dialogContent}>
                {props.isLoading && <OverlayLoader />}

                <Form
                    schema={JSON.parse(action.inputSchema)}
                    onSubmit={data => {
                        onSubmit(data.formData);
                    }}
                >
                    <div className={classes.footer}>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            type="submit"
                        >
                            Submit
                        </Button>
                    </div>
                </Form>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        dialogContent: {
            minWidth: 450,
            [theme.breakpoints.down("xs")]: {
                minWidth: 300,
            },
        },
        footer: {
            marginTop: theme.spacing(3),
        },
    }),
);
