import {gql} from "@apollo/client";
import {
    CreateParams,
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateManyParams,
    UpdateParams,
} from "react-admin";
import {ResourceDataProvider} from "../../dataProvider/ResourceDataProvider";
import {Order, UpdateOrderInput} from "../../../graphql/graphql";
import {cleanKeyValue} from "../../utils/clean-key-value";

export class OrdersDataProvider extends ResourceDataProvider {
    public resource = "orders";

    // TODO: implement
    async create(params: CreateParams) {
        throw new Error("Not implemented");
        return {data: {id: 0}};
    }

    // TODO: implement
    async delete(params: DeleteParams) {
        throw new Error("Not implemented");
        return {data: {id: 0}};
    }

    // TODO: implement
    async deleteMany(params: DeleteManyParams) {
        throw new Error("Not implemented");
        return {};
    }

    async getList(params: GetListParams) {
        let filter = params.filter ?? {};

        const query = gql`
        query getQuery($filter: JSON!) {
            orders(
                page: ${params.pagination.page},
                perPage: ${params.pagination.perPage},
                sortField: "${params.sort.field}",
                sortOrder: "${params.sort.order}",
                filter: $filter,
            ) {
                nodes {
                    id
                    shopifyOrderId
                    orderName
                    email
                    fulfilled
                    financialStatus
                    createdAt
                    updatedAt
                    store {
                        shop
                    }
                    shippingAddress {
                        first_name
                        last_name
                        address1
                        address2
                        city
                        zip
                        country_code
                        country
                    }
                }
                length
            }
        }
        `;

        const result = await this.client.query({query, variables: {filter}});

        return {
            data: result.data.orders.nodes,
            total: result.data.orders.length,
        };
    }

    // TODO: implement
    async getMany(params: GetManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }

    // TODO: implement
    async getManyReference(params: GetManyReferenceParams) {
        throw new Error("Not implemented");
        return {data: [], total: 0};
    }

    async getOne(params: GetOneParams) {
        const query = gql`
        {
            order(id: ${params.id}) {
                id
                shopifyOrderId
                orderName
                email
                fulfilled
                financialStatus
                pluginActions {
                    name
                    endpoint
                    namespace
                    type
                    inputSchema
                    buttonStyle
                    noConfirmation
                }
                store {
                    shop
                }
                fulfillmentStatus
                shippingAddress {
                    country_code
                    zip
                    city
                    name
                    country
                    address1
                    address2
                }
                variables {
                    key
                    value
                }
                orderCertificates {
                    certificate {
                        id
                        hashId
                        locale
                        downloadUrl
                        regenerateUrl
                        variables {
                            key
                            value
                        }
                        privateVariables {
                            key
                            value
                        }
                    }
                }
                orderProcessing {
                    processing {
                        id
                        processingLogic {
                            name
                        }
                    }
                }
                createdAt
                updatedAt
            }
        }
        `;

        const result = await this.queryAllStores({query});

        return {
            data: result.data.order,
        };
    }

    // TODO: implement
    async update(params: UpdateParams) {
        const query = gql`
            mutation update($input: UpdateOrderInput!) {
                updateOrder(input: $input) {
                    id
                }
            }
        `;

        const data = params.data as Order;
        const input: UpdateOrderInput = {
            id: data.id,
            variables: cleanKeyValue(data.variables),
        };

        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.updateOrder,
        };
    }

    // TODO: implement
    async updateMany(params: UpdateManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }
}
