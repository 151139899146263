import React from "react";
import {EditButton} from "react-admin";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import StorageIcon from "@mui/icons-material/Storage";
import {PluginDataField} from "../../../../graphql/graphql";

interface IProps {
    data: PluginDataField;
    onEdit: (data: PluginDataField) => void;
    onData: (data: PluginDataField) => void;
    onDelete: (id: number, data: PluginDataField) => void;
}

export const PluginStorageField = ({data, onEdit, onData, onDelete}: IProps) => {
    const {id, key, isPublic} = data;
    return (
        <TableRow>
            <TableCell>{id}</TableCell>
            <TableCell>{key}</TableCell>
            <TableCell>{isPublic ? "Yes" : "No"}</TableCell>
            <TableCell>
                <EditButton onClick={() => onEdit(data)} />
                <Button
                    onClick={() => onData(data)}
                    startIcon={<StorageIcon />}
                >
                    Data
                </Button>
                <Button
                    onClick={() => onDelete(id, data)}
                    startIcon={<DeleteIcon />}
                >
                    Delete
                </Button>
            </TableCell>
        </TableRow>
    );
};
