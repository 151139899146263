import {DirectoryPathResult, FileListEntity, FSAttributes, FSNodeType, isDirectory, RecursivePathResult} from "./types";

export const pathsToTree = (files: FileListEntity[]) => {
    const result: RecursivePathResult[] = [
        {
            type: FSNodeType.Directory,
            name: "root",
            absolute: "/",
            attributes: {},
            children: [],
        },
    ];

    for (let file of files) {
        let current = result[0];
        const path = file.path;
        const parts = path.split("/").filter(part => !!part);

        const length = parts.length;

        for (let [index, part] of parts.entries()) {
            // File cannot have further children entities
            if (current.type === FSNodeType.File) {
                continue;
            }

            const next = current.children.find(({name}) => name === part);
            let type = index === length - 1 ? FSNodeType.File : FSNodeType.Directory;

            if (!next) {
                const trailingSlash = type === FSNodeType.Directory ? "/" : "";
                let absolute = current.absolute + part + trailingSlash;
                const attributes: FSAttributes = {};

                if (type === FSNodeType.File) {
                    attributes.id = file.id;
                    attributes.fileType = file.fileType;
                    attributes.link = file.link;
                }

                let obj = {
                    type,
                    attributes,
                    absolute,
                    name: part,
                } as RecursivePathResult;

                if (isDirectory(obj)) {
                    obj.children = [];
                }

                current.children.push(obj);
                current = obj;
            } else {
                current = next;
            }
        }
    }

    return result as [DirectoryPathResult];
};
