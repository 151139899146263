import React, {useState} from "react";
import {useCreate, useListContext} from "react-admin";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {ActionComponent, Action} from "./Action";
import {FileListEntity, RecursivePathResult} from "../types";
import TextField from "@mui/material/TextField";

interface Props {
    result: RecursivePathResult;
}

export const CreateFileAction: ActionComponent<Props> = React.forwardRef((props, ref) => {
    const {result, onCancel} = props;
    const [open, setOpen] = useState(false);
    const {refetch} = useListContext<FileListEntity>();

    const [fileName, setFileName] = useState("");

    const [createOne] = useCreate();

    return (
        <>
            <Action onClick={() => setOpen(true)}>
                <span>Create File</span>
            </Action>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle id="alert-dialog-title">{"Create new file"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Please enter the filename</DialogContentText>

                    <TextField
                        autoFocus
                        onChange={event => setFileName(event.target.value)}
                        value={fileName}
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpen(false);
                            onCancel?.();
                        }}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            await createOne(
                                "files",
                                {data: {path: result.absolute + fileName}},
                                {onSuccess: () => refetch()},
                            );
                            setOpen(false);
                            onCancel?.();
                        }}
                        color="primary"
                        autoFocus
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});
