import React from "react";
import {ArrayFieldTemplateProps} from "@rjsf/core";

export const DataFieldTemplate = (props: ArrayFieldTemplateProps) => {
    return (
        <div>
            {props.items.map((element: any, key: number) => {
                return <div key={key}>{element.children}</div>;
            })}
        </div>
    );
};
