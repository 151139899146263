import React from "react";
import {
    Admin as ReactAdmin,
    AuthProvider,
    Resource,
    DataProvider,
    TranslationMessages,
    CustomRoutes,
} from "react-admin";
import {QueryClient} from "react-query";
import polyglotI18nProvider from "ra-i18n-polyglot";
import {FilesComponent} from "./resources/files";
import {TemplatesList} from "./resources/templates/List";
import {TemplatesEdit} from "./resources/templates/Edit";
import {TemplatesCreate} from "./resources/templates/Create";
import {CertificatesList} from "./resources/certificates/List";
import {CertificatesEdit} from "./resources/certificates/Edit";
import {Layout} from "./layout/Layout";
import {BrowserRouter, Route} from "react-router-dom";
import {Dashboard} from "./layout/Dashboard";
import {NotFound} from "./layout/NotFound";
import {OrdersList} from "./resources/orders/List";
import {OrdersEdit} from "./resources/orders/Edit";
import {ProcessingLogicsList} from "./resources/processing-logics/List";
import englishMessages from "./locale/en";
import {ProcessingLogicsEdit} from "./resources/processing-logics/Edit";
import {ProcessingsList} from "./resources/processings/List";
import {ProcessingShow} from "./resources/processings/Show";
import {ProcessingLogicsShow} from "./resources/processing-logics/Show";
import {ProcessingLogicsCreate} from "./resources/processing-logics/Create";
import {ProductsList} from "./resources/products/List";
import {ProductsEdit} from "./resources/products/Edit";
import {PluginsEdit} from "./resources/plugins/Edit";
import {PluginsList} from "./resources/plugins/List";
import {StoreConfiguration} from "./resources/stores/Configuration";
import {FiltersList} from "./resources/filters/List";
import {FiltersEdit} from "./resources/filters/Edit";
import {FiltersCreate} from "./resources/filters/Create";
import {CertificatesCreate} from "./resources/certificates/Create";
import {Welcome} from "./layout/Welcome";

interface Props {
    dataProvider: DataProvider;
    authProvider?: AuthProvider;
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const Admin: React.FC<Props> = props => {
    const {dataProvider, authProvider} = props;

    const messages: Record<string, TranslationMessages> = {
        en: englishMessages,
    };

    const i18nProvider = polyglotI18nProvider(locale => messages[locale], "en", {
        allowMissing: true,
    });

    return (
        <BrowserRouter>
            <ReactAdmin
                i18nProvider={i18nProvider}
                dataProvider={dataProvider}
                authProvider={authProvider}
                layout={Layout}
                dashboard={Dashboard}
                catchAll={NotFound}
                disableTelemetry
                queryClient={queryClient}
            >
                <Resource
                    name="files"
                    list={FilesComponent}
                />
                <Resource
                    name="templates"
                    list={TemplatesList}
                    edit={TemplatesEdit}
                    create={TemplatesCreate}
                />
                <Resource
                    name="certificates"
                    list={CertificatesList}
                    edit={CertificatesEdit}
                    create={CertificatesCreate}
                />
                <Resource
                    name="orders"
                    list={OrdersList}
                    edit={OrdersEdit}
                />
                <Resource
                    name="products"
                    list={ProductsList}
                    edit={ProductsEdit}
                />
                <Resource
                    name="processingLogics"
                    list={ProcessingLogicsList}
                    create={ProcessingLogicsCreate}
                    edit={ProcessingLogicsEdit}
                    show={ProcessingLogicsShow}
                />
                <Resource
                    name="processings"
                    list={ProcessingsList}
                    show={ProcessingShow}
                />
                <Resource
                    name="plugins"
                    list={PluginsList}
                    edit={PluginsEdit}
                />
                <Resource
                    name="filters"
                    list={FiltersList}
                    create={FiltersCreate}
                    edit={FiltersEdit}
                />
                <CustomRoutes>
                    <Route
                        path="/configuration"
                        element={<StoreConfiguration/>}
                    />
                    <Route
                        path="/shopify/callback"
                        element={<Welcome/>}
                    />
                </CustomRoutes>
                <Resource name="stores"/>
                <Resource name="notifications"/>
                <Resource name="tags"/>
            </ReactAdmin>
        </BrowserRouter>
    );
};

export default Admin;
