import {gql} from "@apollo/client";
import {
    CreateParams,
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateManyParams,
    UpdateParams,
} from "react-admin";
import {ResourceDataProvider} from "../../dataProvider/ResourceDataProvider";
import {Certificate, CreateCertificateInput, EntityTag, UpdateCertificateInput} from "../../../graphql/graphql";
import {cleanKeyValue} from "../../utils/clean-key-value";

export class CertificatesDataProvider extends ResourceDataProvider {
    public resource = "certificates";

    async create(params: CreateParams) {
        const query = gql`
            mutation CreateCertificate($input: CreateCertificateInput!) {
                createCertificate(input: $input) {
                    id
                }
            }
        `;

        const data = params.data as Certificate;
        const input: CreateCertificateInput = {
            locale: data.locale,
            templateId: data.template.id,
            variables: cleanKeyValue(data.variables),
            privateVariables: cleanKeyValue(data.privateVariables),
            productVariantId: data.productVariantId,
            relatedItems: data.relatedItems?.map(relatedItem => ({
                handle: relatedItem.handle,
                variantId: relatedItem.variantId,
                qty: relatedItem.qty,
                uniqueLineId: relatedItem.uniqueLineId,
            })) || [],
        };

        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.createCertificate,
        };
    }

    // TODO: implement
    async delete(params: DeleteParams) {
        throw new Error("Not implemented");
        return {data: {id: 0}};
    }

    // TODO: implement
    async deleteMany(params: DeleteManyParams) {
        throw new Error("Not implemented");
        return {};
    }

    async getList(params: GetListParams) {
        let filter = params.filter ?? {};

        const query = gql`
            query getQuery($filter: JSON!) {
                certificates(
                    page: ${params.pagination.page},
                    perPage: ${params.pagination.perPage},
                    sortField: "${params.sort.field}",
                    sortOrder: "${params.sort.order}",
                    filter: $filter,
                ) {
                    nodes {
                        id
                        hashId
                        locale
                        template {
                            id
                            name
                            thumbnail {
                                link
                            }
                        }
                        variables {
                            key
                            value
                        }
                        createdAt
                        updatedAt
                        entityTags {
                            tag {
                                tagName
                            }
                        }
                    }
                    length
                }
            }
        `;

        const result = await this.client.query({query, variables: {filter}});

        return {
            data: result.data.certificates.nodes,
            total: result.data.certificates.length,
        };
    }

    // TODO: implement
    async getMany(params: GetManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }

    // TODO: implement
    async getManyReference(params: GetManyReferenceParams) {
        throw new Error("Not implemented");
        return {data: [], total: 0};
    }

    async getOne(params: GetOneParams) {
        const query = gql`
            query ($id: Int!) {
                certificate(id: $id) {
                    id
                    hashId
                    locale
                    downloadUrl
                    regenerateUrl
                    baseUrl
                    productVariantId
                    productVariant {
                        product {
                            handle
                        }
                    }
                    orderCertificates {
                        order {
                            id
                            orderName
                            shopifyOrderId
                            shippingAddress {
                                country
                            }
                            store {
                                id
                                shop
                            }
                        }
                    }
                    template {
                        id
                        name
                        file {
                            id
                            path
                        }
                    }
                    variables {
                        key
                        value
                    }
                    privateVariables {
                        key
                        value
                    }
                    relatedItems {
                        handle
                        variantId
                        qty
                        uniqueLineId
                    }
                    createdAt
                    updatedAt
                    pluginActions {
                        name
                        endpoint
                        namespace
                        type
                        inputSchema
                        buttonStyle
                        noConfirmation
                    }
                    entityTags {
                        tagId
                    }
                }
            }
        `;

        const id = parseInt(params.id as string);
        const result = await this.queryAllStores({query, variables: {id}});

        const data = result.data.certificate;
        const entityTags: EntityTag[] = data.entityTags;
        const tags: number[] = entityTags.map(templateTag => {
            return templateTag.tagId;
        });
        data.tags = tags;

        return {
            data,
        };
    }

    async update(params: UpdateParams) {
        const query = gql`
            mutation update($input: UpdateCertificateInput!) {
                updateCertificate(input: $input) {
                    id
                }
            }
        `;

        const data = params.data as Certificate;
        const input: UpdateCertificateInput = {
            id: data.id,
            locale: data.locale,
            templateId: data.template.id,
            productVariantId: data.productVariantId,
            variables: cleanKeyValue(data.variables),
            privateVariables: cleanKeyValue(data.privateVariables),
            tags: (data as any).tags,
            relatedItems: data.relatedItems.map(relatedItem => ({
                handle: relatedItem.handle,
                variantId: relatedItem.variantId,
                qty: relatedItem.qty,
                uniqueLineId: relatedItem.uniqueLineId,
            })),
        };

        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.updateCertificate,
        };
    }

    // TODO: implement
    async updateMany(params: UpdateManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }
}
