import {gql} from "@apollo/client";
import {
    CreateParams,
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateManyParams,
    UpdateParams,
} from "react-admin";
import {ResourceDataProvider} from "../../dataProvider/ResourceDataProvider";
import {CreateProcessingLogicInput, ProcessingLogic, UpdateProcessingLogicInput} from "../../../graphql/graphql";

export class ProcessingLogicsDataProvider extends ResourceDataProvider {
    public resource = "processingLogics";

    async create(params: CreateParams) {
        const query = gql`
            mutation create($input: CreateProcessingLogicInput!) {
                createProcessingLogic(input: $input) {
                    id
                }
            }
        `;

        const input: CreateProcessingLogicInput = {
            name: params.data.name,
            type: params.data.type,
            exclusive: params.data.exclusive,
            function: params.data.function,
            includeFilterId: params.data.includeFilter?.id || null,
            excludeFilterId: params.data.excludeFilter?.id || null,
        };

        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.createProcessingLogic,
        };
    }

    // TODO: implement
    async delete(params: DeleteParams) {
        throw new Error("Not implemented");
        return {data: {id: 0}};
    }

    // TODO: implement
    async deleteMany(params: DeleteManyParams) {
        throw new Error("Not implemented");
        return {};
    }

    async getList(params: GetListParams) {
        const filter = params.filter ?? {};

        const query = gql`
            query getQuery($filter: JSON!) {
                processingLogics(
                    page: ${params.pagination.page},
                    perPage: ${params.pagination.perPage},
                    sortField: "${params.sort.field}",
                    sortOrder: "${params.sort.order}",
                    filter: $filter,
                ) {
                    nodes {
                        id
                        name
                        type
                        exclusive
                        createdAt
                        updatedAt
                    }
                    length
                }
            }
        `;

        const result = await this.client.query({query, variables: {filter}});

        return {
            data: result.data.processingLogics.nodes,
            total: result.data.processingLogics.length,
        };
    }

    // TODO: implement
    async getMany(params: GetManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }

    // TODO: implement
    async getManyReference(params: GetManyReferenceParams) {
        throw new Error("Not implemented");
        return {data: [], total: 0};
    }

    async getOne(params: GetOneParams) {
        const query = gql`
        {
            processingLogic(id: ${params.id}) {
                id
                name
                includeFilter {
                    id
                    filterName
                    criteria
                }
                excludeFilter {
                    id
                    filterName
                    criteria
                }
                type
                function
                pluginFunctions {
                    slug
                    description
                }
                exclusive
                createdAt
                updatedAt
            }
        }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.processingLogic,
        };
    }

    async update(params: UpdateParams) {
        const query = gql`
            mutation update($input: UpdateProcessingLogicInput!) {
                updateProcessingLogic(input: $input) {
                    id
                }
            }
        `;

        const data = params.data as ProcessingLogic;
        const input: UpdateProcessingLogicInput = {
            id: data.id,
            name: data.name,
            type: data.type,
            exclusive: data.exclusive,
            function: data.function,
            includeFilterId: data.includeFilter?.id || null,
            excludeFilterId: data.excludeFilter?.id || null,
        };

        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.updateProcessingLogic,
        };
    }

    // TODO: implement
    async updateMany(params: UpdateManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }
}
