import React, {useState} from "react";
import {TextField, TextFieldProps, useNotify, useRecordContext} from "react-admin";
import {makeStyles} from "@mui/styles";
import get from "lodash/get";
import cx from "classnames";
import {CopyAdornment} from "./CopyAdornment";

export interface Props extends TextFieldProps {
    value?: string;
    display?: "inline" | "block";
    useNotify?: boolean;
}

const CopyableField: React.FC<Props> = props => {
    const {value: propsValue, source, display, useNotify: propsUseNotify, label, children, onClick, ...rest} = props;

    const classes = useStyles();
    const record = useRecordContext(props);
    const notify = useNotify();
    let value = "";

    if (!propsValue) {
        value = get(record, source || "");
    } else {
        value = propsValue;
    }

    const [recentlyCopied, setRecentlyCopied] = useState<boolean>(false);

    const copiedLabel = "Copied to clipboard";

    const endAdornment = (
        <CopyAdornment
            value={value}
            onCopy={() => {
                if (propsUseNotify) {
                    notify(copiedLabel);
                } else {
                    setRecentlyCopied(true);
                    setTimeout(() => setRecentlyCopied(false), 2000);
                }
            }}
        />
    );


    return (
        <div className={cx(classes.wrapper, {[classes.inline]: display === "inline"})} onClick={onClick}>
            {recentlyCopied && !propsUseNotify && <span className={classes.copiedLabel}>{copiedLabel}</span>}
            {source && <TextField {...rest} source={source} label={recentlyCopied ? copiedLabel : label}/>}
            {!source && <>{children}</>}
            {endAdornment}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        position: "relative",
    },
    inline: {
        display: "inline-flex",
    },
    copiedLabel: {
        position: "absolute",
        bottom: "-1.1em",
        fontSize: 12,
        color: theme.palette.success.dark,
    },
}));

export default CopyableField;
