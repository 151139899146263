import React from "react";
import createStyles from "@mui/styles/createStyles";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import {ThemeProps, withTheme} from "@rjsf/core";
import {Theme} from "@rjsf/material-ui/v5";
import {DataFieldTemplate} from "./DataFieldTemplate";
import {PluginDataField} from "../../../../../graphql/graphql";
import {JSONSchema7} from "json-schema";

interface IProps {
    onSubmit: (d: PluginDataField) => void;
    onCancel: () => void;
    schema: JSONSchema7;
    data: any;
}

const Form = withTheme(Theme as ThemeProps);

export const DataFieldEdit = ({onSubmit, onCancel, data, schema}: IProps) => {
    const classes = useStyles();
    return (
        <Form
            formData={data}
            ArrayFieldTemplate={DataFieldTemplate}
            onSubmit={d => onSubmit(d.formData[0])}
            schema={schema}
        >
            <div className={classes.formFooter}>
                <Button
                    className={classes.cancelButton}
                    onClick={onCancel}
                    color="secondary"
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                >
                    Save
                </Button>
            </div>
        </Form>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        formFooter: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        cancelButton: {
            marginRight: theme.spacing(1),
        },
    }),
);
