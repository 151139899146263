import {useDataProvider} from "react-admin";
import {useQuery} from "react-query";
import {ApolloQueryResult, gql} from "@apollo/client";
import {Product} from "../../../../graphql/graphql";

export const useListProductVariants = () => {
    const dataProvider = useDataProvider();
    return useQuery<{data: Product[]}>(["products", "listProductVariants"], () =>
        dataProvider.customFetch("products", {
            query: gql`
                {
                    products {
                        nodes {
                            id
                            title
                            handle
                            variants {
                                id
                                variantId
                                title
                            }
                        }
                    }
                }
            `,
            resolver: (result: ApolloQueryResult<any>) => ({data: result.data.products.nodes}),
        }),
    );
};
