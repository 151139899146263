import React from "react";
import {Datagrid, List, TextField} from "react-admin";

export const PluginsList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField
                    source="id"
                    label="ID"
                />
                <TextField source="namespace" />
                <TextField source="enabled" />
            </Datagrid>
        </List>
    );
};
