import React from "react";
import {Datagrid, DateField, List, TextField, useRecordContext, useRefresh, useUpdate} from "react-admin";
import {useNavigate, Link} from "react-router-dom";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import LaunchIcon from "@mui/icons-material/Launch";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";
import {Notification} from "../../../graphql/graphql";

const Actions = () => {
    const navigate = useNavigate();
    const record = useRecordContext<Notification>();
    const refresh = useRefresh();
    const [update] = useUpdate(
        "notifications",
        {
            id: record.id,
            data: {...record, dismissed: true},
            previousData: record,
        },
        {
            onSuccess: () => {
                refresh();
            },
        },
    );

    return (
        <>
            <Button
                onClick={() => update()}
                startIcon={<ClearIcon />}
            >
                Dismiss
            </Button>

            <Link to={`${record.entityName}/${record.entityId}`}>
                <Button startIcon={<LaunchIcon />}>
                    Open
                </Button>
            </Link>
        </>
    );
};

export const NotificationsList = () => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h3" className={classes.title}>Notifications</Typography>

            <List
                resource="notifications"
                exporter={false}
                actions={false}
                sort={{field: "id", order: "DESC"}}
            >
                <Datagrid
                    expand={(
                        <div>
                            <TextField source="data" />
                        </div>
                    )}
                >
                    <TextField source="id" />
                    <TextField source="message" />
                    <TextField source="level" />
                    <TextField
                        source="entityId"
                        label="Entity ID"
                    />
                    <TextField source="entityName" />
                    <TextField source="count" />
                    <DateField
                        source="createdAt"
                        showTime={true}
                    />
                    <DateField
                        source="updatedAt"
                        showTime={true}
                    />
                    <Actions />
                </Datagrid>
            </List>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: theme.spacing(0.5),
    },
}));
