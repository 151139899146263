import React from "react";
import TreeItem, {TreeItemProps} from "@mui/lab/TreeItem";
import {RecursivePathResult} from "./types";
import {ItemLabel} from "./ItemLabel";
import {makeStyles} from "@mui/styles";
import cx from "classnames";

type Props = TreeItemProps & {
    text: string;
    showActions: boolean;
    result: RecursivePathResult;
    onClick: () => void;
    active?: boolean;
    available: boolean;
};

export const BrowserItem = (props: Props) => {
    const {result, showActions, active, available} = props;

    const classes = useStyles();

    const label = (
        <ItemLabel
            showActions={showActions}
            result={result}
            onClick={() => {
                props.onClick();
            }}
        />
    );

    return (
        <TreeItem
            className={cx({
                [classes.active]: active,
                [classes.unavailable]: !available,
            })}
            key={result.absolute}
            nodeId={result.absolute}
            label={label}
        >
            {props.children}
        </TreeItem>
    );
};

const useStyles = makeStyles(() => ({
    active: {
        background: "#ddd",
    },
    unavailable: {
        pointerEvents: "none",
        color: "#aaa",
    },
}));
