import React, {useState} from "react";
import {useDelete, useListContext} from "react-admin";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {ActionComponent, Action} from "./Action";
import {FileListEntity, RecursivePathResult} from "../types";

interface Props {
    result: RecursivePathResult;
}

export const DeleteAction: ActionComponent<Props> = React.forwardRef((props, ref) => {
    const {result, onCancel} = props;
    const [open, setOpen] = useState(false);
    const {refetch} = useListContext<FileListEntity>();

    const [deleteOne] = useDelete();

    return (
        <>
            <Action onClick={() => setOpen(true)}>
                <span>Delete</span>
            </Action>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        By proceeding, the {result.type} {result.absolute} will be deleted
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpen(false);
                            onCancel?.();
                        }}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            await deleteOne("files", {id: result.attributes.id}, {onSuccess: () => refetch()});
                            setOpen(false);
                        }}
                        color="primary"
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});
