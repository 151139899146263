import * as React from "react";

interface InputFilterGroupProps {
    children: React.ReactNode;
    source: string;
    alwaysOn?: boolean;
}

export const InputFilterGroup = ({children}: InputFilterGroupProps) => {
    return <>{children}</>;
};
