import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    RadioButtonGroupInput,
    FormDataConsumer,
    BooleanInput,
} from "react-admin";
import {Type} from "../../../graphql/graphql";

export const ProcessingLogicsCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput
                    label="Name"
                    source="name"
                />
                <ReferenceInput
                    source="includeFilter.id"
                    reference="filters"
                >
                    <SelectInput
                        label="Include criteria"
                        optionText="filterName"
                    />
                </ReferenceInput>

                <ReferenceInput
                    source="excludeFilter.id"
                    reference="filters"
                >
                    <SelectInput
                        label="Exclude criteria"
                        optionText="filterName"
                    />
                </ReferenceInput>

                <RadioButtonGroupInput source="type" choices={[
                    {id: Type.Processing, name: "Processing"},
                    {id: Type.Automatic, name: "Automatic"},
                    {id: Type.Manual, name: "Manual"},
                ]}/>

                <FormDataConsumer>
                    {({formData, ...rest}) => formData.automatic && (
                        <SelectInput
                            source="function"
                            optionText="slug"
                            optionValue="slug"
                            format={value => value === null ? "" : value}
                            choices={
                                formData.pluginFunctions ?? []
                            }
                            {...rest}
                        />
                    )}
                </FormDataConsumer>

                <BooleanInput source="exclusive"/>
            </SimpleForm>
        </Create>
    );
};
