import React, {useState} from "react";
import {TextInput, TextInputProps, useInput} from "react-admin";
import {makeStyles} from "@mui/styles";
import {CopyAdornment} from "./CopyAdornment";

const CopyableInput: React.FC<TextInputProps> = props => {
    const classes = useStyles();
    const {
        field: {value},
    } = useInput(props);
    const [recentlyCopied, setRecentlyCopied] = useState<boolean>(false);

    const endAdornment = (
        <CopyAdornment
            value={value}
            onCopy={() => {
                setRecentlyCopied(true);
                setTimeout(() => setRecentlyCopied(false), 2000);
            }}
        />
    );

    const copiedLabel = "Copied to clipboard";

    return (
        <TextInput
            {...props}
            label={recentlyCopied ? copiedLabel : props.label}
            InputProps={{endAdornment}}
        />
    );
};

const useStyles = makeStyles(theme => ({
    copiedLabel: {
        color: theme.palette.success.dark,
    },
}));

export default CopyableInput;
