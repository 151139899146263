import React from "react";
import {ReactCodeJar} from "react-codejar";
import cx from "classnames";
import {makeStyles} from "@mui/styles";
import {getFileLanguage} from "../../utils/get-file-language";

const highlight = (editor: HTMLElement, language: string) => {
    let code = editor.textContent;
    code = Prism.highlight(code, Prism.languages[language], language);
    editor.innerHTML = `<code class="language-${language}">${code}</code>`;
};

interface Props {
    value: string;
    setValue: (value: string) => void;
    filepath: string;
    style?: React.CSSProperties;
}

export const CodeJarEditor = (props: Props) => {
    const classes = useStyles();

    const {value, setValue, filepath, style = {}} = props;

    const language = getFileLanguage(filepath);

    // In the `pre` element if `key` is not present, React isn't re-creating the element, thus CodeJar doesn't get
    // updated and has the wrong highlight function.
    return (
        <pre
            key={language}
            className={cx(classes.pre, `language-${language}`)}
        >
            <ReactCodeJar
                code={value}
                onUpdate={setValue}
                highlight={editor => highlight(editor, language)}
                lineNumbers={true}
                style={style}
            />
        </pre>
    );
};

const useStyles = makeStyles(() => ({
    pre: {
        fontSize: 14,
    },
}));
