import * as React from "react";
import {useEffect, useCallback} from "react";
import {
    FilterFormBaseProps,
    FilterFormClasses,
    FilterFormInput,
    LabelPrefixContextProvider,
    useListContext,
    useResourceContext,
} from "react-admin";
import {useFormContext} from "react-hook-form";
import lodashGet from "lodash/get";
import {createStyles, makeStyles} from "@mui/styles";

export const CustomFilterFormBase = (props: FilterFormBaseProps) => {
    const {className, filters, ...rest} = props;
    const resource = useResourceContext(props);
    const form = useFormContext();
    const {displayedFilters = {}, hideFilter} = useListContext(props);

    useEffect(() => {
        if (!filters) {
            return;
        }
        filters.forEach(_filter => {
            const filter = _filter as React.ReactElement;
            if (filter.props.alwaysOn && filter.props.defaultValue) {
                throw new Error(
                    "Cannot use alwaysOn and defaultValue on a filter input. Please set the filterDefaultValues props on the <List> element instead.",
                );
            }
        });
    }, [filters]);

    const getShownFilters = () => {
        if (!filters) {
            return [];
        }
        const values = form.getValues();
        return filters.filter(_filterElement => {
            const filterElement = _filterElement as React.ReactElement;
            const filterValue = lodashGet(values, filterElement.props.source);
            return (
                filterElement.props.alwaysOn ||
                displayedFilters[filterElement.props.source] ||
                (filterValue !== "" && typeof filterValue !== "undefined" && checkNestedObject(filterValue))
            );
        });
    };

    const checkNestedObject = (filterValue: any) => {
        if (typeof filterValue !== "object") {
            return true;
        }
        const notEmpty = Object.values(filterValue).filter((data: any) => data !== "" && typeof data !== "undefined");
        return notEmpty.length > 0;
    };

    const handleHide = useCallback(event => hideFilter(event.currentTarget.dataset.key), [hideFilter]);

    const classes = useFormStyles();

    return (
        <LabelPrefixContextProvider prefix={`resources.${resource}.fields`}>
            <form
                className={classes.root}
                {...sanitizeRestProps(rest)}
                onSubmit={e => {
                    e.preventDefault();
                    return false;
                }}
            >
                {getShownFilters().map(_filterElement => {
                    const filterElement = _filterElement as React.ReactElement;
                    return (
                        <FilterFormInput
                            key={filterElement.props.source}
                            filterElement={filterElement}
                            handleHide={handleHide}
                            resource={resource}
                        />
                    );
                })}
                <div className={FilterFormClasses.clearFix} />
            </form>
        </LabelPrefixContextProvider>
    );
};

const sanitizeRestProps = ({
    displayedFilters,
    filterValues,
    hasCreate,
    hideFilter,
    setFilters,
    resource,
    ...props
}: Partial<FilterFormBaseProps> & {hasCreate?: boolean}) => props;

const useFormStyles = makeStyles(theme =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: theme.spacing(-0.5),
            marginBottom: theme.spacing(0.5),
            minHeight: theme.spacing(8),
            display: "flex",
            alignItems: "flex-end",
            flexWrap: "wrap",
            pointerEvents: "none",

            [`& .${FilterFormClasses.clearFix}`]: {clear: "right"},
            "& .MuiFormHelperText-root": {display: "none"},
        },
    }),
);
