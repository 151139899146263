import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import {openInNewTab} from "../../../utils/open-in-new-tab";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import QrCodeIcon from "@mui/icons-material/QrCode";

interface Props {
    fedexCsvUrl: string;
    barcodeCsvUrl: string;
    variant?: "text" | "outlined" | "contained";
}

export const CsvButtons = (props: Props) => {
    const variant = props.variant || "contained";
    return (
        <ButtonGroup variant={variant}>
            <Button
                variant={variant}
                color="primary"
                onClick={() => openInNewTab(props.fedexCsvUrl)}
                startIcon={<RocketLaunchIcon />}
            >
                Fedex CSV
            </Button>

            <Button
                variant={variant}
                color="primary"
                onClick={() => openInNewTab(props.barcodeCsvUrl)}
                startIcon={<QrCodeIcon />}
            >
                Barcode CSV
            </Button>
        </ButtonGroup>
    );
};
