import React from "react";

interface Props {
    condition: boolean;
    wrapper: (children: JSX.Element) => JSX.Element;
    children: JSX.Element;
}

export const ConditionalWrapper = (props: Props) => {
    const {condition, wrapper, children} = props;
    return condition ? wrapper(children) : children;
};
