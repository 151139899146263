import React from "react";
import {ArrayInput, InputProps, useNotify, useRecordContext} from "react-admin";
import {makeStyles} from "@mui/styles";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import SettingsIcon from "@mui/icons-material/Settings";
import Button from "@mui/material/Button";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import copy from "copy-to-clipboard";
import Iterator from "./iterator";
import {CustomAccordionSummary} from "../Accordion/CustomAccordionSummary";
import {Certificate} from "../../../graphql/graphql";
import {keyValueToObject} from "../../utils/key-value-to-object";

const KeyValueInput: React.FC<InputProps> = props => {
    const record = useRecordContext<Certificate>(props);
    const classes = useStyles();
    const arrayInputClasses = useArrayInputStyles();
    const notify = useNotify();

    return (
        <div className={classes.root}>
            <Accordion>
                <CustomAccordionSummary
                    label={props.label}
                    startIcon={<SettingsIcon fontSize="small" />}
                />
                <AccordionDetails>
                    <ArrayInput
                        classes={arrayInputClasses}
                        label={false}
                        source={props.source}
                    >
                        <Iterator source={props.source} />
                    </ArrayInput>

                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{marginRight: "0.5em"}}
                        onClick={() => {
                            const variables = keyValueToObject(record[props.source as keyof Certificate]);
                            copy(JSON.stringify(variables, null, 4));
                            notify("Copied variables as JSON object to the clipboard");
                        }}
                    >
                        <FileCopyIcon />
                        Copy to JSON
                    </Button>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1),
        width: "100%",
    },
}));

const useArrayInputStyles = makeStyles(theme => ({
    root: {
        margin: 0,
    },
}));

export default KeyValueInput;
