import {useLocation} from "react-router";
import {useResourceDefinitions, useGetResourceLabel, useTranslate} from "react-admin";

interface BreadcrumbPart {
    path: string;
    name: string;
    clickable: boolean;
}

const upperEachWord = (text: string) => {
    return text.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
};

export const useBreadcrumbs = () => {
    const translate = useTranslate();
    const location = useLocation();
    const getResourceLabel = useGetResourceLabel();
    const resourcesDefinitions = useResourceDefinitions();
    const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);

    const parts = location.pathname.split("/").filter(part => !!part);
    const finalParts: BreadcrumbPart[] = [
        {
            path: "/",
            name: translate("ra.page.dashboard"),
            clickable: true,
        },
    ];

    let lastPart = "/";

    for (const [index, part] of parts.entries()) {
        const isLast = index === parts.length - 1;
        const resourceDefinition = resources.find(r => r?.name === part);
        const name = resourceDefinition ? getResourceLabel(part) : upperEachWord(part);
        lastPart += part;
        finalParts.push({
            path: lastPart,
            name,
            clickable: !isLast,
        });
        lastPart += "/";
    }

    return finalParts;
};
