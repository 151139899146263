import React from "react";
import {DashboardComponent} from "react-admin";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import {makeStyles} from "@mui/styles";
import {Navigation} from "./Navigation";
import {ProfileName} from "./menu/ProfileName";
import {AccountSwitcher} from "./menu/AccountSwitcher";

interface Props {
    dashboard?: DashboardComponent;
}

const ResponsiveAppBar: React.FC<Props> = (props) => {
    const classes = useStyles();

    return (
        <AppBar
            position="fixed"
            color="secondary"
        >
            <Container maxWidth="xl" className={classes.desktopContainer}>
                <Toolbar
                    disableGutters
                    variant="dense"
                >
                    <Navigation />

                    <ProfileName />

                    <AccountSwitcher/>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

const useStyles = makeStyles(theme => ({
    desktopContainer: {
        [theme.breakpoints.down("md")]: {
            padding: 0,
        },
    },
}));

export default ResponsiveAppBar;
