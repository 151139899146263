import {useMemo} from "react";
import {useDataProvider} from "react-admin";
import {useQuery} from "react-query";
import {gql} from "@apollo/client";
import {ApolloQueryResult} from "@apollo/client/core/types";
import {JsonObject} from "@clauma-marketing/srf-interpreter";
import {FileListEntity} from "../components/FileBrowser/types";

interface Props {
    file?: FileListEntity;
}

export interface ParsedData {
    template: string;
    variables: JsonObject;
    locale: Record<string, JsonObject>;
}

export const useDeepResolvedTemplate = (props: Props) => {
    const dataProvider = useDataProvider();
    const {data: result, isLoading} = useQuery<any>(
        ["templates", "deepResolveTemplate", {id: props.file?.id}],
        () =>
        dataProvider.customFetch("templates", {
            query: gql`{deepResolveTemplate(path: "${props.file?.path}")}`,
            resolver: (result: ApolloQueryResult<any>) => ({data: result.data.deepResolveTemplate}),
        }),
        {enabled: !!props.file?.path},
    );

    return useMemo<ParsedData>(() => {
        let template = "";
        let variables = {};
        let locale = {};
        if (!isLoading && result) {
            const parsed = JSON.parse(result.data);
            template = parsed?.template?.str;
            variables = parsed?.variables;
            locale = parsed?.locale;
        }
        return {
            template,
            locale,
            variables,
        };
    }, [result, isLoading]);
};
