import React from "react";
import {Edit, ImageField, ImageInput, required, SimpleForm, TextInput} from "react-admin";
import {TemplateSelector} from "./templateSelector";
import {TemplatePreviewField} from "../../components/TemplatePreview/TemplatePreviewField";
import {CreateTemplateData} from "./types";
import {useFetch} from "../../hooks/useFetch";
import {uploadThumbnail} from "./api/uploadThumbnail";
import {TagSelector} from "../tags/tagSelector";
import {KeyValueInput} from "../../components/KeyValueField";

export const TemplatesEdit = (props: any) => {
    const fetch = useFetch();
    const transform = async (data: CreateTemplateData) => {
        return await uploadThumbnail(fetch, data);
    };

    return (
        <Edit
            {...props}
            transform={transform}
            mutationMode="pessimistic"
        >
            <SimpleForm>
                <TextInput
                    disabled
                    label="Id"
                    source="id"
                />

                <TextInput
                    source="name"
                    validate={required()}
                />

                <TagSelector
                    source="tags"
                    entityName="template"
                />

                <TemplateSelector source="file" />

                <KeyValueInput
                    label="Variables"
                    source="variables"
                />

                <ImageInput
                    source="thumbnail"
                    label="Thumbnail"
                    accept="image/*"
                >
                    <ImageField
                        source="link"
                        title="title"
                        sx={{
                            "& .RaImageField-image": {
                                margin: "0.5rem",
                                maxHeight: "10em",
                            }
                        }}
                    />
                </ImageInput>

                <TemplatePreviewField templateSource="file" />
            </SimpleForm>
        </Edit>
    );
};
