import {KeyValueField} from "../../graphql/graphql";

export interface KeyValueObject {
    [key: string]: string | null | undefined;
}

export const keyValueToObject = (keyValues: KeyValueField[] = []) => {
    const variables: KeyValueObject = {};
    for (const item of keyValues) {
        if (typeof item !== "undefined" && item.key) {
            variables[item.key] = item.value;
        }
    }
    return variables;
};
