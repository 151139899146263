import {useDataProvider, useEditContext, useGetList} from "react-admin";
import React, {useState} from "react";
import {useMutation} from "react-query";
import {gql} from "@apollo/client";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import BatchPredictionIcon from "@mui/icons-material/BatchPrediction";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import {IsOrderEligibleInput, Order, ProcessingLogic} from "../../../../graphql/graphql";
import {CustomAccordionSummary} from "../../../components/Accordion/CustomAccordionSummary";
import {OverlayLoader, OverlayScope} from "../../../components/OverlayLoader";

export const OrderEligibility = () => {
    const record = useEditContext<Order>();
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const {data: processingLogics = []} = useGetList<ProcessingLogic>("processingLogics");
    const [processingLogicId, setProcessingLogicId] = useState<string>("");

    const {
        mutate,
        data: isOrderEligibleResponse,
        isLoading,
    } = useMutation<{data: {isOrderEligible: string}}, unknown, {input: IsOrderEligibleInput}>(
        ["isOrderEligible"],
        (variables) =>
        dataProvider.customMutate("orders", {
            mutation: gql`
                query ($input: IsOrderEligibleInput!) {
                    isOrderEligible(input: $input) {
                        reason
                        resolveTime
                    }
                }
            `,
            variables,
        }),
    );

    const checkProcessingLogic = async () => {
        if (!record.record) {
            return;
        }
        mutate({
            input: {
                processingLogicId: parseInt(processingLogicId),
                orderId: record.record.id,
            },
        });
    };

    const response = isOrderEligibleResponse?.data.isOrderEligible;

    return (
        <Accordion className={classes.root}>
            <CustomAccordionSummary
                label={"Check logic's inclusion"}
                startIcon={<BatchPredictionIcon fontSize="small" />}
            />
            <AccordionDetails>
                {isLoading && <OverlayLoader scope={OverlayScope.Window} />}

                <div className={classes.logicTitle}>
                    Why is this order not in processing logic?
                </div>

                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    value={processingLogicId}
                    onChange={event => setProcessingLogicId(event.target.value as string)}
                    fullWidth
                >
                    {processingLogics.map(processingLogic => (
                        <MenuItem key={processingLogic.id} value={processingLogic.id}>
                            {processingLogic.name}
                        </MenuItem>
                    ))}
                </Select>

                <Button onClick={checkProcessingLogic}>
                    Describe
                </Button>


                {response && (
                    <div>
                        <div>Response:</div>

                        <strong>
                            <pre className={classes.pre}>
                                {JSON.stringify(response, null, 4)}
                            </pre>
                        </strong>
                    </div>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        width: "100%",
    },
    logicWrapper: {
        marginTop: theme.spacing(2),
    },
    logicTitle: {
        marginBottom: theme.spacing(2),
    },
    pre: {
        whiteSpace: "pre-wrap",
    },
}));
