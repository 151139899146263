import React from "react";
import {EditButton} from "react-admin";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import createStyles from "@mui/styles/createStyles";
import {makeStyles} from "@mui/styles";

interface IProps {
    headings: string[];
    row: any;
    onDelete: () => void;
    onEdit: () => void;
}

const RenderValue: React.FC<{value: any}> = ({value}) => {
    const classes = useStyles();

    if (typeof value === "undefined" || value === null) {
        return <em className={classes.nullValue}>&lt;null&gt;</em>;
    }

    if (typeof value === "boolean") {
        return value ? "Yes" : "No";
    }

    return value;
};

export const DataFieldRow = ({headings, row, onDelete, onEdit}: IProps) => {
    return (
        <TableRow>
            {headings.map((field: string) => (
                <TableCell key={field}>
                    <span>
                        <RenderValue value={row[field]} />
                    </span>
                </TableCell>
            ))}
            <TableCell>
                <EditButton onClick={() => onEdit()} />
                <Button
                    onClick={() => onDelete()}
                    startIcon={<DeleteIcon />}
                >
                    Delete
                </Button>
            </TableCell>
        </TableRow>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        nullValue: {
            color: "#aaa",
        },
    }),
);
