import React from "react";
import {NavLink} from "react-router-dom";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {useBreadcrumbs} from "../hooks/useBreadcrumbs";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1),
    },
    clickable: {
        color: theme.palette.primary.main,
    },
    inactive: {
        color: theme.palette.secondary.main,
    },
}));

export const Breadcrumbs = () => {
    const breadcrumbs = useBreadcrumbs();
    const classes = useStyles();

    if (breadcrumbs.length < 2) {
        return null;
    }

    return (
        <MuiBreadcrumbs
            className={classes.root}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
        >
            {breadcrumbs.map(breadcrumb => {
                if (breadcrumb.clickable) {
                    return (
                        <NavLink
                            className={classes.clickable}
                            key={breadcrumb.path}
                            to={breadcrumb.path}
                        >
                            {breadcrumb.name}
                        </NavLink>
                    );
                } else {
                    return (
                        <Typography
                            className={classes.inactive}
                            key={breadcrumb.path}
                        >
                            {breadcrumb.name}
                        </Typography>
                    );
                }
            })}
        </MuiBreadcrumbs>
    );
};
