import React from "react";
import {RelatedItem, RelatedItemProps} from "./RelatedItem";

interface IProps {
    relatedItems: RelatedItemProps[];
    dense?: boolean;
}

export const RelatedItemsArray: React.FC<IProps> = props => {
    const {relatedItems} = props;

    if (typeof relatedItems === "undefined") {
        return null;
    }

    return (
        <>
            {relatedItems.map((relatedItem, index) => {
                let key: string | number = relatedItem.uniqueLineId;
                if (!key) {
                    key = `${relatedItem.variantId}-${index}`;
                }
                return (
                    <RelatedItem
                        key={key}
                        relatedItem={relatedItem}
                        dense={props.dense}
                    />
                );
            })}
        </>
    );
};
