import {gql} from "@apollo/client";
import {ApolloQueryResult} from "@apollo/client/core/types";
import {ProcessingLogicOrderIds} from "../types";
import {useQuery} from "react-query";
import {useDataProvider} from "react-admin";

interface ProcessingLogicsOrdersValue {
    data: ProcessingLogicOrderIds[];
}

export const useLogicsOrdersCountQuery = () => {
    const dataProvider = useDataProvider();
    return useQuery<ProcessingLogicsOrdersValue>(["processingLogics", "processingLogicsOrderIds"], () =>
        dataProvider.customFetch("processingLogics", {
            query: gql`
                {
                    processingLogicsOrderIds {
                        processingLogicId
                        count
                    }
                }
            `,
            resolver: (result: ApolloQueryResult<any>) => ({data: result.data.processingLogicsOrderIds}),
        }),
    );
};
