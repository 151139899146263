import React from "react";
import {Datagrid, List, TextField} from "react-admin";

export const FiltersList = (props: any) => {
    return (
        <List>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="filterName" />
                <TextField source="entityName" />
            </Datagrid>
        </List>
    );
};
