import React, {useState} from "react";
import {
    ImageField,
    SimpleForm,
    FileField,
    Confirm,
    FileInput,
    ImageInput,
    useCreate,
    useListContext,
    SaveButton,
} from "react-admin";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {ActionComponent, Action} from "./Action";
import {FileListEntity, RecursivePathResult} from "../types";
import TextField from "@mui/material/TextField";
import {Config} from "../../../config";
import {useFetch} from "../../../hooks/useFetch";

interface Props {
    result: RecursivePathResult;
}

export const UploadFileAction: ActionComponent<Props> = React.forwardRef((props, ref) => {
    const {result, onCancel} = props;
    const [open, setOpen] = useState(false);
    const fetch = useFetch();
    const {refetch} = useListContext<FileListEntity>();

    const [fileName, setFileName] = useState("");

    const [createOne] = useCreate();

    return (
        <>
            <Action onClick={() => setOpen(true)}>
                <span>Upload File</span>
            </Action>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle id="alert-dialog-title">Upload file</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        onSubmit={async (files) => {
                            var formData = new FormData();
                            const file: File = files.files.rawFile;
                            console.log(files);
                            console.log(file);
                            formData.append("file", file);
                            // TODO: this replaces the trailing slash because the api adds a slash in-between the name
                            //   and root dir
                            formData.append("rootPath", result.absolute.replace(/\/+$/, ""));

                            const upload = await fetch(`${Config.apiBaseUrl}/files/upload`, {
                                method: "POST",
                                body: formData,
                            });
                            if (upload.ok) {
                                setOpen(false);
                                refetch();
                            }
                        }}
                        toolbar={false}
                    >
                        <FileInput
                            source="files">
                            <FileField source="src" title="title" />
                        </FileInput>

                        <SaveButton label={"Upload"}>
                            Upload
                        </SaveButton>
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    );
});
