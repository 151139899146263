import React from "react";
import createStyles from "@mui/styles/createStyles";
import {makeStyles} from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {JSONSchema7} from "json-schema";
import {DataFieldRow} from "./DataFieldRow";

interface IProps {
    data: any;
    onDelete: (id: number) => void;
    onEdit: (id: number) => void;
    schema: JSONSchema7;
}

export const DataFieldList = ({data, schema, onDelete, onEdit}: IProps) => {
    const classes = useStyles();
    let headings: string[] = [];
    if (schema && schema.items && (schema.items as JSONSchema7).properties) {
        headings = Object.keys((schema.items as JSONSchema7).properties || {});
    }
    return (
        <TableContainer component={Paper}>
            <Table
                className={classes.table}
                aria-label="simple table"
            >
                <TableHead>
                    <TableRow>
                        {headings.map((row: string) => (
                            <TableCell key={row}>{row}</TableCell>
                        ))}
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row: Record<string, any>, key: number) => (
                        <DataFieldRow
                            key={key}
                            headings={headings}
                            row={row}
                            onEdit={() => onEdit(key)}
                            onDelete={() => onDelete(key)}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        table: {
            minWidth: 450,
        },
    }),
);
