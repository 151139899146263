import React, {useMemo, useState} from "react";
import {useListContext} from "react-admin";
import {makeStyles} from "@mui/styles";
import SplitPane, {Pane, SashContent} from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import {FileBrowser} from "../../../components/FileBrowser/FileBrowser";
import {FileListEntity} from "../../../components/FileBrowser/types";
import {pathsToTree} from "../../../components/FileBrowser/pathsToTree";
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import {Toolbar} from "./toolbar/Toolbar";
import {CodeEditor} from "./CodeEditor";
import {InlineCopyableField} from "../../../components/CopyableField";
import {EditorPreview} from "./EditorPreview";
import {getFileLanguage} from "../../../utils/get-file-language";

export const Editor = (props: any) => {
    const classes = useStyles(props);

    const [sizes, setSizes] = useState([250, "auto", 300]);

    const [openedFiles, setOpenedFiles] = useLocalStorage<FileListEntity[]>("openedFiles", []);
    const [openedFilePath, setOpenedFilePath] = useLocalStorage<string | undefined>(
        "openedFilePath",
        openedFiles[openedFiles.length - 1]?.path,
    );
    const {data} = useListContext<FileListEntity>();
    const tree = useMemo(() => pathsToTree(data || []), [data]);

    const lastFile = openedFiles.find(file => file.path === openedFilePath);
    const [code, setCode] = useState("");
    const showPreview = lastFile && getFileLanguage(lastFile.path) === "liquid";

    return (
        <div className={classes.main}>
            <SplitPane
                split="vertical"
                sizes={sizes}
                onChange={sizes => {
                    setSizes(sizes);
                    const event = document.createEvent("HTMLEvents");
                    event.initEvent("resize", true, false);
                    document.dispatchEvent(event);
                }}
                sashRender={(_, active) => <SashContent active={active} type="vscode" />}
            >
                <FileBrowser
                    tree={tree}
                    onOpenFile={file => {
                        const files = [...openedFiles];
                        const index = files.map(file => file.id).indexOf(file.id);
                        if (index === -1) {
                            files.push(file);
                            setOpenedFiles(files);
                            setOpenedFilePath(files[files.length - 1].path);
                        } else {
                            setOpenedFilePath(files[index].path);
                        }
                    }}
                    defaultExpanded={["/"]}
                />

                <Pane minSize={50} maxSize="90%" className={classes.content}>
                    {lastFile && (
                        <>
                            <Toolbar
                                activeFile={lastFile}
                                files={openedFiles}
                                onOpen={entity => {
                                    setOpenedFilePath(entity.path);
                                }}
                                onClose={entity => {
                                    const files = [...openedFiles];
                                    if (openedFilePath === entity.path) {
                                        if (files.length <= 1) {
                                            setOpenedFilePath(undefined);
                                        } else {
                                            let index = openedFiles.indexOf(entity) - 1;
                                            if (index < 0) {
                                                index = 1;
                                            }
                                            setOpenedFilePath(openedFiles[index].path);
                                        }
                                    }
                                    setOpenedFiles(files.filter(file => entity.id !== file.id));
                                }}
                            />

                            {lastFile.link && <InlineCopyableField value={lastFile.link} />}

                            {lastFile.fileType === "IMAGE" ? (
                                <div>
                                    <img className={classes.image} alt={"Uploaded image"} src={lastFile.link} />
                                </div>
                            ) : (
                                <CodeEditor
                                    file={lastFile}
                                    setCode={setCode}
                                />
                            )}
                        </>
                    )}
                </Pane>

                {showPreview ? (
                    <Pane minSize="20%" maxSize="40%">
                        <EditorPreview file={lastFile} templateCode={code} />
                    </Pane>
                ): <></>}
            </SplitPane>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    main: {
        height: "calc(100vh - 20px)",
    },
    content: {},
    image: {
        display: "block",
        margin: "1rem auto 0",
        maxWidth: "100%",
    },
}));
