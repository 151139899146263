import React from "react";
import {Datagrid, ImageField, List, TextField} from "react-admin";

export const TemplatesList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="name" />
                <ImageField
                    sx={{
                        "& .RaImageField-image": {
                            margin: "0rem",
                            maxHeight: "4em",
                        }
                    }}
                    source="thumbnail.link"
                />
            </Datagrid>
        </List>
    );
};
