import {Dispatch, SetStateAction, useEffect, useState} from "react";
import storage from "local-storage-fallback";
import {useGetAccounts} from "./useGetAccounts";

const prefix = "SRF";

export const useLocalStorage = <T>(storageKey: string, initialValue: T, userScoped = true): [T, Dispatch<SetStateAction<T>>] => {
    const {accounts: {current}} = useGetAccounts();

    let scopePrefix = "";

    if (userScoped && current) {
        scopePrefix = `_${current.sub}_`;
    }

    const prefixedStorageKey = prefix + scopePrefix + storageKey;

    const localStorageState = storage.getItem(prefixedStorageKey);
    if (localStorageState) {
        initialValue = JSON.parse(localStorageState);
    }

    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        if (typeof value === "undefined") {
            storage.setItem(prefixedStorageKey, JSON.stringify(null));
        } else {
            storage.setItem(prefixedStorageKey, JSON.stringify(value));
        }
    }, [value, prefixedStorageKey]);

    return [value, setValue];
};
