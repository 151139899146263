import defaultEnglishMessages from "ra-language-english";
import merge from "lodash/merge";

const englishMessages = merge(defaultEnglishMessages, {
    resources: {
        processingLogics: {
            name: "Processing Logics",
        },
    },
});

export default englishMessages;
