import React from "react";
import {JsonObject} from "@clauma-marketing/srf-interpreter";
import {FileListEntity} from "../FileBrowser/types";
import {DebuggablePreview} from "./DebuggablePreview";
import {useDeepResolvedTemplate} from "../../hooks/useDeepResolvedTemplate";

interface Props {
    file: FileListEntity;
    overrideVariables?: JsonObject;
}


export const TemplatePreview: React.FC<Props> = props => {
    const {overrideVariables = {}} = props;
    const {template, variables, locale} = useDeepResolvedTemplate({file: props.file});

    if (!template || !locale) {
        return null;
    }

    return (
        <DebuggablePreview
            template={template}
            variables={{
                ...variables,
                ...overrideVariables,
            }}
            locale={locale}
        />
    );
};
