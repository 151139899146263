import {gql} from "@apollo/client";
import {
    CreateParams,
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateManyParams,
    UpdateParams,
} from "react-admin";
import {ResourceDataProvider} from "../../dataProvider/ResourceDataProvider";

export class ProcessingsDataProvider extends ResourceDataProvider {
    public resource = "processings";

    async create(params: CreateParams) {
        const {processingLogicId, limit} = params.data;

        const query = gql`
            mutation {
                createNewBatch(input: {processingLogicId: ${processingLogicId}, limit: ${limit}}) {
                    id
                }
            }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.createNewBatch,
        };
    }

    // TODO: implement
    async delete(params: DeleteParams) {
        throw new Error("Not implemented");
        return {data: {id: 0}};
    }

    // TODO: implement
    async deleteMany(params: DeleteManyParams) {
        throw new Error("Not implemented");
        return {};
    }

    async getList(params: GetListParams) {
        const filter = params.filter ?? {};

        const query = gql`
            query getQuery($filter: JSON!) {
                processings(
                    page: ${params.pagination.page},
                    perPage: ${params.pagination.perPage},
                    sortField: "${params.sort.field}",
                    sortOrder: "${params.sort.order}",
                    filter: $filter,
                ) {
                    nodes {
                        id
                        type
                        processingLogic {
                            id
                            name
                        }
                        orderProcessings {
                            order {
                                id
                            }
                        }
                        createdAt
                        updatedAt
                    }
                    length
                }
            }
        `;

        const result = await this.client.query({query, variables: {filter}});

        return {
            data: result.data.processings.nodes,
            total: result.data.processings.length,
        };
    }

    // TODO: implement
    async getMany(params: GetManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }

    // TODO: implement
    async getManyReference(params: GetManyReferenceParams) {
        throw new Error("Not implemented");
        return {data: [], total: 0};
    }

    async getOne(params: GetOneParams) {
        const query = gql`
            query ($id: Int!) {
                processing(id: $id) {
                    id
                    type
                    downloadUrl
                    regenerateUrl
                    labelsUrl
                    fedexCsvUrl
                    barcodeCsvUrl
                    processingLogic {
                        id
                        name
                        includeFilter {
                            filterName
                            criteria
                        }
                        excludeFilter {
                            filterName
                            criteria
                        }
                    }
                    orderProcessings {
                        order {
                            id
                            orderName
                            shopifyOrderId
                            fedexCsvUrl
                            barcodeCsvUrl
                            trackingNumber
                            note
                            fulfilled
                            shippingCompany
                            shippingAddress {
                                name
                                country
                                zip
                                city
                                address1
                                address2
                            }
                            billingAddress {
                                name
                                country
                                zip
                                city
                                address1
                                address2
                            }
                            refunds {
                                id
                                refund_line_items {
                                    line_item {
                                        id
                                        name
                                        variant_id
                                        variant_title
                                        quantity
                                        properties {
                                            name
                                            value
                                        }
                                    }
                                }
                            }
                            orderCertificates {
                                certificate {
                                    id
                                    hashId
                                    downloadUrl
                                    regenerateUrl
                                    relatedItems {
                                        handle
                                        variantId
                                        qty
                                        uniqueLineId
                                    }
                                    variables {
                                        key
                                        value
                                    }
                                    privateVariables {
                                        key
                                        value
                                    }
                                }
                            }
                            store {
                                shop
                            }
                            lineItems {
                                id
                                name
                                variant_id
                                variant_title
                                quantity
                                properties {
                                    name
                                    value
                                }
                            }
                            variables {
                                key
                                value
                            }
                        }
                    }
                    createdAt
                    updatedAt
                }
            }
        `;

        const id = parseInt(params.id as string);
        const result = await this.client.query({query, variables: {id}});

        return {
            data: result.data.processing,
        };
    }

    // TODO: implement
    async update(params: UpdateParams) {
        throw new Error("Not implemented");
        return {data: {id: 0}};
    }

    // TODO: implement
    async updateMany(params: UpdateManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }
}
