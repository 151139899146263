import {FileType} from "../../../graphql/graphql";

export enum FSNodeType {
    Directory = "Directory",
    File = "File",
}

export interface FileListEntity {
    id: number;
    path: string;
    link: string;
    fileType: FileType;
}

export interface FileEntity extends FileListEntity {
    content: string;
}

export interface FSAttributes {
    id?: number;
    link?: string;
    fileType?: FileType;
}

export type FilePathResult = {
    type: FSNodeType.File;
    name: string;
    absolute: string;
    attributes: FSAttributes;
};

export type DirectoryPathResult = {
    type: FSNodeType.Directory;
    name: string;
    absolute: string;
    attributes: FSAttributes;
    children: RecursivePathResult[];
};

export type RecursivePathResult = DirectoryPathResult | FilePathResult;

export const isDirectory = (result: RecursivePathResult): result is DirectoryPathResult => {
    return result.type === FSNodeType.Directory;
};
