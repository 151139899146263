import {isDirectory, RecursivePathResult} from "./types";

export const resultsSorter = (a: RecursivePathResult, b: RecursivePathResult): number => {
    if (isDirectory(a) === isDirectory(b)) {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    } else {
        return isDirectory(a) ? -1 : 1;
    }
};
