import React from "react";
import Typography from "@mui/material/Typography";
import {Order} from "../../../../graphql/graphql";
import {keyValueToObject} from "../../../utils/key-value-to-object";

interface Props {
    order: Order;
}

interface AddressComponentProps {
    prefix?: string;
    validated?: string | null;
    original?: string | null;
    isSpellCorrected: boolean;
}

const RenderAddressComponent: React.FC<AddressComponentProps> = ({prefix, validated, original, isSpellCorrected}) => {
    if (!isSpellCorrected) {
        return <Typography>{prefix} {validated}</Typography>;
    }

    return (
        <>
            <Typography sx={{
                textDecoration: "line-through",
                color: "red",
            }}>{prefix} {original}</Typography>
            <Typography sx={{
                color: "green",
            }}>{prefix} {validated}</Typography>
        </>
    );
}

export const RenderAddress: React.FC<Props> = ({order}) => {
    if (!order || !order.shippingAddress) {
        return <span>Address N/A</span>;
    }

    const {shippingAddress, variables} = order;

    const parsedVariables = keyValueToObject(variables);

    const route = parsedVariables.address_route || shippingAddress.address1;
    const apartment = parsedVariables.address_subpremise || shippingAddress.address2;
    const city = parsedVariables.address_locality || shippingAddress.city;
    const streetNumber = parsedVariables.address_street_number || shippingAddress.address2;
    const zip = parsedVariables.address_postal_code || shippingAddress.zip;

    const routeValidated = parsedVariables.address_route_validated === "1";
    const apartmentValidated = parsedVariables.address_subpremise_validated === "1";
    const cityValidated = parsedVariables.address_locality_validated === "1";
    const streetNumberValidated = parsedVariables.address_street_number_validated === "1";

    return (
        <>
            <Typography>{shippingAddress.company}</Typography>
            <Typography>{shippingAddress.name}</Typography>
            <RenderAddressComponent
                validated={route}
                original={shippingAddress?.address1}
                isSpellCorrected={routeValidated}
            />
            {streetNumber && (
                <RenderAddressComponent
                    prefix={"Street number:"}
                    validated={streetNumber}
                    original={shippingAddress.address2 || ""}
                    isSpellCorrected={streetNumberValidated}
                />
            )}
            {apartment && (
                <RenderAddressComponent
                    prefix={"Apartment:"}
                    validated={apartment}
                    original={shippingAddress.address2 || ""}
                    isSpellCorrected={apartmentValidated}
                />
            )}
            <RenderAddressComponent
                prefix={"City:"}
                validated={city}
                original={shippingAddress.city}
                isSpellCorrected={cityValidated}
            />
            <Typography>
                {shippingAddress.province}, {zip}
            </Typography>
            <Typography>{shippingAddress.country}</Typography>
        </>
    );
};
