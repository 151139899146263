import {useRecordContext} from "react-admin";
import React from "react";
import createStyles from "@mui/styles/createStyles";
import {makeStyles} from "@mui/styles";
import {Action} from "./action";
import {PluginActionField} from "../../../graphql/graphql";

export const PluginActions = (props: any) => {
    const record = useRecordContext(props);
    const classes = useStyles();

    if (!record) {
        return null;
    }
    const pluginActions: PluginActionField[] = record.pluginActions;

    if (!pluginActions?.length) {
        return null;
    }

    return (
        <div className={classes.wrapper}>
            {pluginActions.map(action => (
                <Action
                    key={action.name}
                    action={action}
                />
            ))}
        </div>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        wrapper: {},
    }),
);
