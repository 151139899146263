import React from "react";
import {SimpleFormIterator, SimpleFormIteratorClasses, TextInput} from "react-admin";
import {makeStyles} from "@mui/styles";
import NotField from "../NotField";

interface IteratorProps {
    source: string;
}

const Iterator = (props: IteratorProps) => {
    const {source} = props;
    const iteratorClasses = useIteratorStyles();
    const classes = useStyles();

    return (
        <SimpleFormIterator
            className={iteratorClasses.root}
            disableReordering
            source={source}
        >
            <TextInput
                helperText={false}
                source="key"
                label={"Variable Name"}
            />
            <NotField>
                <span className={classes.arrow}>&#8594;</span>
            </NotField>
            <TextInput
                helperText={false}
                formClassName={classes.value}
                source="value"
                label={"Value"}
                multiline
                fullWidth
            />
        </SimpleFormIterator>
    );
};

const useIteratorStyles = makeStyles(theme => ({
    root: {
        [`& .${SimpleFormIteratorClasses.form}`]: {
            flexDirection: "row",
            alignItems: "center",
        },
    },
}));

const useStyles = makeStyles(theme => ({
    arrow: {
        margin: theme.spacing(0.5),
    },
    value: {
        flexGrow: 1,
    },
}));

export default Iterator;
