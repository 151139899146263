import React, {useCallback, useRef, useState} from "react";
import {gql} from "@apollo/client";
import {Button, useDataProvider, useGetList, useListContext, useNotify, useRedirect} from "react-admin";
import {useMutation} from "react-query";
import BatchPredictionIcon from "@mui/icons-material/BatchPrediction";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
    MutationCreateManualBatchArgs,
    Processing,
    ProcessingLogic,
    Type,
} from "../../../../graphql/graphql";

export const CreateBatchMenu: React.FC = () => {
    const {selectedIds} = useListContext();
    const redirect = useRedirect();
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    const anchorEl = useRef();
    const {data: processingLogics = []} = useGetList<ProcessingLogic>("processingLogics", {
        filter: {
            type: Type.Manual,
        },
    });

    const dataProvider = useDataProvider();
    const {
        mutate,
        data: createdBatch,
        isLoading,
    } = useMutation<{data: {createManualBatch: Processing}}, unknown, MutationCreateManualBatchArgs>(
        ["isOrderEligible"],
        (variables) =>
        dataProvider.customMutate("processings", {
            mutation: gql`
                mutation ($input: CreateManualProcessingBatchInput!) {
                    createManualBatch(input: $input) {
                        id
                    }
                }
            `,
            variables,
        }),
    );

    const handleClickButton = useCallback(
        event => {
            event.preventDefault();
            setOpen(true);
            anchorEl.current = event.currentTarget;
        },
        [anchorEl, setOpen],
    );

    const handleRequestClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const createBatch = (processingLogic: ProcessingLogic) => {
        mutate({
            input: {
                processingLogicId: processingLogic.id,
                orderIds: selectedIds,
            },
        }, {
            onError(error) {
                notify((error as Error).message, {type: "error"});
            },
            onSuccess(data) {
                redirect(`/processings/${data.data.createManualBatch.id}/show`);
            },
        });
    };

    return (
        <>
            <Button
                className="add-filter"
                label="Create batch"
                aria-haspopup="true"
                onClick={handleClickButton}
            >
                <BatchPredictionIcon />
            </Button>
            <Menu
                open={open}
                anchorEl={anchorEl.current}
                onClose={handleRequestClose}
            >
                {processingLogics.map(processingLogic => (
                    <MenuItem
                        key={processingLogic.id}
                        value={processingLogic.id}
                        onClick={() => createBatch(processingLogic)}
                    >
                        {processingLogic.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
