import React from "react";
import {Create, SimpleForm, TextInput} from "react-admin";

export const FiltersCreate = (props: any) => {
    return (
        <Create
            {...props}
        >
            <SimpleForm>
                <TextInput source="filterName" />

                <TextInput
                    source="criteria"
                    fullWidth
                    multiline
                />

                <TextInput source="entityName" />
            </SimpleForm>
        </Create>
    );
};
