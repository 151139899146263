import React from "react";
import {makeStyles, createStyles} from "@mui/styles";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {Certificate} from "../../../../graphql/graphql";
import {CertificateField} from "../../../components/CertificateField";

interface Props {
    certificates: Certificate[];
    open: boolean;
    onClose: () => void;
}

export const CertificatesModal = ({certificates, open, onClose}: Props) => {
    const classes = useStyles();

    return (
        <Dialog fullWidth={true} maxWidth={"lg"} onClose={() => onClose()} open={open}>
            <DialogTitle>Certificates</DialogTitle>

            <List className={classes.main}>
                {certificates.map((certificate, i, {length}) =>
                    <CertificateField
                        key={certificate.id}
                        certificate={certificate}
                    />,
                )}
            </List>
        </Dialog>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        main: {},
    }),
);
