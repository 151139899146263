import React, {useState} from "react";
import {useDataProvider, useListContext, useNotify} from "react-admin";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {ActionComponent, Action} from "./Action";
import {FileListEntity, RecursivePathResult} from "../types";
import {gql} from "@apollo/client";

interface Props {
    result: RecursivePathResult;
}

export const RenameAction: ActionComponent<Props> = React.forwardRef((props, ref) => {
    const {result, onCancel} = props;
    const [open, setOpen] = useState(false);
    const {refetch} = useListContext<FileListEntity>();
    const [filename, setFilename] = useState(result.name);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleRenameFile = async () => {
        let paths = result.absolute.split("/");
        paths.pop();

        const path = `${paths.join("/")}/${filename}`;
        try {
            await dataProvider.customMutate("files", {
                mutation: gql`
                    mutation RenameFile($input: RenameFileInput!) {
                        renameFile(input: $input) {
                            id
                        }
                    }
                `,
                variables: {
                    input: {
                        id: result.attributes.id,
                        path,
                    },
                },
            });

            refetch();
        } catch (e: any) {
            notify(e.message, {type: "error"});
        } finally {
            setOpen(false);
        }
    };

    return (
        <>
            <Action onClick={() => setOpen(true)}>
                <span>Rename</span>
            </Action>

            <Dialog
                fullWidth={true}
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle id="alert-dialog-title">Rename</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please enter a new name for the item
                    </DialogContentText>

                    <TextField
                        autoFocus
                        onChange={event => setFilename(event.target.value)}
                        value={filename}
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpen(false);
                            onCancel?.();
                        }}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleRenameFile}
                        color="primary"
                        autoFocus
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});
