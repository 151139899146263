import React from "react";
import {gql} from "@apollo/client";
import {ApolloQueryResult} from "@apollo/client/core/types";
import get from "lodash/get";
import merge from "lodash/merge";
import {useQuery} from "react-query";
import {useDataProvider} from "react-admin";
import {useWatch} from "react-hook-form";
import {JsonObject} from "@clauma-marketing/srf-interpreter";
import {TemplatePreview} from "./index";
import {KeyValueObject, keyValueToObject} from "../../utils/key-value-to-object";
import {KeyValueField} from "../../../graphql/graphql";
import {FileListEntity} from "../FileBrowser/types";

interface Props {
    templateSource: string;
    variablesSource?: string;
    privateVariablesSource?: string;
    templateIdSource?: string;
    loadTemplate?: boolean;
}

export const TemplatePreviewField: React.FC<Props> = props => {
    const {templateSource, variablesSource, privateVariablesSource, templateIdSource, loadTemplate} = props;
    const record = useWatch();
    const dataProvider = useDataProvider();

    let file: FileListEntity = get(record, templateSource);

    let variables: KeyValueObject = {};

    if (variablesSource) {
        let variablesValue: KeyValueField[] = get(record, variablesSource);
        variables = keyValueToObject(variablesValue);
    }

    if (privateVariablesSource) {
        let privateVariablesValue: KeyValueField[] = get(record, privateVariablesSource);
        variables = merge(variables, keyValueToObject(privateVariablesValue));
    }

    const templateId = typeof templateIdSource !== "undefined" && get(record, templateIdSource) as number;

    const shouldDownloadFile = !!(!file && loadTemplate && templateIdSource && templateId);

    const {data} = useQuery<{data: FileListEntity;}>(["templates", "loadTemplateById", {templateId}], () => {
        return dataProvider.customFetch("templates", {
            query: gql`{
                template(id: ${templateId}) {
                    id
                    file {
                        id
                        path
                    }
                }
            }`,
            resolver: (result: ApolloQueryResult<any>) => ({data: result.data.template.file}),
        });
    }, {enabled: shouldDownloadFile});


    if (data?.data) {
        file = data.data;
    }

    if (!file) {
        return null;
    }

    return (
        <TemplatePreview
            file={file}
            overrideVariables={variables as JsonObject}
        />
    );
};
