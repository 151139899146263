import * as React from "react";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {makeStyles} from "@mui/styles";
import {Typography} from "@mui/material";
import {Account, activateAccount} from "../../authProvider";
import {useReload} from "../../hooks/useReload";

interface Props {
    open: boolean;
    onClose?: () => void;
    account?: Account;
}

export const SwitchUserPopup: React.FC<Props> = (props) => {
    const {account, open, onClose} = props;
    const reload = useReload();
    const classes = useStyles();

    if (!account) {
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose?.()}
        >
            <DialogContent>
                    <CardContent>
                        <Typography variant={"subtitle1"} className={classes.title}>
                            Switch user to: {account.shop}
                        </Typography>

                        <Typography variant={"subtitle2"} color={"secondary"} className={classes.description}>
                            This entity was not found under this account, but was found in the suggested one.
                        </Typography>

                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                activateAccount(account);
                                reload();
                            }}
                        >
                            Switch
                        </Button>
                    </CardContent>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: theme.spacing(1),
    },
    description: {
        marginBottom: theme.spacing(2),
        width: 360,
    },
}));
