import React from "react";
import {useCreatePath} from "react-admin";
import {makeStyles, createStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import ListItem from "@mui/material/ListItem";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {KeyValueArray} from "../KeyValueField/KeyValueArray";
import {Certificate} from "../../../graphql/graphql";
import {openInNewTab} from "../../utils/open-in-new-tab";
import CopyableField from "../CopyableField/field";

interface Props {
    certificate: Certificate;
}

export const CertificateField = ({certificate}: Props) => {
    const classes = useStyles();
    const createPath = useCreatePath();
    const editUrl = createPath({resource: "certificates", type: "edit", id: certificate.id});

    return (
        <ListItem className={classes.main}>
            <Card variant="outlined">
                <CardHeader
                    action={
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{marginRight: "0.5em"}}
                                onClick={() => openInNewTab(editUrl)}
                                startIcon={<OpenInNewIcon />}
                            >
                                Edit
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                sx={{marginRight: "0.5em"}}
                                onClick={() => openInNewTab(certificate.regenerateUrl)}
                                startIcon={<RestorePageIcon />}
                            >
                                Regenerate
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => openInNewTab(certificate.downloadUrl)}
                                startIcon={<PictureAsPdfIcon />}
                            >
                                Download
                            </Button>
                        </div>
                    }
                    title={<span>ID: {certificate.id}</span>}
                    subheader={
                        <CopyableField value={certificate.hashId}>
                            Hash: {certificate.hashId}
                        </CopyableField>
                    }
                />
                <CardContent>
                    <div className={classes.variablesWrapper}>
                        <div className={classes.variables}>
                            <KeyValueArray
                                keyValues={certificate.variables}
                            />
                        </div>

                        <div className={classes.divider} />

                        <div className={classes.variables}>
                            <KeyValueArray
                                keyValues={certificate.privateVariables}
                            />
                        </div>
                    </div>
                </CardContent>
            </Card>
        </ListItem>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        main: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
        },
        variablesWrapper: {
            display: "flex",
            marginTop: theme.spacing(1),
        },
        variables: {
            flex: 1,
        },
        divider: {
            borderLeft: "1px solid #ddd",
            margin: `${theme.spacing(0.75)} ${theme.spacing(2)}`,
        },
    }),
);
