import React, {useState} from "react";
import {
    ArrayField,
    Datagrid,
    Edit,
    FunctionField,
    SimpleForm,
    TextField,
    TextInput,
    TopToolbar,
    useRecordContext,
} from "react-admin";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Button from "@mui/material/Button";
import {KeyValueInput} from "../../components/KeyValueField";
import {OrderEligibility} from "./OrderEligibility";
import {InfoModal} from "./Info";
import {openInNewTab} from "../../utils/open-in-new-tab";
import {Order, OrderProcessing} from "../../../graphql/graphql";
import {SwitchUserPopup} from "../../components/SwitchUserPopup";
import {SwitchUserError} from "../../utils/error/SwitchUserError";
import {Account} from "../../authProvider";
import {PluginActions} from "../../components/PluginActions";

const Actions = (props: any) => {
    const record = useRecordContext<Order>(props);

    if (!record) {
        return null;
    }

    return (
        <TopToolbar>
            <PluginActions {...props} />
        </TopToolbar>
    );
};

export const OrdersEdit = (props: any) => {
    const classes = useStyles();

    const [switchUserAccount, setSwitchUserAccount] = useState<Account>();

    return (
        <Edit
            {...props}
            mutationMode="pessimistic"
            redirect={false}
            actions={<Actions />}
            aside={
                <SwitchUserPopup
                    open={typeof switchUserAccount !== "undefined"}
                    onClose={() => setSwitchUserAccount(undefined)}
                    account={switchUserAccount}
                />
            }
            queryOptions={{
                onError(error) {
                    if (error instanceof SwitchUserError) {
                        setSwitchUserAccount(error.user);
                    }
                },
            }}
        >
            <SimpleForm>
                <TextInput
                    disabled
                    label="ID"
                    source="id"
                />

                <InfoModal />

                <KeyValueInput
                    label="Variables"
                    source="variables"
                />

                <OrderEligibility />


                <div className={classes.processingTitle}>
                    <Typography variant={"h3"}>Order processings:</Typography>
                </div>

                <ArrayField source="orderProcessing">
                    <Datagrid
                        className={classes.orderProcessings}
                        bulkActionButtons={false}
                    >
                        <TextField source="processing.id" />
                        <TextField source="processing.processingLogic.name" />
                        <FunctionField label="Open" render={(record: OrderProcessing) => {
                            return (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{marginRight: "0.5em"}}
                                    onClick={() => openInNewTab(`/processings/${record.processing.id}/show`)}
                                    startIcon={<OpenInNewIcon />}
                                >
                                    Open
                                </Button>
                            );
                        }} />
                    </Datagrid>
                </ArrayField>
            </SimpleForm>
        </Edit>
    );
};

const useStyles = makeStyles(theme => ({
    processingTitle: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(0.5),
    },
    orderProcessings: {
        marginTop: theme.spacing(1),
    },
}));


