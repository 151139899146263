import React from "react";
import MenuItem from "@mui/material/MenuItem";
import withStyles from "@mui/styles/withStyles";

export interface ActionProps {
    onConfirm?: () => void;
    onCancel?: () => void;
}

export type ActionComponent<T> = React.FC<T & ActionProps>;

export const Action = withStyles(theme => ({
    root: {
        padding: "5px 10px",
    },
}))(MenuItem);
