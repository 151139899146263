import React from "react";
import {ListBase, ReferenceInput, SelectInput, TextInput} from "react-admin";
import {InputFilterGroup} from "../../components/CustomFilterForm/InputFilterGroup";
import {privateVariablesChoices, variablesChoices} from "./fields/variablesField";
import {AutocompleteTextInput} from "../../components/AutocompleteTextInput/AutocompleteTextInput";
import {CertificatesGrid} from "./CertificatesGrid";
import {makeStyles} from "@mui/styles";

export const CertificatesList = (props: any) => {
    const styles = useStyles();

    const certificateFilters = [
        <TextInput source="id" alwaysOn />,
        <ReferenceInput
            label="Template"
            source="templateId"
            reference="templates"
        >
            <SelectInput
                optionText="name"
                label="Template"
            />
        </ReferenceInput>,
        <TextInput source="hashId" />,
        <TextInput source="locale" />,
        <InputFilterGroup source="variables">
            <AutocompleteTextInput
                label="Variables key"
                className={styles.autocomplete}
                source="variables.key"
                choices={variablesChoices}
            />
            <TextInput source="variables.value" className={styles.autocompleteValue} />
        </InputFilterGroup>,
        <InputFilterGroup alwaysOn source="privateVariables">
            <AutocompleteTextInput
                label="Private variables key"
                className={styles.autocomplete}
                source="privateVariables.key"
                choices={privateVariablesChoices}
                defaultValue={"registration_number"}
            />
            <TextInput source="privateVariables.value" className={styles.autocompleteValue} />
        </InputFilterGroup>,
    ];

    return (
        <ListBase
            {...props}
            sort={{field: "id", order: "DESC"}}
            filterDefaultValues={{
                "privateVariables": {
                    "key": "registration_number"
                }
            }}
        >
            <CertificatesGrid
                filters={certificateFilters}
            />
        </ListBase>
    );
};

const useStyles = makeStyles(theme => ({
    autocomplete: {
        [theme.breakpoints.up("md")]: {
            minWidth: 300,
        },
        [theme.breakpoints.down("md")]: {
            width: "50vw",
        }
    },
    autocompleteValue: {
        [theme.breakpoints.up("md")]: {
            minWidth: 300,
        },
        [theme.breakpoints.down("md")]: {
            marginTop: 0,
            width: "50vw",
        }
    }
}));

