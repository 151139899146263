import {gql} from "@apollo/client";
import {
    CreateParams,
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateManyParams,
    UpdateParams,
} from "react-admin";
import {
    CreateFilterInput,
    Filter,
    UpdateFilterInput,
} from "../../../graphql/graphql";
import {ResourceDataProvider} from "../../dataProvider/ResourceDataProvider";

export class FiltersDataProvider extends ResourceDataProvider {
    public resource = "filters";

    async create(params: CreateParams) {
        const query = gql`
            mutation CreateFilter($input: CreateFilterInput!) {
                createFilter(createFilterInput: $input) {
                    id
                    filterName
                }
            }
        `;

        const data = params.data;
        const input: CreateFilterInput = {
            entityName: data.entityName,
            filterName: data.filterName,
            criteria: data.criteria,
        };

        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.createFilter,
        };
    }

    async delete(params: DeleteParams) {
        const query = gql`
            mutation RemoveFilter($id: Int!) {
                removeFilter(id: $id) {
                    id
                }
            }
        `;

        const result = await this.client.query({
            query,
            variables: {id: params.id},
        });

        return {
            data: result.data,
        };
    }

    // TODO: implement
    async deleteMany(params: DeleteManyParams) {
        throw new Error("Not implemented");
        return {};
    }

    async getList(params: GetListParams) {
        const query = gql`
            {
                filters {
                    id
                    filterName
                    entityName
                }
            }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.filters,
            total: result.data.filters.length,
        };
    }

    async getMany(params: GetManyParams) {
        const query = gql`
            {
                filters {
                    id
                    filterName
                }
            }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.filters,
            total: result.data.filters.length,
        };
    }

    // TODO: implement
    async getManyReference(params: GetManyReferenceParams) {
        throw new Error("Not implemented");
        return {data: [], total: 0};
    }

    async getOne(params: GetOneParams) {
        const query = gql`
            {
                filter(id: ${params.id}) {
                    id,
                    filterName,
                    criteria,
                    entityName
                }
            }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.filter,
        };
    }

    async update(params: UpdateParams) {
        const query = gql`
            mutation update($input: UpdateFilterInput!) {
                updateFilter(input: $input) {
                    id
                }
            }
        `;

        const data = params.data as Filter;
        const input: UpdateFilterInput = {
            id: data.id,
            criteria: data.criteria,
            entityName: data.entityName,
            filterName: data.filterName,
        };

        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.updateFilter,
        };
    }

    // TODO: implement
    async updateMany(params: UpdateManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }
}
