import React, {useEffect} from "react";
import {Notification, useSidebarState, LayoutComponent} from "react-admin";
import {makeStyles} from "@mui/styles";
import {StyledEngineProvider, Theme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import ResponsiveAppBar from "./ResponsiveAppBar";
import {theme} from "./theme";
import {Breadcrumbs} from "./Breadcrumbs";
import {Config} from "../config";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        zIndex: 1,
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default,
        position: "relative",
    },
    appFrame: {
        display: "flex",
        flexDirection: "column",
        // overflowX: "auto",
    },
    main: {
        minHeight: "calc(100vh - 20px)",
    },
    contentWithSidebar: {
        display: "flex",
        flexGrow: 1,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 2,
        padding: theme.spacing(2),
        marginTop: 48,
        paddingLeft: 5,
    },
    footer: {
        fontSize: 14,
        lineHeight: 1,
    },
}));

export const Layout: LayoutComponent = props => {
    const {children, dashboard} = props;
    const [open, setOpen] = useSidebarState();

    const classes = useStyles();

    useEffect(() => {
        setOpen(true);
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <div className={classes.appFrame}>
                        <ResponsiveAppBar dashboard={dashboard} />

                        <main className={classes.main}>
                            <Container maxWidth="xl">
                                {/*<Sidebar classes={{}}>*/}
                                {/*    <Menu logout={logout} hasDashboard={!!dashboard}/>*/}
                                {/*</Sidebar>*/}
                                <div className={classes.content}>
                                    <Breadcrumbs />

                                    {children}
                                </div>
                            </Container>
                        </main>
                        <Notification />

                        <footer className={classes.footer}>Build id: {Config.commitSha}</footer>
                    </div>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
