import React, {useState} from "react";
import {useCreate, useRefresh, useRecordContext, useUpdate, useDelete} from "react-admin";
import createStyles from "@mui/styles/createStyles";
import {makeStyles} from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {DataFieldModal} from "./DataField/DataFieldModal";
import {PluginDataField} from "../../../../graphql/graphql";
import {PluginStorageField} from "./PluginStorageField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {PluginStorageModal} from "./PluginStorageModal";

interface IProps {
    source: string;
}

export const PluginStorageList = (props: IProps) => {
    const {source} = props;
    const record = useRecordContext(props);
    const refresh = useRefresh();
    const classes = useStyles();
    const [update] = useUpdate();
    const [create] = useCreate();
    const [deleteOne] = useDelete();

    const [fieldDialogOpen, setFieldDialogOpen] = useState(false);
    const [dataDialogOpen, setDataDialogOpen] = useState(false);
    const [currentData, setCurrentData] = useState<PluginDataField>();

    if (!record[source]) {
        return null;
    }

    const handleShowFieldModal = (data: PluginDataField) => {
        setCurrentData(data);
        setFieldDialogOpen(true);
    };

    const handleDeleteData = (id: number, data: PluginDataField) => {
        deleteOne(
            "plugins",
            {id, previousData: data},
            {
                onSuccess: closeModalAndRefresh,
            },
        );
    };

    const closeModalAndRefresh = () => {
        setDataDialogOpen(false);
        refresh();
    };

    const handleShowDataModal = (data?: PluginDataField) => {
        if (!data) {
            setCurrentData({
                id: 0,
                isPublic: false,
                key: "",
                schema: "{}",
                value: "[]",
            });
        } else {
            setCurrentData(data);
        }
        setDataDialogOpen(true);
    };

    const handleSubmit = (data: PluginDataField) => {
        if (data.id === 0) {
            create("plugins", {data: {...data, pluginId: record["id"]}}, {onSuccess: closeModalAndRefresh});
            return;
        }
        update("plugins", {id: data.id, data}, {onSuccess: closeModalAndRefresh});
    };

    return (
        <>
            <Button
                className={classes.addButton}
                onClick={() => handleShowDataModal()}
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
            >
                Add
            </Button>
            <TableContainer component={Paper}>
                <Table
                    className={classes.table}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Key</TableCell>
                            <TableCell>Is Public?</TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {record[source].map((row: PluginDataField, key: number) => (
                            <PluginStorageField
                                key={key}
                                data={row}
                                onEdit={handleShowDataModal}
                                onData={handleShowFieldModal}
                                onDelete={handleDeleteData}
                            />
                        ))}
                    </TableBody>
                </Table>

                {currentData && (
                    <DataFieldModal
                        data={currentData}
                        open={fieldDialogOpen}
                        onClose={() => setFieldDialogOpen(false)}
                    />
                )}

                {currentData && (
                    <PluginStorageModal
                        data={currentData}
                        open={dataDialogOpen}
                        onSubmit={handleSubmit}
                        onClose={() => setDataDialogOpen(false)}
                    />
                )}
            </TableContainer>
        </>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        table: {
            minWidth: 650,
        },
        addButton: {
            float: "right",
            marginBottom: theme.spacing(1),
        },
    }),
);
