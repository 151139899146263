import {useContext} from "react";
import {AppBridgeContext} from "@shopify/app-bridge-react/context";
import {jwtFetch} from "../authProvider";
import {userLoggedInFetch} from "../utils/user-logged-in-fetch";

export const useFetch = () => {
    const appBridge = useContext(AppBridgeContext);
    if (appBridge) {
        return userLoggedInFetch(appBridge);
    } else {
        return jwtFetch();
    }
};
