import React from "react";
import {Show, useRecordContext, TopToolbar} from "react-admin";
import Button from "@mui/material/Button";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {OrderProcessingsField} from "./fields/OrderProcessingsField";
import {openInNewTab} from "../../utils/open-in-new-tab";
import {ProcessingInfoField} from "./fields/ProcessingInfoField";
import {Processing} from "../../../graphql/graphql";
import {CsvButtons} from "./fields/CsvButtons";

const Actions = () => {
    const record = useRecordContext<Processing>();

    if (!record) {
        return null;
    }

    return (
        <TopToolbar>
            <CsvButtons
                barcodeCsvUrl={record.barcodeCsvUrl}
                fedexCsvUrl={record.fedexCsvUrl}
            />

            <Button
                variant="contained"
                color="primary"
                onClick={() => openInNewTab(record.labelsUrl)}
                startIcon={<LocalShippingIcon />}
            >
                Shipping labels
            </Button>

            <Button
                variant="contained"
                color="primary"
                onClick={() => openInNewTab(record.regenerateUrl)}
                startIcon={<RestorePageIcon />}
            >
                Regenerate
            </Button>

            <Button
                variant="contained"
                color="primary"
                onClick={() => openInNewTab(record.downloadUrl)}
                startIcon={<PictureAsPdfIcon />}
            >
                Download
            </Button>
        </TopToolbar>
    );
};

export const ProcessingShow: React.FC = () => {
    return (
        <Show
            actions={<Actions />}
            queryOptions={{
                refetchOnWindowFocus: false,
            }}
        >
            <Card>
                <CardContent>
                    <ProcessingInfoField />

                    <OrderProcessingsField source="orderProcessings" />
                </CardContent>
            </Card>
        </Show>
    );
};
