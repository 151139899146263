import {useNotify, useRecordContext, useRefresh} from "react-admin";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import createStyles from "@mui/styles/createStyles";
import {makeStyles} from "@mui/styles";
import {useFetch} from "../../hooks/useFetch";
import {Config} from "../../config";
import {PluginModal} from "./modal";
import {PluginActionField} from "../../../graphql/graphql";
import {parseInlineStyle} from "./utils/parse-inline-style";

interface IProps {
    action: PluginActionField;
}

export const Action = (props: IProps) => {
    const {action} = props;
    const record = useRecordContext(props);
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const fetch = useFetch();
    const notify = useNotify();
    const [modalOpen, setModalOpen] = useState(false);

    const refresh = useRefresh();

    const callPluginAction = async (data: any = {}) => {
        setIsLoading(true);

        const response = await fetch(`${Config.apiBaseUrl}/plugins/${action.endpoint}`, {
            method: "POST",
            body: JSON.stringify({
                data,
                entity: {
                    id: record.id,
                },
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        const body = await response.json();
        setIsLoading(false);

        if (body.reload) {
            refresh();
        }

        const errored = body.error || response.status > 300 || response.status < 200;

        if (errored) {
            const error = body.error || body.message;
            notify(error, {
                type: "error",
                autoHideDuration: 5000,
            });
        } else {
            const message = body.data ?? "Success";
            notify(message, {
                type: "success",
                autoHideDuration: 5000,
            })
            setModalOpen(false);
        }
    };

    const style = action.buttonStyle ? parseInlineStyle(action.buttonStyle) : {};

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                style={style}
                className={classes.button}
                onClick={async () => {
                    if (action.noConfirmation) {
                        await callPluginAction();
                    } else {
                        setModalOpen(true);
                    }
                }}
            >
                {action.name}
            </Button>
            <PluginModal
                {...props}
                isLoading={isLoading}
                action={action}
                open={modalOpen}
                setOpen={setModalOpen}
                onSubmit={async data => {
                    await callPluginAction(data);
                }}
            />
        </>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        button: {
            marginRight: theme.spacing(1),
        },
    }),
);
