import {FileEntity, FileListEntity} from "../../../components/FileBrowser/types";
import React, {useEffect, useState} from "react";
import {useGetOne, useUpdate} from "react-admin";
import {pathToFilename} from "../../../components/FileBrowser/pathToFilename";
import {CodeJarEditor} from "../../../components/CodeJarEditor/CodeJarEditor";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";

interface Props {
    setCode: (code: string) => void;
    file: FileListEntity;
}

export const CodeEditor: React.FC<Props> = (props) => {
    const classes = useStyles();

    const [value, setValue] = useState("");
    const [dirty, setDirty] = useState(false);

    const {file, setCode} = props;
    const {data, isLoading, error, refetch} = useGetOne<FileEntity>("files", {id: file.id});

    useEffect(() => {
        if (data && typeof data.content !== "undefined") {
            setValue(data.content);
            setCode?.(data.content);
        }
    }, [data]);

    const name = pathToFilename(file);

    const [update, {isLoading: saving}] = useUpdate();

    return (
        <div>
            <div className={classes.toolbar}>
                <span>{name}</span>

                <div>
                    <Button
                        onClick={() => {
                            update(
                                "files",
                                {
                                    id: file.id,
                                    data: {
                                        content: value,
                                        path: file.path,
                                    },
                                },
                                {
                                    onSuccess: () => setDirty(false),
                                },
                            );
                        }}
                        size="small"
                        variant="outlined"
                        color="primary"
                        disabled={!dirty || saving}
                    >
                        Save
                    </Button>
                </div>
            </div>

            <CodeJarEditor
                value={value}
                setValue={value => {
                    setValue(value);
                    setDirty(true);
                    setCode?.(value);
                }}
                filepath={file.path}
                style={{height: "calc(100vh - 265px)"}}
            />
        </div>
    );
};

const useStyles = makeStyles(() => ({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0.5em 0.75em",
        borderTop: "1px solid #ddd",
        borderBottom: "1px solid #eee",
    },
}));
