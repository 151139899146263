import React from "react";
import {SaveButton, SimpleForm, TextInput, ToolbarClasses} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import {makeStyles, createStyles} from "@mui/styles";

interface Props {
    open: boolean;
    onClose: () => void;
    onSubmit: (limit: number) => void;
}

export const LimitModal: React.FC<Props> = (props) => {
    const {open, onClose, onSubmit} = props;

    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogContent className={classes.dialogContent}>
                <SimpleForm
                    onSubmit={(data) => {
                        onSubmit(data.limit);
                    }}
                    toolbar={
                        <Toolbar
                            children={
                                <div className={ToolbarClasses.defaultToolbar}>
                                    <SaveButton label={"Create batch"} />
                                </div>
                            }
                        />
                    }
                >
                    <TextInput
                        source="limit"
                        helperText={"How many orders to include in the batch?"}
                    />
                </SimpleForm>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        dialogContent: {
            minWidth: 450,
            [theme.breakpoints.down("xs")]: {
                minWidth: 300,
            },
        },
        footer: {
            marginTop: theme.spacing(3),
        },
    }),
);
