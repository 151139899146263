export const languageExtensionMap: Record<string, string> = {
    json: "json",
    liquid: "liquid",
    sql: "sql",
};

export const getFileLanguage = (filepath: string) => {
    const parts = filepath.split(".");
    const extension = parts[parts.length - 1];
    return languageExtensionMap[extension] || "liquid";
};
