import React from "react";
import {makeStyles} from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";

interface Props {
    label: React.ReactNode;
    startIcon?: React.ReactNode;
}

export const CustomAccordionSummary = (props: Props) => {
    const classes = useStyles();

    const {label, startIcon} = props;

    return (
        <AccordionSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon />}
        >
            {startIcon && (
                <div
                    className={classes.startIconWrapper}
                >
                    {startIcon}
                </div>
            )}

            <span>{label}</span>
        </AccordionSummary>
    );
};

const useStyles = makeStyles(theme => ({
    summary: {
        '& .MuiAccordionSummary-content': {
            alignItems: "center",
        },
    },
    startIconWrapper: {
        marginRight: theme.spacing(1),
        color: "rgba(0, 0, 0, 0.6)",
    },
}));
