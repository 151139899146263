import React, {useEffect} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {SxProps, Theme} from "@mui/material/styles";
import {useController} from "react-hook-form";

interface AutocompleteTextInputProps {
    source?: string;
    label: string;
    choices?: string[];
    sx?: SxProps<Theme>;
    defaultValue?: string;
    className?: string;
}

export const AutocompleteTextInput = (props: AutocompleteTextInputProps) => {
    const {
        source,
        choices = [],
        sx,
        label,
        defaultValue,
        className,
    } = props;

    const {
        field: {value, onChange},
    } = useController({name: source || "", defaultValue});

    return (
        <Autocomplete
            freeSolo
            value={value}
            onChange={function(event, value) {
                onChange(value);
            }}
            sx={sx}
            className={className}
            options={choices}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    onChange={onChange}
                />
            )}
        />
    );
};
