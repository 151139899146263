import React, {useEffect, useState} from "react";
import {Provider} from "@shopify/app-bridge-react";
import {Config} from "./config";
import {ConditionalWrapper} from "./components/ConditionalWrapper";
import AdminWrapper from "./AdminWrapper";

const App = () => {
    const [loading, setLoading] = useState(true);
    const [forceRedirect, setForceRedirect] = useState(false);

    const requestUrl = new URL(window.location.href);
    const {searchParams} = requestUrl;
    let shopOrigin = searchParams.get("shop");
    // shopOrigin = "star-registration-test-3.myshopify.com"
    let host: string = searchParams.get("host") || "";
    if (!host && shopOrigin) {
        host = window.btoa(shopOrigin + "/admin");
    }

    // console.log(Object.keys(searchParams));

    // const forceRedirect = searchParams.get("force-redirect") !== null;
    // let forceRedirect = false;

    // console.log(config, window.location.search.indexOf("host=") === -1, forceRedirect);

    useEffect(() => {
        if (shopOrigin) {
            const redirectUnauthorizedUrl = new URL(`${Config.apiBaseUrl}/shopify/redirect-unauthorized`);
            redirectUnauthorizedUrl.search = requestUrl.search;

            fetch(redirectUnauthorizedUrl.toString())
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    if (res.redirect) {
                        return window.location.assign(res.redirect);
                    } else if (requestUrl.pathname === "/shopify/callback") {
                        setForceRedirect(true);
                    }

                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        // TODO: show a loader here.
        return <div>Loading...</div>;
    }

    const config = {apiKey: Config.shopifyApiKey, host: host, forceRedirect};

    // if (window.location.search.indexOf("hmac=") > -1 || window.location.search.indexOf("host=") === -1 && forceRedirect) {
    // // if (window.location.search.indexOf("host=") === -1 && forceRedirect) {
    // // if (window.location.search.indexOf("host=") !== -1 && forceRedirect) {
    //     console.log("window.location.assign", Config.apiBaseUrl);
    //     window.location.assign(`${Config.apiBaseUrl}/shopify/auth${window.location.search}`);
    //     return null;
    // }

    return (
        <ConditionalWrapper
            condition={!!host}
            wrapper={children => <Provider config={config}>{children}</Provider>}
        >
            <AdminWrapper />
        </ConditionalWrapper>
    );
};

export default App;
