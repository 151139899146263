import React from "react";
import {Create, ImageField, ImageInput, required, SimpleForm, TextInput} from "react-admin";
import {TemplateSelector} from "./templateSelector";
import {TemplatePreviewField} from "../../components/TemplatePreview/TemplatePreviewField";
import {useFetch} from "../../hooks/useFetch";
import {uploadThumbnail} from "./api/uploadThumbnail";
import {CreateTemplateData} from "./types";
import {TagSelector} from "../tags/tagSelector";

export const TemplatesCreate = (props: any) => {
    const fetch = useFetch();
    const transform = async (data: CreateTemplateData) => {
        return await uploadThumbnail(fetch, data);
    };
    return (
        <Create
            {...props}
            transform={transform}
        >
            <SimpleForm>
                <TextInput
                    source="name"
                    validate={required()}
                />
                <TagSelector
                    source="tags"
                    entityName="template"
                />
                <TemplateSelector source="file" />

                <ImageInput
                    source="thumbnail"
                    label="Thumbnail"
                    accept="image/*"
                >
                    <ImageField
                        source="link"
                        title="title"
                    />
                </ImageInput>

                <TemplatePreviewField templateSource="file" />
            </SimpleForm>
        </Create>
    );
};
