import * as React from "react";
import {
    Form,
    required,
    useTranslate,
    useLogin,
    useNotify,
} from "ra-core";
import {TextInput} from "react-admin";
import {useState} from "react";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {Account} from "../../authProvider";

export const PopupLoginForm = (props: LoginFormProps) => {
    const {redirectTo, className} = props;
    const [loading, setLoading] = useState(false);

    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose?.()}
        >
            <DialogContent>
                <StyledForm
                    onSubmit={async (values) => {
                        setLoading(true);
                        try {
                            const account = await login(values, redirectTo);
                            setLoading(false);
                            props.onSuccess?.(account);
                        } catch (error) {
                            setLoading(false);
                            let message;
                            if (typeof error === "string") {
                                message = error;
                            } else if (error instanceof Error) {
                                message = error.message;
                            } else {
                                message = "ra.auth.sign_in_error";
                            }

                            notify(message, {type: "warning", messageArgs: {_: message}});
                        }
                    }}
                    mode="onChange"
                    noValidate
                    className={className}
                >
                    <CardContent className={LoginFormClasses.content}>
                        <TextInput
                            autoFocus
                            source="username"
                            label={translate("ra.auth.username")}
                            validate={required()}
                            fullWidth
                        />
                        <TextInput
                            source="password"
                            label={translate("ra.auth.password")}
                            type="password"
                            autoComplete="current-password"
                            validate={required()}
                            fullWidth
                        />

                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            fullWidth
                            className={LoginFormClasses.button}
                        >
                            {loading ? (
                                <CircularProgress
                                    className={LoginFormClasses.icon}
                                    size={19}
                                    thickness={3}
                                />
                            ) : (
                                translate("ra.auth.sign_in")
                            )}
                        </Button>
                    </CardContent>
                </StyledForm>
            </DialogContent>
        </Dialog>



    );
};

const PREFIX = "RaLoginForm";

export const LoginFormClasses = {
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
    icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({theme}) => ({
    [`& .${LoginFormClasses.content}`]: {
        width: 300,
    },
    [`& .${LoginFormClasses.button}`]: {
        marginTop: theme.spacing(2),
    },
    [`& .${LoginFormClasses.icon}`]: {
        margin: theme.spacing(0.3),
    },
}));

export interface LoginFormProps {
    redirectTo?: string;
    className?: string;
    onSuccess?: (account: Account) => void;
    open: boolean;
    onClose?: () => void
}
