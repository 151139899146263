import React, {useEffect, useState} from "react";
import {useUpdate, useRecordContext, useRefresh} from "react-admin";
import createStyles from "@mui/styles/createStyles";
import {makeStyles} from "@mui/styles";
import {DataFieldList} from "./DataFieldList";
import {DataFieldEdit} from "./DataFieldEdit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import UndoIcon from "@mui/icons-material/Undo";
import {PluginDataField} from "../../../../../graphql/graphql";

interface IProps {
    data: PluginDataField;
    open: boolean;
    onClose: () => void;
}

enum DisplayMode {
    Add,
    View,
    Edit,
}

export const DataFieldModal = ({data, ...props}: IProps) => {
    const record = useRecordContext(props);
    const [update] = useUpdate();
    const refresh = useRefresh();

    const {open, onClose} = props;
    const {schema, value, key} = data;
    const classes = useStyles();

    const [items, setItems] = useState<PluginDataField[]>([]);
    const [mode, setMode] = useState<DisplayMode>(DisplayMode.View);
    const [activeDataIndex, setActiveDataIndex] = useState<number>(-1);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        if (data.value) {
            setItems(JSON.parse(data.value));
        }
    }, [data]);

    const handleItemChange = (data: PluginDataField) => {
        if (activeDataIndex === -1) {
            setItems([...items, data]);
        } else {
            items[activeDataIndex] = data;
            setItems([...items]);
        }
        setIsDirty(true);
        setActiveDataIndex(-1);
        setMode(DisplayMode.View);
    };

    const handleShowEdit = (id: number) => {
        setActiveDataIndex(id);
        setMode(DisplayMode.Edit);
    };

    const handleDelete = (id: number) => {
        items.splice(id, 1);
        setItems([...items]);
        setIsDirty(true);
    };

    const handleCancelChanges = () => {
        setItems(JSON.parse(data.value));
        setIsDirty(false);
    };

    const handleSaveChanges = () => {
        const updated = {...data, value: JSON.stringify(items)};
        update(
            "plugins",
            {id: updated.id, data: updated},
            {
                onSuccess: () => {
                    setIsDirty(false);
                    onClose();
                    refresh();
                },
            },
        );
    };

    if (!record) {
        return null;
    }

    if (typeof value === "undefined") {
        return null;
    }

    const renderList = () => {
        if (!items || items.length == 0) {
            return;
        }

        return (
            <DataFieldList
                schema={JSON.parse(schema)}
                data={items}
                onEdit={handleShowEdit}
                onDelete={handleDelete}
            />
        );
    };

    const renderEdit = () => (
        <DataFieldEdit
            data={[items[activeDataIndex]]}
            onSubmit={handleItemChange}
            schema={JSON.parse(schema)}
            onCancel={() => setMode(DisplayMode.View)}
        />
    );

    return (
        <Dialog
            maxWidth={false}
            open={open}
            onClose={() => mode == DisplayMode.View && onClose()}
        >
            <DialogTitle>
                {key}

                {mode == DisplayMode.View && (
                    <>
                        <Button
                            disabled={!isDirty}
                            className={classes.dialogButton}
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={handleSaveChanges}
                        >
                            Save Changes
                        </Button>

                        <Button
                            disabled={!isDirty}
                            className={classes.dangerButton}
                            variant="contained"
                            color="secondary"
                            startIcon={<UndoIcon />}
                            onClick={handleCancelChanges}
                        >
                            Cancel Changes
                        </Button>

                        <Button
                            className={classes.dialogButton}
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                setActiveDataIndex(-1);
                                setMode(DisplayMode.Edit);
                            }}
                        >
                            Add Data
                        </Button>
                    </>
                )}
            </DialogTitle>
            <DialogContent>{mode == DisplayMode.Edit ? renderEdit() : renderList()}</DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        dialogButton: {
            float: "right",
            marginLeft: theme.spacing(1),
        },
        dangerButton: {
            float: "right",
            marginLeft: theme.spacing(1),
            backgroundColor: "red",
        },
        footer: {
            marginTop: theme.spacing(3),
        },
        table: {
            minWidth: 450,
        },
        formFooter: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        cancelButton: {
            marginRight: theme.spacing(1),
        },
    }),
);
