import {gql} from "@apollo/client";
import {
    CreateParams,
    DeleteManyParams,
    DeleteParams,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateManyParams,
    UpdateParams,
} from "react-admin";
import {UpdateStoreInput} from "../../../graphql/graphql";
import {ResourceDataProvider} from "../../dataProvider/ResourceDataProvider";
import {cleanKeyValue} from "../../utils/clean-key-value";

export class StoresDataProvider extends ResourceDataProvider {
    public resource = "stores";

    // TODO: implement
    async create(params: CreateParams) {
        throw new Error("Not implemented");
        return {data: {id: 0}};
    }

    // TODO: implement
    async delete(params: DeleteParams) {
        throw new Error("Not implemented");
        return {data: {id: 0}};
    }

    // TODO: implement
    async deleteMany(params: DeleteManyParams) {
        throw new Error("Not implemented");
        return {};
    }

    async getList(params: GetListParams) {
        let filter = params.filter ?? {};

        const query = gql`
        query getQuery($filter: JSON!) {
            stores(
                page: ${params.pagination.page},
                perPage: ${params.pagination.perPage},
                sortField: "${params.sort.field}",
                sortOrder: "${params.sort.order}",
                filter: $filter,
            ) {
                nodes {
                    id
                    shop
                    createdAt
                    updatedAt
                }
                length
            }
        }
        `;

        const result = await this.client.query({query, variables: {filter}});

        return {
            data: result.data.stores.nodes,
            total: result.data.stores.length,
        };
    }

    // TODO: implement
    async getMany(params: GetManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }

    // TODO: implement
    async getManyReference(params: GetManyReferenceParams) {
        throw new Error("Not implemented");
        return {data: [], total: 0};
    }

    async getOne(params: GetOneParams) {
        const query = gql`
        {
            store(id: ${params.id}) {
                id
                shop
                configuration {
                    key
                    value
                }
                createdAt
                updatedAt
            }
        }
        `;

        const result = await this.client.query({query});

        return {
            data: result.data.store,
        };
    }

    async update(params: UpdateParams) {
        const query = gql`
            mutation UpdateStore($input: UpdateStoreInput!) {
                updateStore(input: $input) {
                    id
                }
            }
        `;

        const data = params.data;
        const input: UpdateStoreInput = {
            id: data.id,
            shop: data.shop,
            configuration: cleanKeyValue(data.configuration),
        };
        const result = await this.client.query({query, variables: {input}});

        return {
            data: result.data.updateStore,
        };
    }

    // TODO: implement
    async updateMany(params: UpdateManyParams) {
        throw new Error("Not implemented");
        return {data: []};
    }
}
