import React, {ReactNode} from "react";
import {
    ArrayField,
    ChipField,
    CreateButton,
    Datagrid,
    DatagridClasses,
    DateField,
    ExportButton,
    FilterButton,
    ImageField,
    Pagination,
    SingleFieldList,
    TextField,
    useListContext,
} from "react-admin";
import Card from "@mui/material/Card";
import Toolbar from "@mui/material/Toolbar";
import {makeStyles} from "@mui/styles";
import {KeyValueField} from "../../components/KeyValueField";
import {CustomFilterForm} from "../../components/CustomFilterForm/CustomFilterForm";
import {CopyableField} from "../../components/CopyableField";
import {OverlayLoader, OverlayScope} from "../../components/OverlayLoader";

interface ListToolbarProps {
    filters: ReactNode[];
}

const ListToolbar: React.FC<ListToolbarProps> = (props) => (
    <Toolbar>
        <CustomFilterForm filters={props.filters} />
        <Toolbar>
            <CreateButton />
            <FilterButton filters={props.filters} />
            <ExportButton />
        </Toolbar>
    </Toolbar>
);

export const CertificatesGrid = (props: any) => {
    const {isFetching} = useListContext(props);

    const classes = useStyles();

    return (
        <>
            <ListToolbar filters={props.filters} />

            <Card className={classes.content}>
                {isFetching && <OverlayLoader scope={OverlayScope.Window} />}

                <Datagrid
                    sx={{
                        [`& .${DatagridClasses.tableWrapper}`]: {
                            overflow: "auto",
                        },
                    }}
                    rowClick="edit"
                    sort={{field: "createdAt", order: "DESC"}}
                >
                    <TextField
                        source="id"
                        label="ID"
                    />
                    <TextField
                        source="template.name"
                        label="Template"
                        sortable={false}
                    />
                    <ImageField
                        source="template.thumbnail.link"
                        sx={{
                            "& .RaImageField-image": {
                                margin: "0rem",
                                maxHeight: "4em",
                                width: "auto",
                            },
                        }}
                        className={classes.thumbnail}
                        label="Thumbnail"
                        sortable={false}
                    />
                    <CopyableField source="hashId" className={classes.hashId} />
                    <TextField source="locale" />
                    <KeyValueField
                        source="variables"
                        dense={true}
                    />
                    <DateField
                        source="createdAt"
                        showTime={true}
                    />
                    <DateField
                        source="updatedAt"
                        showTime={true}
                    />

                    <ArrayField source="entityTags">
                        <SingleFieldList>
                            <ChipField source="tag.tagName" />
                        </SingleFieldList>
                    </ArrayField>
                </Datagrid>

                <Pagination />
            </Card>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    content: {
        position: "relative",
    },
    thumbnail: {
        textAlign: "center",
    },
    hashId: {
        display: "inline-block",
        maxWidth: "100px",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
}));
