import React from "react";
import {KeyValueItem} from "./KeyValueItem";
import {KeyValueField} from "../../../graphql/graphql";

interface IProps {
    keyValues: KeyValueField[];
    dense?: boolean;
}

export const KeyValueArray: React.FC<IProps> = props => {
    const {keyValues} = props;

    if (typeof keyValues === "undefined") {
        return null;
    }

    return (
        <>
            {keyValues.map(keyValue => (
                <KeyValueItem
                    key={keyValue.key}
                    keyValue={keyValue}
                    dense={props.dense}
                />
            ))}
        </>
    );
};
