import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import {JsonObject} from "@clauma-marketing/srf-interpreter";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import SRFFabric from "@clauma-marketing/srf-fabric";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import PreviewIcon from "@mui/icons-material/Preview";
import Pagination from "@mui/material/Pagination";
import {CustomAccordionSummary} from "../Accordion/CustomAccordionSummary";
import {useUserStore} from "../../hooks/useUserStore";
import {Config} from "../../config";

export interface Props {
    template: string;
    variables: JsonObject;
    locale: Record<string, JsonObject>;
    fitHeight?: boolean;
    pageNumber?: number;
    onLocaleChange?: (locale: string) => void;
}

export const DebuggablePreview: React.FC<Props> = props => {
    const {
        template = "",
        variables = {},
        locale = {},
        fitHeight = false,
        pageNumber: initialPageNumber = 1,
        onLocaleChange,
    } = props;

    const [currentLocale, setCurrentLocale] = useState<string>("en");
    const [pageCount, setPageCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(initialPageNumber);
    const [debug, setDebug] = useState<boolean>(false);
    const {data: userStore} = useUserStore();

    useEffect(() => onLocaleChange?.(currentLocale), [currentLocale]);

    const classes = useStyles();

    const locales = Object.keys(locale);

    if (locales.length && locales.indexOf(currentLocale) === -1) {
        setCurrentLocale(locales[0]);
    }

    if (!template || !locale) {
        return null;
    }

    return (
        <div className={classes.root}>
            <Accordion defaultExpanded={true}>
                <CustomAccordionSummary
                    label={<span>Preview</span>}
                    startIcon={<PreviewIcon fontSize="small" />}
                />
                <AccordionDetails>
                    <div className={classes.previewWrapper}>
                        <FormGroup row>
                            {locales.length > 0 && (
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="preview-language-label">Preview Language</InputLabel>
                                    <Select
                                        labelId="preview-language-label"
                                        value={currentLocale}
                                        label="Age"
                                        onChange={event => setCurrentLocale(event.target.value as string)}
                                    >
                                        {locales.map(locale => (
                                            <MenuItem
                                                key={locale}
                                                value={locale}
                                            >
                                                {locale.toUpperCase()}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={debug}
                                        onChange={event => setDebug(event.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Show Debug Lines"
                            />
                        </FormGroup>

                        <SRFFabric
                            className={classes.canvas}
                            template={template}
                            variables={{
                                debug_enabled: debug,
                                ...variables,
                            }}
                            fitHeight={fitHeight}
                            locale={locales.length ? locale[currentLocale] : {}}
                            pageNumber={pageNumber - 1}
                            onPageCountChange={count => setPageCount(count)}
                            interpreterOptions={{
                                fileSystemBaseUrl: `${Config.apiBaseUrl}/shopify/${userStore?.data.shop}/file/`,
                            }}
                        />
                        <Pagination count={pageCount} page={pageNumber}
                                    onChange={(_, pageNumber) => setPageNumber(pageNumber)} />

                        {props.children}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            width: "100%",
        },
        canvas: {
            width: "100%",
            minHeight: "200px",
            marginTop: "10px",
            border: "1px dashed #ddd",
        },
        formControl: {
            minWidth: 135,
            margin: theme.spacing(1),
        },
        previewWrapper: {
            width: "100%",
        },
    }),
);
