import React from "react";
import {useShowContext} from "react-admin";
import {makeStyles, createStyles} from "@mui/styles";
import Card from "@mui/material/Card";
import {Processing} from "../../../../graphql/graphql";
import {FilterInfo} from "../../processing-logics/Show/FilterInfo";

export const ProcessingInfoField = () => {
    const {isFetching, record} = useShowContext<Processing>();

    const classes = useStyles();

    if (isFetching || !record) {
        return null;
    }

    const {name, includeFilter, excludeFilter} = record.processingLogic;

    return (
        <Card className={classes.main} variant="outlined">
            <div>Batch ID: {record.id}</div>
            <div>
                <span>Logic: </span><span className={classes.name}>{name}</span>
            </div>
            <FilterInfo
                includeFilter={includeFilter}
                excludeFilter={excludeFilter}
            />
        </Card>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        main: {
            display: "inline-block",
            marginBottom: theme.spacing(2),
            padding: theme.spacing(1),
            lineHeight: 1.3,
        },
        name: {
            fontWeight: 600,
        },
    }),
);
